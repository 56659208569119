import { CircularProgress } from '@/components/Elements/circularprogress';
import { useUser as useAuthUser } from '@/lib/auth';
import clsx from 'clsx';
import { useState } from 'react';
import { useBolt } from '../../features/bolt/getBolt';
import { useUser } from '../../features/user/getUser';
import { getImageURL } from '../../services/api';
import { asCurrencyString } from '../../utils/money';
import { DRYTXNDATA } from '../../utils/types';
import { CustomDialog } from '../common/Dialog';

function TransactionDialog({
  transaction,
  isOpen,
  onClose,
}: {
  transaction: DRYTXNDATA;
  isOpen: boolean;
  onClose: () => void;
}) {
  const authUser = useAuthUser();
  const bolt = useBolt({ specId: transaction.bolt });
  const sender = useUser({ userId: transaction.sender });
  const receiver = useUser({ userId: transaction.receiver });

  if (
    !authUser.isSuccess ||
    !bolt.isSuccess ||
    !sender.isSuccess ||
    !receiver.isSuccess
  )
    return null;

  const outgoing = authUser.data?.user.id.toString() === transaction.sender;

  return (
    <CustomDialog isOpen={isOpen} onClose={onClose}>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 sm:col-span-1">
          {authUser.data?.user.id.toString() !==
          transaction.receiver.toString() ? (
            <ReturnAvatar userId={transaction.receiver} />
          ) : (
            <ReturnAvatar userId={transaction.sender} />
          )}
        </div>
        <div className="col-span-2 flex flex-col justify-around gap-4 sm:col-span-1">
          <div className="text-2xl font-bold sm:text-3xl">
            {bolt.data.bolt.specName}
          </div>
          <div
            className={clsx(
              'text-3xl font-bold',
              outgoing ? 'text-error' : 'text-success'
            )}
          >
            {outgoing ? '-' : '+'}
            {asCurrencyString(transaction.amount)}
          </div>
          <div className="text-lg font-thin sm:text-sm">
            {new Date(transaction.updatedAt).toLocaleDateString('en-us', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </div>
        </div>
        <div className="text-lg">
          To:{' '}
          <span className="font-bold text-secondary">
            {receiver.data.user.username}
          </span>
        </div>
        <div className="text-lg">
          From:{' '}
          <span className="font-bold text-secondary">
            {sender.data.user.username}
          </span>
        </div>
      </div>
    </CustomDialog>
  );
}

const ReturnAvatar = ({ userId }: { userId: string }) => {
  const { data, status } = useUser({ userId });

  if (status === 'loading') return null;
  if (status === 'error') return null;

  if (!data.user.profilePic) {
    return (
      <div className="aspect-square">
        <div className="flex h-full w-full items-center justify-center rounded-full bg-primary text-lg text-white">
          <strong>
            {data.user?.firstName[0]?.toLocaleUpperCase()}.
            {data.user?.lastName?.[0]?.toLocaleUpperCase()}.
          </strong>
        </div>
      </div>
    );
  }

  const profileObj =
    typeof data.user.profilePic === 'string'
      ? getImageURL(data.user.profilePic)
      : URL.createObjectURL(data.user.profilePic);
  return (
    <div className="aspect-square h-full w-full">
      <img className="h-full w-full object-contain" src={profileObj} />
    </div>
  );
};

export function Transaction({ transaction }: { transaction: DRYTXNDATA }) {
  const authUser = useAuthUser();
  const bolt = useBolt({ specId: transaction.bolt });
  const [isOpen, setIsOpen] = useState(false);

  if (bolt.isLoading || authUser.isLoading) return <CircularProgress />;
  if (bolt.isError || authUser.isError) return null;

  return (
    <>
      <TransactionDialog
        transaction={transaction}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <div
        className="flex w-full cursor-pointer items-center gap-6 rounded-lg p-8 shadow-lg ring-1 ring-base-content/10 hover:shadow-xl"
        onClick={() => setIsOpen(true)}
      >
        <div className="w-20 sm:w-24">
          {authUser.data?.user.id.toString() !==
          transaction.receiver.toString() ? (
            <ReturnAvatar userId={transaction.receiver} />
          ) : (
            <ReturnAvatar userId={transaction.sender} />
          )}
        </div>
        <div className="flex flex-col">
          <div className="text-lg font-bold">{bolt.data.bolt.specName}</div>
          <div
            className={clsx(
              'text-sm font-bold',
              authUser.data?.user.id.toString() ===
                transaction.receiver.toString()
                ? 'text-success'
                : 'text-error'
            )}
          >
            {asCurrencyString(transaction.amount)}
          </div>
          <span className="mt-2 text-xs font-thin opacity-70">
            {new Date(transaction.updatedAt).toLocaleDateString('en-us', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </span>
        </div>
      </div>
    </>
  );
}
