import { useMintBolt } from '@/features/bolt/mint';
import { z } from 'zod';
import { useZodForm } from '../../utils/form';
import { CustomDialog } from '../common/Dialog';

export function MintBolt({
  isOpen,
  onClose,
  specId,
}: {
  isOpen: boolean;
  onClose: () => void;
  specId: string;
}) {
  const methods = useZodForm({
    schema: z.object({
      amount: z.coerce.number().positive(),
    }),
  });

  const mintBolt = useMintBolt({
    config: {
      onSuccess: () => {
        onClose();
      },
    },
  });

  return (
    <CustomDialog isOpen={isOpen} onClose={onClose}>
      <div className="text-center font-display text-xl font-bold text-primary">
        Mint
      </div>
      <form
        className="flex h-full flex-col justify-between gap-8"
        onSubmit={methods.handleSubmit(async (values) =>
          mintBolt.mutate({
            specId,
            ...values,
          })
        )}
      >
        <div className="w-full">
          <label className="label">
            <span className="label-text">Amount</span>
          </label>
          <input
            className="input-bordered input-primary input w-full"
            type="number"
            step={0.01}
            {...methods.register('amount')}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.amount?.message}
          </span>
        </div>
        <div className="flex w-full justify-center gap-4">
          <button
            onClick={onClose}
            type="button"
            className="btn-primary btn-outline btn-sm btn"
          >
            Back
          </button>
          <button type="submit" className="btn-secondary btn-sm btn">
            Submit
          </button>
        </div>
      </form>
    </CustomDialog>
  );
}
