import { LinkButton } from '@/components/Elements/linkbutton';

function AlreadyLoggedIn() {
  return (
    <div className="mx-auto mt-8 flex w-full flex-col items-center gap-4">
      <div>
        You are already logged in. To register, please log out before visiting
        this page.
      </div>
      <LinkButton to="/" className="normal-case">
        Return Home
      </LinkButton>
    </div>
  );
}

export default AlreadyLoggedIn;
