import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import {
  MintPermission,
  SellPermissions,
} from '../../redux/reducers/authReducer';

type DelegationsResult = {
  permissions: {
    [user: number]: [
      | {
          type: 'mint';
          mints: MintPermission[];
        }
      | {
          type: 'accept';
        }
      | {
          type: 'sell';
          sell: SellPermissions;
        }
    ];
  };
  status: string;
};

export const getDelegations = () => {
  return axios
    .get(`/user/delegations`)
    .then((res) => res.data as DelegationsResult);
};

type UseDelegationsOptions = {
  config?: QueryConfig<typeof getDelegations>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useDelegations = ({ config }: UseDelegationsOptions = {}) => {
  return useQuery({
    // ...config,
    queryKey: ['delegations'],
    queryFn: () => getDelegations(),
  });
};
