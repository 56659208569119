import { useSellBolt } from '@/features/bolt/sell';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { useZodForm } from '../../utils/form';
import { CustomDialog } from '../common/Dialog';

export function SellBolt({
  isOpen,
  onClose,
  specId,
}: {
  isOpen: boolean;
  onClose: () => void;
  specId: string;
}) {
  const methods = useZodForm({
    schema: z
      .object({
        amount: z.coerce.number().positive(),
        email: z.string().email(),
        confirmEmail: z.string().email(),
      })
      .superRefine((data, ctx) => {
        if (data.email !== data.confirmEmail) {
          ctx.addIssue({
            code: 'custom',
            message: 'Emails do not match',
          });
        }
      }),
  });

  const sellBolt = useSellBolt({
    config: {
      onSuccess: (data) => {
        onClose();
        if (data.status == 'error') {
          toast.error(`Failed to send: ${data.msg}`);
        } else {
          const vals = methods.getValues();
          toast.success(`Successfully sent to ${vals.email}`);
        }
      },
      onError: () => {
        toast.error('Failed to send');
      },
    },
  });

  return (
    <CustomDialog isOpen={isOpen} onClose={onClose}>
      <div className="text-center font-display text-xl font-bold text-primary">
        Sell
      </div>
      <form
        className="flex h-full flex-col justify-between gap-2"
        onSubmit={methods.handleSubmit(async (values) => {
          sellBolt.mutate({
            specId,
            ...values,
          });
        })}
      >
        <div className="w-full">
          <label className="label">
            <span className="label-text">Amount</span>
          </label>
          <input
            className="input-bordered input-primary input w-full"
            type="number"
            step={0.01}
            {...methods.register('amount')}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.amount?.message}
          </span>
        </div>
        <div className="w-full">
          <label className="label">
            <span className="label-text">E-Mail</span>
          </label>
          <input
            className="input-bordered input-primary input w-full"
            {...methods.register('email')}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.email?.message}
          </span>
        </div>
        <div className="w-full">
          <label className="label">
            <span className="label-text">Confirm E-Mail</span>
          </label>
          <input
            className="input-bordered input-primary input w-full"
            {...methods.register('confirmEmail')}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.confirmEmail?.message}
          </span>
        </div>
        <div className="flex w-full justify-center gap-4">
          <button
            onClick={onClose}
            type="button"
            className="btn-primary btn-outline btn-sm btn"
          >
            Back
          </button>
          <button type="submit" className="btn-secondary btn-sm btn">
            Submit
          </button>
        </div>
      </form>
    </CustomDialog>
  );
}
