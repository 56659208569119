import {
  ZodFormTextField,
  ZodPasswordField,
} from '@/components/Elements/ZodFormFields';
import { useLogin } from '@/lib/auth';
import { useZodForm } from '@/utils/form';
import { Link, useNavigate } from 'react-router-dom';
import { z } from 'zod';

function Login() {
  const login = useLogin();
  const navigate = useNavigate();
  const methods = useZodForm({
    schema: z.object({
      username: z
        .string()
        .nonempty('Username cannot be empty')
        .regex(/^[^@]*$/, 'Use your username, not your email'),
      password: z
        .string()
        .min(4, 'Passwords are at least 4 characters long')
        .regex(
          /.*[A-Z].*/g,
          'Passwords always contain at least one capital letter'
        ),
      rememberMe: z.boolean(),
    }),
    defaultValues: {
      rememberMe: true,
    },
  });

  return (
    <div className="mx-auto flex w-full max-w-md flex-col items-center gap-8">
      <div className="text-6xl font-extrabold leading-relaxed tracking-widest">
        ZUZ
      </div>
      <div className="w-full">
        <form
          className="flex w-full flex-col gap-4"
          onSubmit={methods.handleSubmit(async (data) => {
            await login.mutateAsync(data);
            navigate('/wallet');
          })}
        >
          <ZodFormTextField
            placeholder="Username"
            id="username"
            schema={methods}
            name="username"
            errorMsg={methods.formState.errors.username?.message}
          />
          <ZodPasswordField
            id="password"
            placeholder="Password"
            schema={methods}
            name="password"
            errorMsg={methods.formState.errors.password?.message}
          />
          <div>
            <label className="flex cursor-pointer items-center gap-2">
              <input
                id="rememberme"
                type="checkbox"
                className="checkbox-primary checkbox checkbox-sm ring-1"
                {...methods.register('rememberMe')}
              />
              <span>Remember me</span>
            </label>
          </div>
          <div className="grid place-content-center">
            <button type="submit" className="btn-secondary btn-sm btn">
              Login
            </button>
          </div>
        </form>
      </div>
      <div className="divider my-0" />
      {/* links for register as new user and forgot password */}
      <div className="flex gap-8 text-center">
        <span className="text-sm">
          <Link
            id="link.register"
            className="link-hover link-primary link"
            to="/register/customer"
          >
            New to ZUZ?
            <br />
            Register
          </Link>
        </span>
        <span className="text-sm">
          <Link
            id="link.forgot"
            className="link-hover link-primary link"
            to="/forgot-password"
          >
            Forgot your password?
          </Link>
        </span>
      </div>
    </div>
  );
}

export default Login;
