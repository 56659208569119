import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// Persisted store for permissions
// Used to determine what actions a user can take
// Populated when logging in
export type PermissionStore = {
  permissions: {
    accept: { grantor: string }[];
    sell: { grantor: string; specIds: string[] | 'all'; maxLimit: number }[];
    mint: { grantor: string; specIds: string[] | 'all'; maxLimit: number }[];
  };
  setPermissions: (permissions: PermissionStore['permissions']) => void;
  addAcceptPermission: (grantor: string) => void;
  addSellPermission: (
    grantor: string,
    specIds: string[] | 'all',
    maxLimit: number
  ) => void;
  addMintPermission: (
    grantor: string,
    specIds: string[] | 'all',
    maxLimit: number
  ) => void;
  getCanSell: (specId: string) => boolean;
  getCanMint: (specId: string) => boolean;
  getCanAccept: () => boolean;
};

// Implement the store
export const usePermissionStore = create<PermissionStore>()(
  persist(
    (set, get) => ({
      permissions: { accept: [], sell: [], mint: [] },
      setPermissions: (permissions) => set({ permissions }),
      addAcceptPermission: (grantor) =>
        set((state) => ({
          permissions: {
            ...state.permissions,
            accept: [...state.permissions.accept, { grantor }],
          },
        })),
      addSellPermission: (grantor, specIds, maxLimit) =>
        set((state) => ({
          permissions: {
            ...state.permissions,
            sell: [...state.permissions.sell, { grantor, specIds, maxLimit }],
          },
        })),
      addMintPermission: (grantor, specIds, maxLimit) =>
        set((state) => ({
          permissions: {
            ...state.permissions,
            mint: [...state.permissions.mint, { grantor, specIds, maxLimit }],
          },
        })),
      getCanSell: (specId) =>
        get().permissions.sell.some(
          (p) => p.specIds === 'all' || p.specIds.includes(specId)
        ),
      getCanMint: (specId) =>
        get().permissions.mint.some(
          (p) => p.specIds === 'all' || p.specIds.includes(specId)
        ),
      getCanAccept: () => get().permissions.mint.length > 0,
    }),
    { name: 'zuz-permissions' }
  )
);
