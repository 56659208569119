import { useEditUser } from '@/features/user/editUser';
import { useUser } from '@/lib/auth';
import { getImageURL } from '@/services/api';
import { Avatar, Badge, Box, IconButton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { FaEdit } from 'react-icons/fa';

export const ReturnAvatar = () => {
  const user = useUser();

  let res = null;
  const profilePic = user.data?.user.profilePic || null;
  if (profilePic) {
    let profileObj: any = null;
    if (typeof profilePic === 'string') {
      profileObj = getImageURL(profilePic);
    } else {
      profileObj = URL.createObjectURL(profilePic);
    }
    res = <Avatar src={profileObj} className="m-1 bg-secondary" />;
  } else {
    res = (
      <Avatar className="m-1 bg-secondary">
        {user?.data?.user.firstName?.[0]}
        {user?.data?.user.lastName?.[0]}
      </Avatar>
    );
  }

  const queryClient = useQueryClient();

  const editUser = useEditUser({
    config: {
      onSuccess: async () => {
        await queryClient.refetchQueries(['user']);
        toast.success('Successfully updated profile picture');
      },
    },
  });

  if (user.isLoading) return null;

  //icon-button-file is what allows the avatar to browse
  //their computer for a profile picture
  return (
    <Box>
      <input
        accept="image/*"
        id="icon-button-file"
        type="file"
        className="hidden"
        onChange={(e) => {
          const file = e.target.files?.[0];
          if (!file) return;
          editUser.mutate({
            userId: user.data!.user.id, // eslint-disable-line @typescript-eslint/no-non-null-assertion
            profilePic: file,
          });
        }}
      />
      <label htmlFor="icon-button-file">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <Badge
            overlap="circular"
            color="primary"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<FaEdit />}
          >
            {res}
          </Badge>
        </IconButton>
      </label>
    </Box>
  );
};
