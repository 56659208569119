import {
  LoginResponse,
  loginWithUsernameAndPassword,
} from '@/features/auth/login';
import { getSession } from '@/features/user/auth/getSession';
import { getUser } from '@/features/user/getUser';
import { registerUser, registerUserProps } from '@/features/user/registerUser';
import { usePermissionStore } from '@/stores/PermissionStore';
import storage from '@/utils/storage';
import { IUSERDATA } from '@/utils/types';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { configureAuth, ReactQueryAuthConfig } from 'react-query-auth';
import seedPermissions from './seedPermissions';

// Adds the newly logged in user to the token store
async function handleUserResponse(data: LoginResponse, persist: boolean) {
  const { token, id } = data;

  const storageToken = storage.getToken();

  if (storageToken) {
    storageToken.activeAccount = id.toString();
    const accountIndex = storageToken.accounts.findIndex(
      (account) => account.id === id.toString()
    );

    if (accountIndex === -1) {
      storageToken.accounts.push({ token, id: id.toString() });
    } else {
      storageToken.accounts[accountIndex].token = token;
    }
  }

  storage.setToken(
    storageToken ?? {
      activeAccount: id.toString(),
      accounts: [{ token, id: id.toString() }],
    },
    persist
  );
}

// Fetches the user/session from the server
async function userFn() {
  if (!storage.getToken()) return null;
  const session = await getSession();
  if (!session.user) return null;
  const { user } = await getUser({ userId: session.user.id.toString() });
  return { user };
}

// Logs in the user and returns the user and permissions
// Also adds the user to the token store
async function loginFn(data: {
  username: string;
  password: string;
  rememberMe: boolean;
}) {
  let response: LoginResponse | null = null;
  try {
    response = await loginWithUsernameAndPassword(data);
  } catch (e) {
    console.log(`login error: ${e} in auth.tsx`);
  }

  if (!response || response.status !== 'success') {
    toast.error('Something went wrong. Please try again later.');
    return null;
  }

  await handleUserResponse(response, data.rememberMe);
  const session = await getSession();

  if (!session.user) {
    toast.error('Something went wrong. Please try again later.');
    return null;
  }

  const { user } = await getUser({ userId: session.user.id.toString() });
  return { user, permissions: response.permissions };
}

async function registerFn(data: registerUserProps) {
  await registerUser(data);
  return null;
}

// Removes the user from the token store
// Removes the token store if there are no more accounts
async function logoutFn() {
  const storageToken = storage.getToken();
  if (storageToken) {
    storageToken.accounts = storageToken.accounts.filter(
      (account) => account.id !== storageToken.activeAccount
    );

    if (storageToken.accounts.length === 0) {
      storage.clearToken();
    } else {
      storageToken.activeAccount = storageToken.accounts[0].id;
      storage.setToken(storageToken);
    }
  }
}

// Switches to the account with the given id
function switchAccounts(id: string) {
  const storageToken = storage.getToken();
  if (storageToken) {
    storageToken.activeAccount = id;
    storage.setToken(storageToken);
    toast.success('Switched accounts');
  }
}

type LoginCredentials = {
  username: string;
  password: string;
  rememberMe: boolean;
};
type SignupCredentials = registerUserProps;

const authConfig: ReactQueryAuthConfig<
  { user: IUSERDATA; permissions?: LoginResponse['permissions'] } | null,
  LoginCredentials,
  SignupCredentials
> = {
  userFn,
  loginFn,
  registerFn,
  logoutFn,
};

const {
  useLogin: builtinUseLogin,
  useLogout: builtinUseLogout,
  useRegister,
  useUser,
  AuthLoader,
} = configureAuth(authConfig);

// Modify the built-in useLogin to seed permissions
const useLogin = () => {
  const data = builtinUseLogin();
  const permissionStore = usePermissionStore();
  const queryClient = useQueryClient();

  const mutateAsync = async (input: LoginCredentials) => {
    const res = await data.mutateAsync(input, {
      onError: () => {
        toast.error('Invalid Credentials.');
      },
    });
    if (res?.permissions) seedPermissions(res.permissions, permissionStore);

    // Invalidate all queries on login
    queryClient.invalidateQueries();

    return res;
  };

  return { ...data, mutateAsync };
};

// Modify the built-in useLogout to switch accounts
const useLogout = (id?: string) => {
  const data = builtinUseLogout();
  const queryClient = useQueryClient();

  const mutateAsync = async (
    input: Parameters<(typeof data)['mutateAsync']>[0]
  ) => {
    // Switch to the given account if there is one
    if (id) switchAccounts(id);

    // Logout (delete token)
    await data.mutateAsync(input);

    // Invalidate all queries on logout
    await queryClient.invalidateQueries();
  };

  return { ...data, mutateAsync };
};

export {
  useLogin,
  useLogout,
  useRegister,
  useUser,
  AuthLoader,
  switchAccounts,
};
