import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { MutationConfig } from '../../lib/react-query';

export const initSqrTransaction = async ({ sqrurl }: { sqrurl: string }) =>
  axios.post('/v2/transaction/fromsqr', { sqrurl }).then(({ data }) => data);

type useInitSqrTransactionOptions = {
  config?: MutationConfig<typeof initSqrTransaction>;
};

export const useInitSqrTransaction = ({
  config,
}: useInitSqrTransactionOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: initSqrTransaction,
  });
};
