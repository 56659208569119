import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';

// Menus offer an easy way to build custom, accessible
// dropdown components with robust support for keyboard navigation.

type Props = {
  button: React.ReactNode;
  items: React.ReactNode;
  origin: 'right' | 'left';
};
export default function MyMenu({ button, items, origin }: Props) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button as="div">{button}</Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            'absolute z-10 mt-2 w-max rounded-md bg-white py-2 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none',
            origin === 'right'
              ? 'right-0 origin-top-left'
              : 'left-0 origin-top-right'
          )}
        >
          {items}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
