import { axios } from '@/lib/axios';

export type Role = 'customer' | 'merchant';

// Shape of the permissions that are returned from the server
// Received when logging in
export type Permission =
  | {
      type: 'accept';
    }
  | {
      type: 'sell';
      sell: {
        specIDs: string[] | 'all';
        maxLimit: number;
      };
    }
  | {
      type: 'mint';
      mint: {
        specIDs: string[] | 'all';
        maxLimit: number;
      };
    };

export type LoginResponse = {
  status: 'success';
  token: string;
  roles: Role[];
  permissions: {
    [issuer: string]: Permission[];
  };
  id: number;
};

export const loginWithUsernameAndPassword = async (data: {
  username: string;
  password: string;
}): Promise<LoginResponse> =>
  axios.post('/login', data).then(({ data }) => data);
