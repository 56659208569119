import { IUSERDATA } from '../../utils/types';
import { UsersActionsTypes } from '../actions/actionTypes';

// TODO: consider putting state/action interfaces in a separate file like with actionTypes
export interface UsersState {
  [key: string]: IUSERDATA;
}

const initialState: UsersState = {};

export interface UsersAction {
  type: UsersActionsTypes;
  payload: {
    users: Array<IUSERDATA>;
  };
}

function usersReducer(state: UsersState = initialState, action: UsersAction) {
  switch (action.type) {
    //Reducer for getting all the users
    case UsersActionsTypes.GET_USERS:
      return action.payload.users.reduce(
        (users: UsersState, user: IUSERDATA) => {
          users[user.id] = user;
          return users;
        },
        state
      );
    //Reducer for editing a particular user
    case UsersActionsTypes.EDIT_USER:
      const new_state = Object.assign({}, state);

      const newUserInfo = action.payload.users[0]; //Because the way the original payload is defined, have to unpack it
      const newId = newUserInfo.id;

      new_state[newId].firstName = newUserInfo.firstName;
      new_state[newId].email = newUserInfo.email;

      if (newUserInfo.lastName) {
        new_state[newId].lastName = newUserInfo.lastName;
      }

      if (newUserInfo.profilePic) {
        new_state[newId].profilePic = newUserInfo.profilePic;
      }
      return new_state;
    default:
      return state;
  }
}

export default usersReducer;
