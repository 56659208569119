export enum SentTxnActionsTypes {
  GET_SENT_TXN = 'GET_SENT_TXN',
  UPDATE_SENT_TXN = 'SET_SENT_TXN',
}

export enum TxnActionsTypes {
  // user logs in, get all transactions
  GET_TXNS = 'GET_TXNS',
  // user initialize a new transaction (and it may be completed)
  INIT_TXN = 'INIT_TXN',
  // user received new transaction
  RECEIVE_TXN = 'RECEIVE_TXN',
  // user receives an update to a transaction
  UPDATE_TXN = 'UPDATE_TXN',
  // user accepts or rejects a transaction
  ACCEPT_REJECT_TXN = 'ACCEPT_REJECT_TXN',
  // sqr information returned
  INIT_SQR_TXN = 'INIT_SQR_TXN',
}

export enum AuthActionsTypes {
  // load auth state from local storage
  LOAD = 'LOAD_LOCALSTORAGE',
  // user login (ONLY logs into backend; receives and stores auth token)
  LOGIN = 'LOGIN',
  // user logout (specific account)
  LOGOUT = 'LOGOUT',
  // user logout (all accounts)
  LOGOUT_ALL = 'LOGOUT_ALL',
  // switch accounts
  SWITCH = 'SWITCH_TO_ACCOUNT',
}

export enum AccountDataActionsTypes {
  // save full account data state to store for later retrieval
  STORE = 'STORE_ACCOUNT_DATA',
  // clear all data of an account from the application
  CLEAR = 'CLEAR_ACCOUNT_DATA',
  // signals other slices of state to clear the currently active account
  CLEAR_ACTIVE = 'CLEAR_ACTIVE_ACCOUNT_DATA',
  // clear entirety of account data
  CLEAR_ALL = 'CLEAR_ALL',
}

export enum BoltActionsTypes {
  // get all the bolts in the user's wallet
  GET_USER_BOLTS = 'GET_USER_BOLT',
  // create a new bolt spec
  CREATE_BOLT = 'CREATE_BOLT',
  // mint a bolt
  MINT_BOLT = 'MINT_BOLT',
  // donate a bolt
  DONATE_BOLT = 'DONATE_BOLT',
}

export enum UsersActionsTypes {
  // get all bolt users
  GET_USERS = 'GET_USERS',
  EDIT_USER = 'EDIT_USER',
}

// The modal reducer currently controls only the ExpiredSessionModal
export enum ModalActionsTypes {
  // open top-level modal
  OPEN_MODAL = 'OPEN_MODAL',
  // close top-level modal
  CLOSE_MODAL = 'CLOSE_MODAL',
}

// used to keep QR modal's unique
export enum QrActionTypes {
  SET_CODE = 'SET_QR_CODE',
  SET_CLOSED = 'SET_QR_CLOSED',
  SET_OPEN = 'SET_QR_OPEN',
  FORCE_CLOSED = 'FORCE_QR_CLOSED',
}
