import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import { IssuerData } from '../../utils/types';

type IssuerResult = {
  status: string;
  result: IssuerData;
};

export const getIssuer = ({ issuerId }: { issuerId: string }) => {
  return axios
    .get(`/user/issuer/${issuerId}`)
    .then((res) => res.data as IssuerResult);
};

type UseIssuerOptions = {
  issuerId: string;
  config?: QueryConfig<typeof getIssuer>;
};

export const useIssuer = ({ config, issuerId }: UseIssuerOptions) => {
  return useQuery({
    ...config,
    queryKey: ['issuer', issuerId],
    queryFn: () => getIssuer({ issuerId }),
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};
