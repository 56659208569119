import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import { CheckUserResponse } from '../../services/api';

type UsernameCheckResult = CheckUserResponse;

export const getUsernameCheck = ({ username }: { username: string }) => {
  return axios
    .get(`/user/check-username/${username}`)
    .then((res) => res.data as UsernameCheckResult);
};

type UseUsernameCheckOptions = {
  username: string;
  config?: QueryConfig<typeof getUsernameCheck>;
};

// We prefetch and cache the username check
export const useUsernameCheck = ({
  config, // eslint-disable-line @typescript-eslint/no-unused-vars
  username,
}: UseUsernameCheckOptions) => {
  return useQuery({
    //...config,
    queryKey: ['checkUser', username],
    queryFn: () => getUsernameCheck({ username }),
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};
