import { CircularProgress } from '@/components/Elements/circularprogress';
import { FaCheckCircle } from 'react-icons/fa';
import { useBolt } from '../../features/bolt/getBolt';
import { useUser } from '../../features/user/getUser';
import useTransactionStore from '../../stores/TransactionStore';
import { asCurrencyString } from '../../utils/money';
import { DRYTXNDATA, IBOLTDATA, IUSERDATA } from '../../utils/types';
import BoltLogo from '../common/BoltLogo';
import { CustomDialog } from '../common/Dialog';

function Content({
  bolt,
  transaction,
  receiver,
}: {
  bolt: IBOLTDATA;
  transaction: DRYTXNDATA;
  receiver: IUSERDATA;
}) {
  const { setSentTransaction } = useTransactionStore();

  return (
    <div className="flex flex-col items-center gap-4 p-8">
      <div className="flex flex-col items-center text-success">
        <div>
          <FaCheckCircle className="h-10 w-10" />
        </div>
        <div className="text-2xl font-bold">Payment Sent</div>
      </div>
      <div className="divider" />
      <div className="space-y-2">
        <BoltLogo specID={bolt.specID} />
        <div className="text-lg font-bold">{bolt.specName}</div>
      </div>
      <div className="divider" />
      <div className="w-full text-base-content/80">
        <div className="flex w-full justify-between">
          <span>To:</span>
          <span className="font-bold">@{receiver.username}</span>
        </div>
        <div className="flex w-full justify-between">
          <span>Amount Sent:</span>
          <span className="font-bold">
            {asCurrencyString(transaction.amount)}
          </span>
        </div>
        <div className="flex w-full justify-between">
          <span>ZUZ:</span>
          <span className="font-bold">{bolt.specName}</span>
        </div>
      </div>
      <div className="mt-5 w-full">
        <button
          type="button"
          className="btn-secondary btn float-right"
          onClick={() => setSentTransaction(null)}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
}

export default function SendPaymentDialog() {
  const { sentTransaction, setSentTransaction } = useTransactionStore();
  const receiver = useUser({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    userId: sentTransaction?.receiver,
    config: {
      enabled: !!sentTransaction,
    },
  });
  const bolt = useBolt({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    specId: sentTransaction?.bolt,
    config: { enabled: !!sentTransaction },
  });

  return (
    <CustomDialog
      isOpen={!!sentTransaction}
      onClose={() => setSentTransaction(null)}
    >
      {!bolt.isSuccess || !receiver.isSuccess || !sentTransaction ? (
        <CircularProgress />
      ) : (
        <Content
          bolt={bolt.data.bolt}
          transaction={sentTransaction}
          receiver={receiver.data?.user}
        />
      )}
    </CustomDialog>
  );
}
