import { axios } from '../../lib/axios';

type ShowQRResult = {
  status: string;
};

export const showQR = ({
  id,
  amount,
  note,
  tax,
}: {
  id: string;
  amount: number;
  note: string;
  tax: number;
}) => {
  return axios
    .post(`/v2/transaction/showqr`, {
      id,
      amount,
      note,
      tax,
    })
    .then((res) => res.data as ShowQRResult);
};
