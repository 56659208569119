import { ITXNDATA } from '@/utils/types';
import { TxnActionsTypes } from './actionTypes';

//Action for receiving transaction
export const receiveTxnAction = (transaction: ITXNDATA, firstTime = false) => {
  return {
    type: TxnActionsTypes.RECEIVE_TXN,
    payload: { transaction, firstTime },
  };
};

//Action for updating transactions
export const updateTxnAction = (transaction: ITXNDATA) => {
  return {
    type: TxnActionsTypes.UPDATE_TXN,
    payload: { transaction },
  };
};

//Action for accepting/rejecting transactions
export const acceptRejectTxnAction = (
  transaction: ITXNDATA,
  firstTime = false
) => {
  return {
    type: TxnActionsTypes.ACCEPT_REJECT_TXN,
    payload: { transaction, firstTime },
  };
};
