// create a some globally available state that is used to make the
// payment recieved/sent dialogs show up.

import { create } from 'zustand';
import { DRYTXNDATA } from '../utils/types';

type TransactionStore = {
  sentTransaction: DRYTXNDATA | null;
  receivedTransaction: DRYTXNDATA | null;
  setSentTransaction: (sentTransaction: DRYTXNDATA | null) => void;
  setReceivedTransaction: (receivedTransaction: DRYTXNDATA | null) => void;
};

export default create<TransactionStore>((set) => ({
  sentTransaction: null,
  receivedTransaction: null,
  setSentTransaction: (sentTransaction) => set(() => ({ sentTransaction })),
  setReceivedTransaction: (receivedTransaction) =>
    set(() => ({ receivedTransaction })),
}));
