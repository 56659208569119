import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { FaCheck, FaChevronDown } from 'react-icons/fa';

// Listboxes are a great foundation for building custom, accessible select menus
// for the app, complete with robust support for keyboard navigation.
// These are built with first-class support for integration with React Hook Form.

type ListboxOptionType<T> = {
  values: T[]; // the list of values to display
  displayValue: (prop: T) => string; // maps a value to a display string
  keyValue: (prop: T) => string; // maps a value to a unique key string
};

// This is the list of options that will be displayed in the listbox.
function ListboxOptions<T>({
  values,
  keyValue,
  displayValue,
}: ListboxOptionType<T>) {
  // If there are no values, display a message saying so.
  if (values.length === 0)
    return (
      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
        Nothing found.
      </div>
    );

  return (
    <>
      {values.map((value) => (
        <Listbox.Option key={keyValue(value)} value={value}>
          {/* We're given a render prop that tells us whether this option is selected.
           * We can use that to conditionally render a checkmark next to the option.
           */}
          {({ selected }) => (
            <div className="relative cursor-default select-none rounded-md py-2 pl-8 pr-4 hover:bg-primary hover:text-white">
              <span
                className={clsx(
                  selected ? 'font-bold' : 'font-normal',
                  'block truncate'
                )}
              >
                {displayValue(value)}
              </span>
              {selected && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                  <FaCheck />
                </span>
              )}
            </div>
          )}
        </Listbox.Option>
      ))}
    </>
  );
}

type ListboxProps<T, K extends FieldValues> = Pick<
  UseControllerProps<K>, // type parameter K is the type of the form values and will enforce that the name prop is a key of the form values
  'control' | 'name' // these are the props that the Controller wrapper component needs
> & {
  values: T[]; // the list of values to display
  displayValue: (prop: T) => string; // maps a value to a display string
  keyValue: (prop: T) => string; // maps a value to a unique key string
  placeholder: string; // the placeholder text to display when no value is selected
  multiple?: boolean; // whether multiple values can be selected
};
export default function MyListbox<T, K extends FieldValues>({
  control,
  name,
  values,
  displayValue,
  keyValue,
  placeholder,
  multiple = false,
}: ListboxProps<T, K>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Listbox as="div" multiple={multiple} className="relative" {...field}>
          <div>
            <Listbox.Button className="input-bordered input relative w-full text-left">
              <span className="block truncate">
                {/* If this is a multi-select, field.value
                 * will be an array of values. We can probably
                 * in the future use Array.isArray to
                 * an equivalent effect  */}
                {multiple
                  ? field.value?.length > 0
                    ? field.value
                        .map((value: any) => displayValue(value))
                        .join(', ')
                    : placeholder
                  : field.value
                  ? displayValue(field.value)
                  : placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <FaChevronDown
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-40 max-h-40 w-full overflow-auto rounded-md border border-primary bg-white p-2 shadow-2xl ring-1 ring-base-300">
              <ListboxOptions<T>
                keyValue={keyValue}
                displayValue={displayValue}
                values={values}
              />
            </Listbox.Options>
          </Transition>
        </Listbox>
      )}
    />
  );
}
