import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { MutationConfig } from '../../lib/react-query';
import { getSession } from '../user/auth/getSession';

export const giveFeedback = async ({
  type,
  msg,
  canContact,
}: {
  type: string;
  msg: string;
  canContact: boolean;
}) => {
  const session = await getSession();

  if (!session.user) throw new Error('You must be logged in to give feedback');

  const username = session.user.username;
  const browser = window.navigator.userAgent;

  axios.post(`/general/feedback`, { type, msg, canContact, username, browser });
};

type feedback = {
  config?: MutationConfig<typeof giveFeedback>;
};

export const useGiveFeedback = ({ config }: feedback = {}) => {
  return useMutation({
    ...config,
    mutationFn: giveFeedback,
  });
};
