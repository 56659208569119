import { useInfiniteQuery } from '@tanstack/react-query';

import { axios } from '../../lib/axios';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';
import { DRYTXNDATA } from '../../utils/types';

type TransactionResult = {
  status: string;
  page: string;
  limit: string;
  more: string;
  transactions: DRYTXNDATA[];
};

export const getTransactions = ({ pageParam = 0 }) => {
  return axios
    .get(`/v2/transaction/user-txns?page=${pageParam}`)
    .then((response) => response.data as TransactionResult);
};

type QueryFnType = typeof getTransactions;

type UseTransactionsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useInfiniteTransactions = ({
  config, // eslint-disable-line @typescript-eslint/no-unused-vars
}: UseTransactionsOptions = {}) => {
  return useInfiniteQuery<ExtractFnReturnType<QueryFnType>>({
    // ...config,
    queryKey: ['transactions'],
    queryFn: getTransactions,
    getNextPageParam: (lastPage, allPages) => +lastPage.page + 1, // eslint-disable-line @typescript-eslint/no-unused-vars
  });
};
