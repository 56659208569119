import storage from '@/utils/storage';
import { useQueryClient } from '@tanstack/react-query';
import { createContext } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import io, { Socket } from 'socket.io-client';
import { env } from '../env/client.mjs';
import { axios } from '../lib/axios';
import { setOpen } from '../redux/actions/qrAction';
import { receiveTxnAction, updateTxnAction } from '../redux/actions/txnAction';
import { logURL } from '../services/api';
import useTransactionStore from '../stores/TransactionStore';
import { logerrBackend } from '../utils/logger';

const WebSocketContext = createContext({});

export { WebSocketContext };

function downcode() {
  let x = Date.now();
  const result = [];
  while (x > 0 && result.length < 5) {
    const val = x % 26;
    result.push(String.fromCharCode(65 + val));
    const y = (x - val) / 26;
    x = y;
  }
  return result.join('');
}

function upcode() {
  let x = Math.random();
  const result = [];
  while (x > 0 && result.length < 10) {
    x = x * 26;
    const val = Math.floor(x);
    x = x - val;
    result.push(String.fromCharCode(65 + val));
  }
  return result.join('');
}

export const QrUniqId = `${downcode()}-${upcode()}`;

console.log(`QR IS: ${QrUniqId}`);

function checkTypeActing(msg: string, actingID: any) {
  console.log(`CTA: ${msg} ${actingID} ${typeof actingID}`);
  if (typeof actingID !== 'string') {
    logerrBackend(
      `actingID is not a string! ${msg} ${actingID} ${typeof actingID}`,
      false
    );
  }
}

const WebSocketProvider = ({ children }: any) => {
  let socket: Socket = undefined as any;

  const dispatch = useDispatch();
  const { setReceivedTransaction: setReceivedTransactionId } =
    useTransactionStore();
  const queryClient = useQueryClient();

  if (!socket) {
    const token = storage.getToken();
    let activeToken: string | undefined = undefined;
    if (token !== undefined) {
      activeToken = token?.accounts.find(
        (a) => a.id === token?.activeAccount
      )?.token;
    }

    // create single Socket instance per page load
    socket = io(env.VITE_SERVER_URL);
    console.log(`socket created: ${env.VITE_SERVER_URL}`);
    // attach event listeners
    socket
      .on('connect', () => {
        if (token) {
          socket.emit('authenticate', {
            token: activeToken,
            actingAs: token.activeAccount,
            qrcode: QrUniqId,
          });
        }
      })
      .on('authenticated', () => {
        console.log('socket authenticated');
        checkTypeActing('AUTHENTICATED', token?.activeAccount);
        socket.emit('whoiam', {
          actingAs: token?.activeAccount,
          qrcode: QrUniqId,
        });
      })
      .on('unauthorized', (/*msg: any*/) => {
        console.log('could not connect socket');
      })
      .on('transactionInitiated', (msg: any) => {
        console.log(`initiated: ${JSON.stringify(msg)}`);
        console.log(msg);
        dispatch(receiveTxnAction(msg.socketResp, false));
      })
      .on('transactionCompleted', (msg: any) => {
        console.log(`completed: ${JSON.stringify(msg)}`);
        console.log(msg);
        queryClient.invalidateQueries(['my-bolts']);
        setReceivedTransactionId(msg.socketResp);
        if (msg.socketResp.receiver !== token?.activeAccount) {
          logerrBackend(
            `transactionCompleted: actingId=${token?.activeAccount} receiver=${msg.socketResp.receiver}`,
            false
          );
        }

        if (
          'qrCreator' in msg &&
          msg.qrCreator !== '' &&
          msg.qrCreator !== QrUniqId
        )
          console.log(`Bad qrcreator code: ${msg.qrCreator} != ${QrUniqId}`);
        dispatch(setOpen(false));
      })
      .on('activityHappened', (msg: any) => {
        console.log('activity for a different profile than I am in now');
        console.log(msg);
        // create and dispatch an event that will cause an indicator
        //to appear next to the 'activityFor' userid - which should
        //be one of the profiles for this user.
        //dispatch(receiveTxnAction(msg.socketResp));
      })
      .on('transactionUpdated', (msg: any) => {
        console.log(`updated ${JSON.stringify(msg)}`);
        console.log(msg);
        dispatch(updateTxnAction(msg));
      })
      .on('closeqr', (msg: any) => {
        console.log(`closeqr: ${msg}`);
        dispatch(setOpen(false));
      })
      .on('notification', (msg: any) => {
        console.log(msg);
      })
      .on('tail', (msg: any) => {
        console.log(`=== Tailing server log:${msg['filename']}`);
        for (const line of msg['lines']) {
          if (line != '') console.log(`- ${line}`);
        }
      })
      .on('reload', (msg: any) => {
        console.log(msg);
        if (msg.version > env.VITE_VERSION) {
          toast.error('An update is needed.  You might need to re-login.');
          window.location.reload();
        }
      });
  }

  // retry connect without leaking sockets
  const connect = (newActingID: string) => {
    console.log(`Connecting to socket as ${newActingID}`);

    const token = storage.getToken();
    if (!token) {
      console.log('no token');
      return;
    }

    token.activeAccount = newActingID;
    storage.setToken(token);

    if (!socket.connected) {
      // 'connect' event listener triggers authentication
      console.log('try connect');
      socket.connect();
    } else {
      // temporary fix: restarts connection with new authentication token
      console.log('try disconnect');
      socket.disconnect();
      console.log('try connect after disconncet');
      socket.connect();
    }
  };

  const ws = {
    socket: socket,
    connect: connect,
  };

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export type WS = {
  socket: Socket;
  connect: (token: string, uid: string) => void;
  qrcode: string;
};

////////////////////////////////////////////////////////////////
// log backend to console

async function startLog2Console() {
  const response = await axios.get(`${logURL}/?log=one`);
  console.log(response);
}

Object.assign(window, { startLog2Console });

export default WebSocketProvider;
