import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from '@tanstack/react-query';

export const sellBolt = async ({
  specId,
  amount,
  email,
}: {
  specId: string;
  amount: number;
  email: string;
}) =>
  axios
    .post('/stripe/sell', {
      boltID: specId,
      amount,
      email,
    })
    .then(({ data }) => {
      console.log(data);
      return data;
    });

type UseSellBoltOptions = {
  config?: MutationConfig<typeof sellBolt>;
};

export const useSellBolt = ({ config }: UseSellBoltOptions) => {
  return useMutation({
    ...config,
    mutationFn: sellBolt,
  });
};

/*
const sellBolt = async (specID: SPECUUID, amount: number, email: string) => {
  const body = {
    boltID: specID,
    amount: amount,
    email: email,
  };
*/
