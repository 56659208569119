import { FaEdit, FaStore, FaTrash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useDelegations } from '../../features/user/getDelegations';
import { useUser } from '../../features/user/getUser';
import { ExtractFnReturnType } from '../../lib/react-query';
import { delegatePermissions } from '../../redux/actions/usersAction';

type DelegateCardProps = {
  userId: string;
  permission: NonNullable<
    ExtractFnReturnType<typeof useDelegations>['data']
  >['permissions'][number];
};

function DelegateCard({ userId, permission }: DelegateCardProps) {
  const { data, status } = useUser({ userId });
  const dispatch = useDispatch();

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'error') {
    return <div>Error</div>;
  }

  return (
    <div className="group/item flex items-center gap-2 rounded-md px-4 py-3 hover:bg-slate-200">
      <div className="flex aspect-square h-10 items-center justify-center rounded-full bg-secondary text-white">
        <FaStore />
      </div>
      <div className="flex-1 overflow-hidden">
        <div className="overflow-hidden text-ellipsis whitespace-nowrap font-bold">
          @ {data.user.businessName ?? data.user.username}
        </div>
        <div className="flex flex-wrap gap-1 text-sm">
          {permission.map((p) => (
            <span
              key={JSON.stringify(p)}
              className="badge badge-primary badge-sm font-bold uppercase text-white"
            >
              {p.type}
            </span>
          ))}
        </div>
      </div>
      <Link
        to={`/settings/delegations/edit/${userId}`}
        className="btn-secondary btn-ghost btn-sm btn invisible group-hover/item:visible"
      >
        <FaEdit />
      </Link>
      <button
        onClick={() => {
          dispatch(
            delegatePermissions(
              userId,
              {},
              {
                specIDs: [],
                maxLimit: 0,
              },
              false
            )
          );
        }}
        className="btn-secondary btn-ghost btn-sm btn invisible group-hover/item:visible"
      >
        <FaTrash />
      </button>
    </div>
  );
}

function Permissions() {
  const { data: delegates, status } = useDelegations();

  if (status === 'loading') {
    return <div>Loading...</div>;
  }

  if (status === 'error') {
    return <div>Error</div>;
  }

  if (Object.entries(delegates.permissions).length === 0) {
    return <div>No Delegated Permissions</div>;
  }

  return (
    <div>
      {Object.entries(delegates.permissions).map(([userId, permission]) => (
        <DelegateCard key={userId} userId={userId} permission={permission} />
      ))}
    </div>
  );
}

function DelegatedPermissions() {
  const navigate = useNavigate();

  return (
    <>
      <Permissions />
      <div className="mt-4 flex justify-between">
        <button
          className="btn-secondary btn-sm btn"
          onClick={() => navigate('/settings/delegations/create')}
        >
          Add Delegate
        </button>
      </div>
    </>
  );
}

export default DelegatedPermissions;
