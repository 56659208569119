import { CircularProgress } from '@/components/Elements/circularprogress';
import { Link as MuiLink } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useBolts } from '../../features/bolt/getBolts';
import { useIssuer } from '../../features/issuers/getIssuer';
import { useStory } from '../../features/stories/getStory';
import { CAMPAIGNDATA, IssuerData } from '../../utils/types';
import BoltLogo, { IssuerLogo } from '../common/BoltLogo';

////////////////////////////////////////////////////////////////
// If the issuer has campaigns, this shows a single campaign
type CampaignProps = {
  campaign: CAMPAIGNDATA;
};
function Campaign({ campaign }: CampaignProps) {
  console.log(campaign);
  return (
    <div className="flex flex-col items-center gap-4 rounded-md p-8 shadow-lg ring-1 ring-base-content/10">
      <div className="font-display text-2xl font-bold tracking-tighter text-primary">
        {campaign.name}
      </div>
      <div>
        <BoltLogo specID={campaign.bolt} />
      </div>
      {campaign.buyzuz && (
        <div>
          <Link
            className="btn-secondary btn-sm btn-wide btn"
            to={campaign.buyzuz}
          >
            Buy ZUZ
          </Link>
        </div>
      )}
      <div dangerouslySetInnerHTML={{ __html: campaign.description }} />
    </div>
  );
}

////////////////////////////////////////////////////////////////
// If the issuer has campaigns, this shows all the campaigns
type CampaignsProps = {
  issuer: IssuerData;
};
function Campaigns({ issuer }: CampaignsProps) {
  return (
    <div className="flex flex-col gap-4">
      {issuer.campaigns.map((campaign) => (
        <Campaign key={campaign.code} campaign={campaign} />
      ))}
    </div>
  );
}

////////////////////////////////////////////////////////////////
// The issuer story is displayed here
type StoriesProps = {
  issuer: IssuerData;
};
function Stories({ issuer }: StoriesProps) {
  const { data: story, status: storyStatus } = useStory({
    issuerId: issuer.id.toString(),
  });

  if (storyStatus === 'loading') return <CircularProgress />;
  if (storyStatus === 'error') return <div>Something went wrong</div>;

  return (
    <div className="flex w-full flex-col items-center gap-6">
      {story.stories.map((story) => (
        <div
          key={story.title}
          className="w-full max-w-lg overflow-hidden rounded-lg shadow-2xl"
        >
          {story.image && (
            <div>
              <img src={story.image} alt="story" className="w-full" />
            </div>
          )}
          <div className="p-4">
            <div className="text-xl font-bold">{story.title}</div>
            {story.caption && (
              <div dangerouslySetInnerHTML={{ __html: story.caption }} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

////////////////////////////////////////////////////////////////
// The list of accepted ZUZ are displayed with this compnent
type AcceptedZuzProps = {
  issuer: IssuerData;
};
function AcceptedZuz({ issuer }: AcceptedZuzProps) {
  const bolts = useBolts({ specIds: issuer.acceptedZuz ?? [] });

  if (bolts.isLoading) return <CircularProgress />;
  if (bolts.isError) return <div>Something went wrong</div>;
  if (!bolts.data.length) return null;

  return (
    <div className="mt-10 flex flex-col">
      <span className="text-lg font-bold text-secondary">We Accept</span>
      <div className="mt-4 flex w-full flex-wrap justify-start gap-4">
        {bolts.data.map((bolt) => (
          <div
            key={bolt.specID}
            className="flex w-full items-center justify-center gap-3 rounded border border-solid border-secondary p-3 shadow-lg sm:w-auto"
          >
            <div className="w-16">
              <BoltLogo specID={bolt.specID} />
            </div>
            <span className="text-sm font-bold">{bolt.specName}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

////////////////////////////////////////////////////////////////
// This is exported component that get vendor information (useIssuer)
// and then displays all the vendor's information: their logo,
// username, etc. followed by AcceptedZuz,
// Stories, and then Campaigns
function Issuer() {
  const params = useParams();
  const issuer = useIssuer({ issuerId: params.issuerId as string });

  if (issuer.isLoading) return <CircularProgress />;
  if (issuer.isError) return <div>Something went wrong</div>;

  return (
    <div className="flex flex-col gap-8">
      <div className="mt-8 flex items-center justify-center gap-8 sm:flex-col-reverse">
        <div className="flex flex-col items-center gap-2">
          <span className="text-2xl font-bold sm:text-3xl sm:leading-4 sm:text-secondary">
            {issuer.data.result.businessName}
          </span>
          <span className="text-sm font-bold text-primary">
            @{issuer.data.result.username}
          </span>
        </div>
        <div className="w-32">
          <IssuerLogo issuerId={issuer.data.result.id.toString()} />
        </div>
      </div>
      {issuer.data.result.buyurl && (
        <div className="flex w-full justify-center">
          <MuiLink
            component={Link}
            to={issuer.data.result.buyurl ?? '/'}
            className="btn-secondary btn mt-6 w-full sm:w-auto sm:rounded-full"
          >
            See Campaigns
          </MuiLink>
        </div>
      )}
      <AcceptedZuz issuer={issuer.data.result} />
      <Stories issuer={issuer.data.result} />
      <Campaigns issuer={issuer.data.result} />
    </div>
  );
}

export default Issuer;
