import { CircularProgress } from '@/components/Elements/circularprogress';
import React from 'react';
import { toast } from 'react-hot-toast';
import { Navigate, useParams } from 'react-router-dom';
import { useUser } from '../features/user/getUser';
import SpendZuz from './SpendZuz';

function QR() {
  const [spendZuzDialog, setSpendZuzDialog] = React.useState(true);

  // get the params from the url
  const { issuerId, amount, code } = useParams();

  // check if the params are valid
  if (!issuerId || !amount || !code) {
    toast.error('Invalid QR redirect');
    return <Navigate to="/wallet" />;
  }

  // parse the amount
  const allowTips = amount[0] == 'T';
  const parsedAmount = parseFloat(allowTips ? amount.substring(1) : amount);

  const issuer = useUser({ userId: issuerId });

  if (issuer.status !== 'success') return <CircularProgress />;
  if (!spendZuzDialog) return <Navigate to="/wallet" />;

  const storeName = issuer.data.user.businessName
    ? issuer.data.user.businessName
    : `@${issuer.data.user.username}`;

  return (
    <SpendZuz
      isOpen={spendZuzDialog}
      setIsOpen={setSpendZuzDialog}
      storeName={storeName}
      storeId={issuer.data.user.id}
      amt={parsedAmount}
      allowTips={allowTips}
      extraZuz={[]}
      qrCreator={code}
      posTid={''}
    />
  );
}

export default QR;
