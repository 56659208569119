// @ts-check
import { z } from 'zod';

/**
 * Specify your environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const schema = z.object({
  VITE_WEBSITE_URL: z.string().url(),
  VITE_APP_URL: z.string().url(),
  VITE_BACKEND_URL: z.string().url(),
  VITE_SERVER_URL: z.string().url(),
  VITE_ALLOW_ZUZSPEC: z.preprocess(
    (value) => value === 'true' || value === '1',
    z.boolean()
  ),
  VITE_ABOUT_URL: z.string().url(),
  VITE_VERSION: z.coerce.number(),
  VITE_QR_PREFIX: z.string(),
  VITE_AWSS3_URL: z.string().url(),
});
