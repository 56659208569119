import QRScannerDialog from '@/components/QRScanner';
import { logerrBackend } from '@/utils/logger';
import { StyledEngineProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { toast, ToastBar, Toaster } from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ReceivePaymentDialog from '../components/Dialog/ReceivePaymentDialog';
import SendPaymentDialog from '../components/Dialog/SendPaymentDialog';
import { Notification } from '../components/notifications/Notification';
import ScrollToTop from '../components/ScrollToTop';
import CustomThemeProvider from '../components/ThemeProvider';
import WebSocketProvider from '../context/WebSocketContext';
import { queryClient } from '../lib/react-query';
import store from '../redux';

const ErrorFallback = () => {
  return (
    <div
      className="flex h-screen w-screen flex-col items-center justify-center space-y-4"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Something went wrong.</h2>
      <button
        className="btn"
        onClick={() => window.location.assign(window.location.origin)}
      >
        Refresh
      </button>
    </div>
  );
};

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <FaSpinner size="xl" />
        </div>
      }
    >
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, info) =>
          logerrBackend(
            `Zuz Client Error: ${err.message}\nComponent Stack: ${info.componentStack}`,
            false
          )
        }
      >
        <StyledEngineProvider injectFirst>
          <CustomThemeProvider>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <WebSocketProvider>
                  <ReactQueryDevtools />
                  <QRScannerDialog />
                  <ReceivePaymentDialog />
                  <SendPaymentDialog />
                  <CssBaseline />
                  <Toaster
                    position="bottom-right"
                    toastOptions={{
                      duration: 5000,
                    }}
                  >
                    {(t) => (
                      <ToastBar toast={t}>
                        {({ message, icon }) => (
                          <>
                            <Notification
                              notification={{
                                id: t.id,
                                icon,
                                message,
                              }}
                              onDismiss={() => toast.dismiss(t.id)}
                            />
                          </>
                        )}
                      </ToastBar>
                    )}
                  </Toaster>
                  <BrowserRouter>
                    <ScrollToTop />
                    {children}
                  </BrowserRouter>
                </WebSocketProvider>
              </QueryClientProvider>
            </Provider>
          </CustomThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
