export function jsonParse<T>(json: string | null) {
  if (!json) return null;

  let parse: T | null;
  try {
    parse = JSON.parse(json);
  } catch (e) {
    parse = null;
  }

  return parse;
}
