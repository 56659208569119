import { LinkButton } from '@/components/Elements/linkbutton';
import { useLocation } from 'react-router-dom';

function Confirmation() {
  const location = useLocation();

  const verified = new URLSearchParams(location.search).get('verified');
  const ifVerified = verified === 'true';

  return (
    <div className="mt-8 flex flex-col items-center justify-center  gap-4">
      {ifVerified && (
        <>
          <div>Thank you. Your email is now verfied.</div>
          <div className="flex">
            <span className="mr-1">Back to</span>
            <LinkButton className="normal-case" to="/login">
              Log In
            </LinkButton>
          </div>
        </>
      )}
      {!ifVerified && (
        <>
          <div>
            Something went wrong when confirming your email. Please try again.
          </div>
          <div className="flex">
            <span className="mr-1">Back to</span>
            <LinkButton to="/login" className="normal-case">
              Log In
            </LinkButton>
          </div>
        </>
      )}
    </div>
  );
}

export default Confirmation;
