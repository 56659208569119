import { CircularProgress } from '@/components/Elements/circularprogress';
import { useUser } from '@/lib/auth';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import {
  EmailFreq,
  FreqNums,
  useUpdatePreferences,
} from '../../features/preferences/updatePreferences';
import { useZodForm } from '../../utils/form';
import { IUSERDATA } from '../../utils/types';

const EmailFrequencies: Record<keyof typeof EmailFreq, string> = {
  ALWAYS: 'Always',
  PERDAY: 'Daily',
  PERWEEK: 'Weekly',
  NEVER: 'Never',
};

type EmailPreferencesProps = {
  user: IUSERDATA;
};

function EmailPreferences({ user }: EmailPreferencesProps) {
  const methods = useZodForm({
    schema: z.object({
      frequency: z.nativeEnum(EmailFreq),
    }),
    defaultValues: {
      frequency: FreqNums[user.emailfreq],
    },
  });

  const currentUser = useUser();
  const updateUserPreferences = useUpdatePreferences({
    config: {
      onSuccess: async () => {
        await currentUser.refetch();
        toast.success('Preferences updated');
      },
    },
  });

  return (
    <form
      onSubmit={methods.handleSubmit(async (values) => {
        updateUserPreferences.mutate({
          emailFreq: values.frequency,
        });
      })}
    >
      <div className="flex flex-col gap-4">
        <span className="text-lg">Email Preferences</span>
        <div className="flex flex-col gap-2">
          {Object.values(EmailFreq).map((freq) => (
            <label key={freq} className="flex items-center text-sm">
              <input
                type="radio"
                className="radio-primary radio mr-2"
                value={freq}
                {...methods.register('frequency')}
              />
              {EmailFrequencies[freq]}
            </label>
          ))}
        </div>
      </div>
      <button
        className="btn-primary btn-sm btn mt-4"
        disabled={!methods.formState.isDirty}
        type="submit"
      >
        Save
      </button>
    </form>
  );
}

function Preferences() {
  const currentUser = useUser();

  if (!currentUser.isSuccess) return <CircularProgress />;
  if (!currentUser.data) return <div>Not logged in</div>;

  return (
    <>
      <EmailPreferences user={currentUser.data.user} />
    </>
  );
}

export default Preferences;
