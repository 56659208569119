import { Avatar } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FaStore } from 'react-icons/fa';
import { useBolt } from '../../features/bolt/getBolt';
import { useIssuer } from '../../features/issuers/getIssuer';
import { getImageURL } from '../../services/api';

type BoltLogoProps = {
  specID: string;
};

const BoltLogo: FC<BoltLogoProps> = ({ specID }) => {
  const bolt = useBolt({ specId: specID });
  const issuer = useIssuer({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    issuerId: bolt.data?.bolt.issuer.toString()!,
    config: {
      // we only do a check if the bolt has been fetched, otherwise we get into an infinite loop.
      // Gabriel - please put in the meaning of the expression below.
      enabled: !!bolt.data?.bolt.issuer,
    },
  });

  const [logo, setLogo] = useState('');

  useEffect(() => {
    // Gabriel - I assume isSuccess is something that is added by the react query?  Can you annotate this.
    if (!issuer.isSuccess) return;

    for (const campaign of issuer.data.result.campaigns) {
      if (campaign.bolt == specID && campaign.logo && campaign.logo.length > 0)
        setLogo(campaign.logo);
    }

    // we never found a logo for the specific bolt, so use issuer's if there is one
    if (issuer.data.result.logo && issuer.data.result.logo.length > 0)
      setLogo(issuer.data.result.logo);
  }, [bolt.status, issuer.status]);

  return logo ? (
    <Avatar
      className="h-full w-full"
      variant="rounded"
      src={getImageURL(logo)}
    />
  ) : (
    <FaStore />
  );
};

export default BoltLogo;

type IssuerLogoProps = {
  issuerId: string;
};

export const IssuerLogo: FC<IssuerLogoProps> = ({ issuerId }) => {
  const { data, status } = useIssuer({ issuerId });

  if (status === 'loading' || status === 'error' || !data.result.logo)
    return (
      <Avatar className="h-full w-full">
        <FaStore />
      </Avatar>
    );

  return (
    <img
      className="w-full rounded object-contain"
      src={getImageURL(data.result.logo)}
    />
  );
};
