import { Renderable } from 'react-hot-toast';
import { FaTimes } from 'react-icons/fa';

export type NotificationProps = {
  notification: {
    id: string;
    message?: Renderable;
    icon?: Renderable;
  };
  onDismiss: (id: string) => void;
};

// Notifications are used to display messages to the user.
// They are displayed in the top right corner of the screen.
// They are dismissed by clicking the X button.
export const Notification = ({
  notification: { id, message, icon },
  onDismiss,
}: NotificationProps) => {
  return (
    <div className="w-full max-w-md">
      <div role="alert">
        <div className="flex">
          <div className="flex flex-1 p-3">
            {icon}
            <span className="text-opacity-75">{message}</span>
          </div>
          <div>
            <button
              className="btn-ghost btn-xs btn-square btn opacity-50"
              onClick={() => {
                onDismiss(id);
              }}
            >
              <span className="sr-only">Close</span>
              <FaTimes className="h-4 w-4" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
