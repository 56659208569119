import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

type TextFieldProps = {
  label?: string;
  placeholder?: string;
  id?: string;
  type: string;
  schema: any;
  name: string;
  errorMsg?: string;
};

export function ZodFormTextField({
  label,
  placeholder,
  id,
  type = 'text',
  schema,
  name,
  errorMsg,
}: TextFieldProps) {
  return (
    <div>
      {label ? (
        <label className="label">
          <span className="label-text">{label}</span>
        </label>
      ) : null}
      <input
        id={id}
        className="input-bordered input w-full"
        type={type}
        placeholder={placeholder}
        {...schema.register(name)}
      />
      <span className="text-xs text-error">{errorMsg}</span>
    </div>
  );
}

// field for entering a password.  Defaults to password type which
// hides typing.  Allows user to switch on text
export function ZodPasswordField({
  label,
  placeholder,
  id,
  schema,
  name,
  errorMsg,
}: TextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <div className="relative">
        {label ? (
          <label className="label" htmlFor={id}>
            <span className="label-text">{label}</span>
          </label>
        ) : null}
        <input
          id={id}
          className="input-bordered input w-full"
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          {...schema.register(name)}
        />
        {/* gets whether to show password or not */}
        <label className="absolute right-4 top-1/2 grid -translate-y-1/2 cursor-pointer place-content-center">
          <input
            type="checkbox"
            className="inset-0 hidden"
            onChange={() => {
              setShowPassword(!showPassword);
            }}
          />
          {showPassword ? (
            <FaEyeSlash className="h-4 w-4" />
          ) : (
            <FaEye className="h-4 w-4" />
          )}
        </label>
      </div>
      <span className="text-xs text-error">{errorMsg}</span>
    </div>
  );
}

type SubmitProps = {
  text: string;
  disabled: boolean;
};

export function ZodSubmitButton({
  text = 'Submit',
  disabled = false,
}: SubmitProps) {
  return (
    <button className="btn-primary btn" type="submit" disabled={disabled}>
      {text}
    </button>
  );
}
