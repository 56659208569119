import { combineReducers } from 'redux';
import { DRYTXNDATA } from '../../utils/types';
import accountDataReducer, { IAccountDataState } from './accountDataReducer';
import authReducer, { IAuthStateData } from './authReducer';
import boltReducer, { BoltState } from './boltReducer';
import modalReducer, { IModalState } from './modalReducer';
import qrReducer, { IQrState } from './qrReducer';
import sentTxnReducer from './sentTxnReducer';
import txnReducer, { TxnDryState } from './txnReducer';
import usersReducer, { UsersState } from './usersReducer';

export interface IAppState {
  transactions: TxnDryState;
  sentTransaction: DRYTXNDATA | null;
  authState: IAuthStateData;
  accountDataState: IAccountDataState;
  modalState: IModalState;
  userBolts: BoltState;
  users: UsersState;
  qr: IQrState;
}

export const rootReducer = combineReducers<IAppState>({
  transactions: txnReducer,
  sentTransaction: sentTxnReducer,
  authState: authReducer,
  accountDataState: accountDataReducer,
  modalState: modalReducer,
  userBolts: boltReducer,
  users: usersReducer,
  qr: qrReducer,
});
