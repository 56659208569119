import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { MutationConfig } from '../../lib/react-query';

export const EmailFreq = {
  ALWAYS: 'ALWAYS',
  PERDAY: 'PERDAY',
  PERWEEK: 'PERWEEK',
  NEVER: 'NEVER',
} as const;
export type EmailFreq = (typeof EmailFreq)[keyof typeof EmailFreq];

const FreqServer = {
  ALWAYS: 'always',
  PERDAY: 'perday',
  PERWEEK: 'perweek',
  NEVER: 'never',
};

export const FreqNums: Record<number, EmailFreq> = {
  0: EmailFreq.ALWAYS,
  1: EmailFreq.PERDAY,
  2: EmailFreq.PERWEEK,
  3: EmailFreq.NEVER,
};

export const updateUserPreferences = async ({
  emailFreq,
}: {
  emailFreq: keyof typeof EmailFreq;
}) => axios.post(`/user/prefs`, { emailfreq: FreqServer[emailFreq] });

type updatePreferences = {
  config?: MutationConfig<typeof updateUserPreferences>;
};

export const useUpdatePreferences = ({ config }: updatePreferences = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateUserPreferences,
  });
};
