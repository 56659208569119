import { CircularProgress } from '@/components/Elements/circularprogress';
import { useUser } from '@/lib/auth';
import { Combobox } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FaAngleDown, FaArrowRight } from 'react-icons/fa';
import { z } from 'zod';
import { useIssuer } from '../features/issuers/getIssuer';
import { useInitTransaction } from '../features/transaction/initTransaction';
import { useMyBolts } from '../features/user/getMyBolts';
import useTransactionStore from '../stores/TransactionStore';
import { useZodForm } from '../utils/form';
import { asCurrencyString, rounded2pennies } from '../utils/money';
import { IBOLTDATA } from '../utils/types';
import { CustomDialog } from './common/Dialog';

type BoltComboBoxProps = {
  query: string; // Query to filter bolts by
  storeId: string; // Id of the store requesting zuz
  extraZUZ: Array<string>; // any zuz, in addition to reciever (storeId), that are also definitely accepted by reciever
};
function BoltComboBoxOptions({ query, storeId, extraZUZ }: BoltComboBoxProps) {
  const { data, status } = useMyBolts();
  // determines whether or not the client may select a bolt that is not owned by the store or extraZUZ
  const [allowOther, setAllowOther] = useState(false);

  if (status === 'loading') return <CircularProgress />;
  if (status === 'error') return <div>Error</div>;

  const [bolts, setBolts] = useState<(typeof data)['bolts']>([]);

  // filter bolts by query and storeId
  // if allowOther is true, also include bolts that are not owned by the store or extraZUZ
  // otherwise, only include bolts that are owned by the store or extraZUZ
  useEffect(() => {
    const queriedBolts = data.bolts.filter((bolt) =>
      bolt.bolt.specName.toLowerCase().includes(query.toLowerCase())
    );

    const filteredBolts = allowOther
      ? queriedBolts
      : queriedBolts.filter(
          (bolt) =>
            bolt.bolt.issuer.toString() === storeId ||
            extraZUZ.includes(bolt.bolt.issuer.toString())
        );

    if (filteredBolts.length === 0) {
      setAllowOther(true);
    }

    setBolts(filteredBolts);
  }, [query, storeId, extraZUZ, allowOther]);

  if (bolts.length === 0) return <div>No ZUZ found</div>;

  return (
    <div>
      {bolts.map(({ bolt, amount }) => (
        <Combobox.Option
          className={({ active }) =>
            clsx(
              `relative cursor-pointer select-none rounded-md py-2 pl-10 pr-4`,
              active ? 'bg-teal-600 text-white' : 'text-gray-900'
            )
          }
          key={bolt.specID}
          value={bolt}
        >
          <div className="flex items-center justify-between">
            <span>{bolt.specName}</span>
            <span className="text-sm">{asCurrencyString(amount)}</span>
          </div>
        </Combobox.Option>
      ))}
      {!allowOther && (
        <div
          className="relative cursor-pointer select-none rounded-md py-2 pl-10 pr-4 text-gray-900 hover:bg-teal-600 hover:text-white"
          onClick={() => {
            setAllowOther(true);
          }}
        >
          Other...
        </div>
      )}
    </div>
  );
}

//Passed from QRScanner
type SpendZuzProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void; //Function for closing spend zuz dialog
  storeName: string; //Name of store requesting zuz
  storeId: string; //Id of the store requesting zuz
  amt?: number; //Amount that the user needs to transfer
  pos?: string; // pos being used
  extraZuz: string[]; // any zuz, in addition to reciever (storeId), that are also definitely accepted by reciever
  qrCreator: string; // the identity of the app that created the QR (if from our app)
  posTid: string; // extra data for POS transaction
  allowTips: boolean; // whether to allow merchant specified tips to be shown
};

// Dialogue for spending zuz
function SpendZuz({
  isOpen,
  setIsOpen,
  storeName,
  storeId,
  amt,
  pos,
  extraZuz,
  qrCreator,
  posTid,
  allowTips,
}: SpendZuzProps) {
  const currentUser = useUser();
  const issuer = useIssuer({
    issuerId: storeId,
    config: { enabled: !!storeId },
  });
  const [query, setQuery] = useState('');
  const myBolts = useMyBolts();
  const methods = useZodForm({
    schema: z
      .object({
        amount: z.coerce.number().gt(0),
        tip: z.coerce.number().min(0).max(100),
        zuz: z.object({ specID: z.string().nonempty() }),
      })
      .superRefine(({ amount, tip, zuz }, ctx) => {
        const thisBolt = myBolts.data?.bolts.find(
          (bolt) => bolt.bolt.specID == zuz.specID
        );
        const walletAmount = thisBolt?.amount ?? 0;
        const boltName = thisBolt?.bolt.specName;
        if (amount + tip > walletAmount) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `You only have ${asCurrencyString(
              walletAmount
            )} of ${boltName}.`,
            path: ['amount'],
          });
        }
      }),
    defaultValues: {
      amount: amt ?? 0,
      tip: 0,
      zuz: { specID: '' },
    },
  });

  // used to convert get percent of amt rounded to pennies
  const getPercent2Pennies = (amt: number, percent: number) => {
    /*console.log(`gp2p(${amt},${percent})`);*/ return rounded2pennies(
      (amt * percent) / 100
    );
  };

  useEffect(() => {
    if (amt) methods.setValue('amount', amt);
  }, [amt]);

  useEffect(() => {
    if (!myBolts.isSuccess) return;
    if (myBolts.data.bolts.length === 1) {
      methods.setValue('zuz', myBolts.data.bolts[0].bolt);
    }
  }, [myBolts.status]);

  const queryClient = useQueryClient();

  const { setSentTransaction } = useTransactionStore();
  const initTransaction = useInitTransaction({
    config: {
      onSuccess: (res) => {
        methods.reset();
        setSentTransaction(res.data.transaction);
        queryClient.refetchQueries(['my-bolts']);
      },
    },
  });

  if (!currentUser.isSuccess || !issuer.isSuccess || !amt) return null;

  return (
    <CustomDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="flex items-center gap-2 text-xl font-bold leading-10">
        <span>{currentUser.data?.user.username}</span>
        <FaArrowRight />
        <span>{storeName}</span>
      </div>
      <div className="text-sm">Confirm payment and choose a ZUZ.</div>
      <form
        className="mt-2 flex flex-col gap-2"
        onSubmit={methods.handleSubmit(({ amount: amt, tip, zuz }) => {
          let qrcode = qrCreator;
          let realpos = pos;
          if (pos == 'none') {
            // first check for pos in code
            const match = qrCreator.match(/^(.*)-2$/);
            if (match) {
              // this is clover
              qrcode = match[1];
              realpos = 'clover';
            }
          }

          // calculate amount of tip and add to base amt, that is what
          // is sent to backend for amount of sale.  The portion of
          // the total that is the tip is sent as tipAmt.
          const tipAmt: number = getPercent2Pennies(amt, tip);
          const totalAmt: number = amt + tipAmt;
          setIsOpen(false);
          initTransaction.mutate({
            boltID: zuz.specID,
            userID: storeId,
            amount: totalAmt,
            merchantID: undefined,
            qrCreator: qrcode,
            tip: tipAmt,
            usingPOS: realpos,
            posTid,
          });
        }, console.error)}
      >
        <div>
          <label className="label">
            <span className="label-text">Base Amount</span>
          </label>
          <input
            className="input-bordered input-primary input w-full"
            type="number"
            step={0.01}
            disabled={amt !== undefined}
            {...methods.register('amount')}
            value={methods.watch('amount').toFixed(2)}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.amount?.message}
          </span>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Select ZUZ</span>
          </label>
          <Controller
            name="zuz"
            control={methods.control}
            render={({ field }) => (
              <Combobox as="div" className="relative z-10" {...field}>
                <Combobox.Input
                  className="input-bordered input-primary input w-full"
                  onChange={(e) => setQuery(e.target.value)}
                  displayValue={(bolt: IBOLTDATA) => bolt.specName}
                  placeholder="Search for ZUZ"
                />
                <Combobox.Button className="btn-ghost btn-square btn absolute inset-y-0 right-0 flex items-center">
                  <FaAngleDown className="h-5 w-5" aria-hidden />
                </Combobox.Button>
                <Combobox.Options className="absolute max-h-28 w-full overflow-auto rounded-md bg-white p-2 shadow-2xl">
                  <BoltComboBoxOptions
                    query={query}
                    storeId={storeId}
                    extraZUZ={extraZuz}
                  />
                </Combobox.Options>
              </Combobox>
            )}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.zuz?.message}
          </span>
        </div>
        {allowTips && issuer.data.result.tips?.percents?.length && (
          <div>
            <label className="label">
              <span className="label-text">Add a Tip</span>
            </label>
            <div className="flex gap-2">
              {issuer.data.result.tips?.percents?.map((percent) => (
                <div key={percent} className="relative flex-1 items-center">
                  <input
                    className="peer absolute inset-0 cursor-pointer opacity-0"
                    type="radio"
                    {...methods.register('tip')}
                    value={percent}
                  />
                  <div className="rounded-md p-2 outline outline-1 peer-checked:bg-base-200">
                    <span className="text-xl text-secondary">{percent}%</span>
                    <br />
                    <span className="text-sm text-gray-500">
                      (
                      {asCurrencyString(
                        getPercent2Pennies(methods.getValues('amount'), percent)
                      )}
                      )
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <span className="text-xs text-error">
              {methods.formState.errors.tip?.message}
            </span>
            {methods.formState.dirtyFields.tip && (
              <button
                onClick={() => methods.resetField('tip')}
                className="btn-ghost btn-sm btn relative mt-2 w-full flex-1 outline outline-1"
              >
                Remove Tip
              </button>
            )}
          </div>
        )}
        <div className="mt-4">
          <div className="btn-disabled btn w-full justify-between">
            <span className="text-base-content">Total:</span>{' '}
            <span className="text-primary">
              {asCurrencyString(amt)}
              {methods.watch('tip') !== 0 &&
                ` + ${asCurrencyString(
                  getPercent2Pennies(amt, methods.watch('tip'))
                )} tip`}
            </span>
          </div>
        </div>
        <div>
          <button className="btn-secondary btn w-full">
            Complete Transfer
          </button>
        </div>
      </form>
    </CustomDialog>
  );
}

export default SpendZuz;
