import { env } from '@/env/client.mjs';
import storage from '@/utils/storage';
import Axios, {
  AxiosError as NativeAxiosError,
  AxiosRequestConfig,
} from 'axios';
import { toast } from 'react-hot-toast';

// used for API calls
export const axios = Axios.create({
  baseURL: env.VITE_BACKEND_URL,
});

// used for stories, no auth needed and not to API
export const noApiAxiosPlain = Axios.create({
  baseURL: '',
});

function authRequestInterceptor(config: AxiosRequestConfig) {
  const storageToken = storage.getToken();

  // if we have a token, add it to the request
  if (storageToken) {
    const account = storageToken.accounts.find((account) => {
      return account.id === storageToken.activeAccount;
    });

    if (account) {
      config.headers.Authorization = `Bearer ${account.token}`;
    }
  }

  config.headers.Accept = 'application/json';
  return config;
}

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    // return response.data;
    return response;
  },
  (error: AxiosError) => {
    console.log('Axios error: ', error);
    const message = error?.response?.data?.msg ?? 'An error occurred.';

    // unauthorized
    if (error.response?.status === 401) {
      // something is wrong about auth, clear state so we can start over
      storage.clearToken();
    } else {
      toast.error(message);
    }

    return Promise.reject(error);
  }
);

export type AxiosError = NativeAxiosError;
