import { CircularProgress } from '@/components/Elements/circularprogress';
import { Listbox } from '@headlessui/react';
import clsx from 'clsx';
import { useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FaChevronDown, FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { getBolts } from '../../features/bolt/getBolts';
import { useDelegations } from '../../features/user/getDelegations';
import { useMyBolts } from '../../features/user/getMyBolts';
import { useUser } from '../../features/user/getUser';
import { ExtractFnReturnType } from '../../lib/react-query';
import { delegatePermissions } from '../../redux/actions/usersAction';
import { SellPermissions } from '../../redux/reducers/authReducer';
import { useZodForm } from '../../utils/form';
import { CustomDialog } from '../common/Dialog';

function BoltListBoxOptions() {
  const { data, status } = useMyBolts();

  if (status === 'loading') return <CircularProgress />;
  if (status === 'error') return <div>Error</div>;

  return (
    <>
      {data.bolts.map((bolt) => (
        <Listbox.Option
          className={({ active, selected }) =>
            clsx(
              `relative select-none rounded-md py-2 pl-10 pr-4`,
              active ? 'bg-teal-600 text-white' : 'text-gray-900',
              selected ? 'bg-teal-600 text-white' : 'text-gray-900'
            )
          }
          key={bolt._id}
          value={bolt.bolt}
        >
          {bolt.bolt.specName}
        </Listbox.Option>
      ))}
    </>
  );
}

type MintPermissionDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  parentMethods: UseFormReturn<
    {
      accept: boolean;
      spend: boolean;
      spendBolts: {
        specID: string;
        specName: string;
      }[];
      spendLimit: number;
      permissions: {
        specName: string;
        limit: number;
        specId: string;
      }[];
    },
    any
  >;
};
function MintPermissionDialog({
  isOpen,
  setIsOpen,
  parentMethods,
}: MintPermissionDialogProps) {
  const methods = useZodForm({
    schema: z
      .object({
        bolt: z.object({
          specID: z.string(),
          specName: z.string(),
        }),
        limit: z.coerce.number().min(0),
      })
      .superRefine(({ bolt }, ctx) => {
        if (
          parentMethods
            .getValues('permissions')
            .some((perm) => perm.specId === bolt.specID)
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Permission already exists for this bolt',
            path: ['bolt'],
          });
        }
      }),
  });

  return (
    <CustomDialog isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="text-lg font-bold">Add a Mint Permission</div>
      <form
        onSubmit={methods.handleSubmit(({ bolt, limit }) => {
          parentMethods.setValue('permissions', [
            ...parentMethods.getValues('permissions'),
            {
              specId: bolt.specID,
              specName: bolt.specName,
              limit,
            },
          ]);
          setIsOpen(false);
        }, console.error)}
      >
        <div className="w-full">
          <label className="label">
            <span className="label-text">Bolt Spec</span>
          </label>
          <Controller
            name="bolt"
            control={methods.control}
            render={({ field }) => (
              <Listbox as="div" className="relative" {...field}>
                <Listbox.Button className="input-bordered input-primary input w-full">
                  {field.value?.specName}
                </Listbox.Button>
                <Listbox.Options className="absolute max-h-40 w-full overflow-auto rounded-md bg-white p-2 shadow-2xl">
                  <BoltListBoxOptions />
                </Listbox.Options>
              </Listbox>
            )}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.bolt?.message}
          </span>
        </div>
        <div>
          <label className="label">
            <span className="label-text">Limit</span>
          </label>
          <input
            type="number"
            step={0.01}
            className="input-bordered input-primary input w-full"
            {...methods.register('limit')}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.limit?.message}
          </span>
        </div>
        <button className="btn-primary btn-sm btn float-right mt-4">Add</button>
      </form>
    </CustomDialog>
  );
}

type EditDelegateFormProps = {
  userId: string;
  permission: NonNullable<
    ExtractFnReturnType<typeof useDelegations>['data']
  >['permissions'][number];
};
function EditDelegateForm({ userId, permission }: EditDelegateFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mintPermissionOpen, setMintPermissionOpen] = useState(false);

  const user = useUser({ userId });

  const methods = useZodForm({
    schema: z
      .object({
        accept: z.boolean(),
        spend: z.boolean(),
        spendBolts: z.array(
          z.object({
            specID: z.string(),
            specName: z.string(),
          })
        ),
        spendLimit: z.coerce.number().min(0),
        permissions: z.array(
          z.object({
            specId: z.string(),
            specName: z.string(),
            limit: z.coerce.number().min(0),
          })
        ),
      })
      .superRefine(({ spend, spendLimit, spendBolts }, ctx) => {
        if (spend && !spendLimit) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Must have a spend limit',
            path: ['spendLimit'],
          });
        }

        if (spend && !spendBolts.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Must have at least one spend bolt',
            path: ['spendBolts'],
          });
        }
      }),
    defaultValues: async () => {
      const accept = permission.some((p) => p.type === 'accept');
      const spend = permission.some((p) => p.type === 'sell');

      const specIds =
        (
          permission.filter((p) => p.type === 'sell') as unknown as {
            type: 'sell';
            sell: SellPermissions;
          }[]
        )?.[0]?.sell.specIDs ?? [];

      const bolts = await getBolts({ specIds });

      const spendBolts = bolts.map(({ specID, specName }) => ({
        specID,
        specName,
      }));
      const spendLimit =
        (
          permission.find((p) => p.type === 'sell') as unknown as {
            type: 'sell';
            sell: SellPermissions;
          }
        )?.sell.maxLimit ?? 0;

      const permissions = [];

      return {
        accept,
        spend,
        spendBolts,
        spendLimit,
        permissions,
      };
    },
  });

  if (methods.formState.isLoading || user.isLoading) return <>Loading...</>;
  if (user.isError) return <>Error loading user</>;

  return (
    <>
      <MintPermissionDialog
        isOpen={mintPermissionOpen}
        setIsOpen={setMintPermissionOpen}
        parentMethods={methods}
      />
      <div className="text-lg font-bold">
        Editing Delegation for{' '}
        <span className="capitalize">{user.data.user.username}</span>
      </div>
      <form
        className="flex w-full max-w-xl flex-col gap-4"
        onSubmit={methods.handleSubmit((data) => {
          dispatch(
            delegatePermissions(
              userId,
              data.permissions.reduce(
                (acc, { specId, limit }) => ({
                  ...acc,
                  [specId]: {
                    specID: specId,
                    limit,
                  },
                }),
                {}
              ),
              {
                maxLimit: data.spendLimit,
                specIDs: data.spendBolts.map((bolt) => bolt.specID),
              },
              data.accept
            )
          );
          navigate('/settings/delegations');
        }, console.error)}
      >
        <div>
          <label className="label">
            <span>Accept</span>
            <input
              type="checkbox"
              className="checkbox-primary checkbox outline outline-1"
              {...methods.register('accept')}
            />
          </label>
        </div>

        <div>Mint Permissions</div>
        <div className="flex flex-wrap items-center gap-2">
          {methods.watch('permissions').map((permission) => (
            <button
              key={permission.specId}
              className="btn-primary btn-xs btn"
              onClick={() =>
                methods.setValue(
                  'permissions',
                  methods
                    .watch('permissions')
                    .filter((p) => p.specId !== permission.specId)
                )
              }
            >
              <div className="flex justify-between">
                <span>{permission.specName}</span> -{' '}
                <span>${permission.limit}</span>
              </div>
            </button>
          ))}
          <button
            type="button"
            className="btn-primary btn-xs btn-circle btn"
            onClick={() => setMintPermissionOpen(true)}
          >
            <FaPlus />
          </button>
          <span className="text-xs text-error">
            {methods.formState.errors.permissions?.message}
          </span>
        </div>

        <div>
          <label className="label">
            <span>Spend</span>
            <input
              type="checkbox"
              className="checkbox-primary checkbox outline outline-1"
              {...methods.register('spend')}
            />
          </label>
        </div>

        {methods.watch('spend') && (
          <>
            <div className="w-full">
              <label className="label">
                <span className="label-text">Bolts</span>
              </label>
              <Controller
                name="spendBolts"
                control={methods.control}
                render={({ field }) => (
                  <Listbox
                    as="div"
                    disabled={!methods.watch('spend')}
                    className="relative"
                    multiple
                    {...field}
                  >
                    <Listbox.Button className="input-bordered input w-full">
                      <div className="flex w-full items-center justify-between">
                        <span>
                          {methods.watch('spendBolts')?.length ?? 'None'}{' '}
                          selected
                        </span>
                        <FaChevronDown />
                      </div>
                    </Listbox.Button>
                    <Listbox.Options className="absolute max-h-40 w-full overflow-auto rounded-md bg-white p-2 shadow-2xl">
                      <BoltListBoxOptions />
                    </Listbox.Options>
                  </Listbox>
                )}
              />
              <div className="pt-1 text-sm">
                {methods.watch('spendBolts')?.length > 0 && (
                  <>
                    Selected bolts:{' '}
                    {methods
                      .watch('spendBolts')
                      .map((bolt) => bolt.specName)
                      .join(', ')}
                  </>
                )}
              </div>
              <span className="text-xs text-error">
                {methods.formState.errors.spendBolts?.message}
              </span>
            </div>
            <div>
              <label className="label">
                <span className="label-text">Limit</span>
              </label>
              <input
                type="number"
                className="input-bordered input-primary input w-full"
                disabled={!methods.watch('spend')}
                {...methods.register('spendLimit')}
              />
              <span className="text-xs text-error">
                {methods.formState.errors.spendLimit?.message}
              </span>
            </div>
          </>
        )}

        <div className="flex justify-end gap-2">
          <Link
            to="/settings/delegations"
            className="btn-primary btn-ghost btn"
          >
            Cancel
          </Link>
          <button
            className="btn-primary btn"
            disabled={!methods.formState.isDirty}
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
}

export default function EditDelegate() {
  const { userId } = useParams();
  const delegations = useDelegations();

  if (delegations.isLoading) return <>Loading...</>;
  if (delegations.isError) return <>An error occurred.</>;
  if (!userId) return <>User ID not found.</>;

  const permission = Object.entries(delegations.data.permissions).find(
    ([id]) => id === userId
  )?.[1];

  if (!permission) return <>User delegation not found.</>;

  return <EditDelegateForm userId={userId} permission={permission} />;
}
