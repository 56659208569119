import { useUser } from '@/lib/auth';
import { Card, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

// The git information, __BRANCH__ and __COMMIT__, is defined in vite.config.js

function About() {
  const user = useUser();

  return (
    <>
      <Container maxWidth="md">
        <Card style={{ margin: 10, borderRadius: 20, padding: 20 }}>
          <Grid container spacing={4} alignItems="flex-start">
            <Grid item>
              <Typography variant="h3">About</Typography>
              <Typography variant="caption">
                Branch: {__BRANCH__} | Commit: {__COMMIT__}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                Zuz is a platform for digital assets. We support local
                currencies like Sharpsburg Bucks. Local currencies promote the
                community's economic development, and encourage the use of
                locally produced goods and services. Join us as we support local
                businesses, and promote sustainability!
              </Typography>
              <Typography
                href="https://zuzlab.com/"
                component="a"
                variant="caption"
              >
                Home
              </Typography>
              <br />
              {!!user.data && !!user.data.user ? (
                <Typography to="/feedback" component={Link} variant="caption">
                  Feedback
                </Typography>
              ) : (
                <Typography variant="caption">
                  <a
                    href="mailto:info@zuzlab.com?subject=My Feedback about getzuz.com"
                    target="_blank"
                  >
                    Feedback
                  </a>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      </Container>
    </>
  );
}

export default About;
