import { Grid } from '@mui/material';
import QRCodeCreator from './QRCodeCreater';

function MerchantPos() {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <QRCodeCreator />
    </Grid>
  );
}

export default MerchantPos;
