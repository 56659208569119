import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import AlreadyLoggedIn from '@/components/auth/AlreadyLoggedIn';
import Login from '@/components/auth/Login';
import Feedback from '@/components/feedback/Feedback';
import MerchantPos from '@/components/merchantPos/MerchantPos';
import Profile from '@/components/profile/Profile';
import QR from '@/components/QR';
import Settings from '@/components/settings/Settings';
import Wallet from '@/components/wallet/Wallet';
import { useUser } from '@/lib/auth';

const App = () => {
  const user = useUser();

  if (user.isLoading)
    return (
      <div className="grid h-full w-full place-items-center">
        <span className="loading loading-spinner" />
      </div>
    );

  if (!user.data) return <Login />;

  return <Outlet />;
};

export const protectedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/wallet', element: <Wallet /> },
      { path: '/profile', element: <Profile /> },
      { path: '/settings/*', element: <Settings /> },
      { path: '/pos', element: <MerchantPos /> },
      { path: '/qr/:amount/:issuerId/:code', element: <QR /> },
      { path: '/feedback', element: <Feedback /> },
      { path: '/login', element: <Navigate to="/wallet" /> },
      { path: '/register/*', element: <AlreadyLoggedIn /> },
    ],
  },
];
