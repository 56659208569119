import { AccountDataActionsTypes } from '../actions/actionTypes';
import { BoltState } from './boltReducer';
import { TxnDryState } from './txnReducer';

export interface AccountData {
  transactions: TxnDryState;
  userBolts: BoltState;
}

export interface IAccountDataState {
  // Account ID
  [key: string]: {
    // Role ID
    [key: string]: AccountData;
  };
}

interface AccountDataStoreAction {
  type: AccountDataActionsTypes.STORE;
  payload: {
    accountID: string;
    delegatedID: string;
    accountData: AccountData;
  };
}

interface AccountDataClearAction {
  type: AccountDataActionsTypes.CLEAR;
  payload: {
    accountID: string;
  };
}

interface AccountDataClearAllAction {
  type: AccountDataActionsTypes.CLEAR_ALL;
}

// signals other slices of state to clear the currently active account
export interface AccountDataClearActiveAction {
  type: AccountDataActionsTypes.CLEAR_ACTIVE;
}

type AccountDataAction =
  | AccountDataStoreAction
  | AccountDataClearAction
  | AccountDataClearAllAction;

const initialState: IAccountDataState = {};

// save current account's info into Redux
// user info is global; doesn't need to be switched out
const accountDataReducer = (
  state: IAccountDataState = initialState,
  action: AccountDataAction
) => {
  switch (action.type) {
    // Reducer to (non-persistently) save account data
    case AccountDataActionsTypes.STORE:
      const allAccountData = state[action.payload.accountID];
      return {
        ...state,
        [action.payload.accountID]: {
          ...allAccountData,
          [action.payload.delegatedID]: action.payload.accountData,
        },
      };
    // Reducer to clear account data when logging out of a specific account
    case AccountDataActionsTypes.CLEAR:
      const { [action.payload.accountID]: clearedAccount, ...rest } = state; // eslint-disable-line @typescript-eslint/no-unused-vars
      return rest;

    // Reducer to clear all account data when logging out of all accounts
    case AccountDataActionsTypes.CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
};

export default accountDataReducer;
