import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.css';

// shim for browsers before 3/22 (remove this in 6 months or so?)
if (!Object.hasOwn) {
  Object.hasOwn = Object.hasOwnProperty;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
