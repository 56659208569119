import { CircularProgress } from '@/components/Elements/circularprogress';
import { useUser } from '@/features/user/getUser';
import {
  switchAccounts,
  useLogout,
  useUser as useActiveUser,
} from '@/lib/auth';
import { queryClient } from '@/lib/react-query';
import storage from '@/utils/storage';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FaStore } from 'react-icons/fa';
import AddAccountDialog from '../profile/AddAccountDialog';

function Account({ id }: { id: string }) {
  const activeUser = useActiveUser();
  const user = useUser({ userId: id });
  const logout = useLogout(id);

  if (!user.data) {
    return <CircularProgress />;
  }

  const merchant = !!user.data.user.businessName;

  return (
    <div
      className={clsx(
        'group/item flex items-center gap-2 rounded-md px-4 py-3 hover:bg-slate-200',
        id === activeUser.data?.user.id.toString() && 'bg-slate-100'
      )}
    >
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-secondary text-white">
        <FaStore />
      </div>
      <div className="flex-1">
        <div className="font-bold">
          @ {merchant ? user.data.user.businessName : user.data.user.username}
        </div>
        <div className="text-sm">({merchant ? <>Merchant</> : <>User</>})</div>
      </div>
      <button
        className="btn-secondary btn-ghost btn-sm btn invisible group-hover/item:visible"
        onClick={async () => {
          switchAccounts(id);
          queryClient.invalidateQueries();
        }}
      >
        Switch
      </button>
      <button
        className="btn-secondary btn-ghost btn-sm btn invisible group-hover/item:visible"
        onClick={async () => logout.mutateAsync({})}
      >
        Logout
      </button>
    </div>
  );
}

function Accounts() {
  const [token, setToken] = useState(storage.getToken());
  const user = useActiveUser();

  useEffect(() => {
    setToken(storage.getToken());
  }, [user.data]);

  if (token?.accounts.length === 0) {
    return <span>No Linked Profiles</span>;
  }

  return (
    <div id="s.ma.all">
      {token?.accounts.map((account) => (
        <Account key={account.id} id={account.id} />
      ))}
    </div>
  );
}

export default function ManageAccounts() {
  //State for modal to add users
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddAccountDialog isOpen={open} onClose={() => setOpen(false)} />

      <Accounts />
      <div className="mt-4 flex justify-between">
        <button
          id="s.ma.add"
          className="btn-secondary btn-sm btn"
          onClick={() => setOpen(true)}
        >
          Add Account
        </button>
        <button
          className="btn-secondary btn-sm btn"
          onClick={async () => {
            storage.clearToken();
            queryClient.invalidateQueries();
          }}
        >
          Logout of All Accounts
        </button>
      </div>
    </>
  );
}
