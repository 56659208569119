import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../lib/react-query';
import { IBOLTDATA } from '../../utils/types';
import { getBolt } from './getBolt';

export const getBolts = async ({ specIds }: { specIds: string[] }) => {
  const bolts: IBOLTDATA[] = [];

  for await (const specId of specIds) {
    const { bolt } = await getBolt({ specId });
    bolts.push(bolt);
  }

  return bolts;
};

type UseBoltsOptions = {
  specIds: string[];
  config?: QueryConfig<typeof getBolts>;
};

export const useBolts = ({ config, specIds }: UseBoltsOptions) => {
  return useQuery({
    ...config,
    queryKey: ['bolts', specIds],
    queryFn: () => getBolts({ specIds }),
  });
};
