/* Component to reset passwords - when users click on the reset link in their emails after initiating the forgot password process,
 * they will be redirected here. Users are required to type their new password twice. The username and token from the link are used to make
 * the reset password request.
 */
import { ZodPasswordField } from '@/components/Elements/ZodFormFields';
import { useResetPassword } from '@/features/user/resetPassword';
import { useZodForm } from '@/utils/form';
import { toast } from 'react-hot-toast';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

function ResetPassword() {
  //parse username and token from url
  const [searchParams] = useSearchParams();

  const token = searchParams.has('token')
    ? searchParams.get('token')
    : 'invalid token';

  const params = useParams<{ username: string }>();
  const username = params.username ?? 'invalid username';

  // On feedback from backend, show toast.
  const resetPassword = useResetPassword({
    config: {
      onSuccess: () => {
        toast.success('Password reset successfully');
      },
    },
  });

  // form to fill out when requesting password reset
  const methods = useZodForm({
    schema: z
      .object({
        password: z
          .string()
          .min(4, 'Password must be at least 4 characters long')
          .regex(
            /.*[A-Z].*/g,
            'Password must contain at least one capital letter'
          ),
        confirmPassword: z.string(),
      })
      // make sure both passwords match and if not, show on confirmPassword field
      .superRefine((data, ctx) => {
        if (data.password !== data.confirmPassword) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Passwords do not match',
            path: ['confirmPassword'],
          });
        }
      }),
  });

  if (!resetPassword.isSuccess) {
    return (
      <div className="mt-8 flex flex-col items-center gap-4">
        <h1 className="text-2xl tracking-tight">Create a New Password</h1>
        <form
          className="form-control w-full max-w-sm gap-2"
          onSubmit={methods.handleSubmit(({ password }) => {
            if (!token) return;
            resetPassword.mutate({ username, token, newPassword: password });
          })}
        >
          <ZodPasswordField
            placeholder="Password"
            id="password"
            errorMsg={methods.formState.errors.password?.message}
            schema={methods}
            name="password"
          />
          <ZodPasswordField
            placeholder="Confirm Password"
            id="passwordCopy"
            errorMsg={methods.formState.errors.confirmPassword?.message}
            schema={methods}
            name="confirmPassword"
          />
          <button
            className="btn-primary btn mt-4"
            type="submit"
            disabled={!methods.formState.isValid}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }

  return (
    <div className="mt-8 flex h-full flex-col items-center justify-center">
      <h3 className="text-center text-xl font-bold">
        Success!
        <br /> Your password has been reset.
      </h3>
      <br />
      <Link to="/login" className="btn">
        Return to login page
      </Link>
    </div>
  );
}

export default ResetPassword;
