import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import { IssuerData } from '../../utils/types';

type IssuersResult = {
  status: string;
  result: IssuerData[];
};

export const getIssuers = () => {
  return axios
    .get(`/v2/user/list/issuers`)
    .then((res) => res.data as IssuersResult);
};

type UseIssuerOptions = {
  config?: QueryConfig<typeof getIssuers>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useIssuers = ({ config }: UseIssuerOptions = {}) => {
  return useQuery({
    // ...config,
    queryKey: ['issuers'],
    queryFn: () => getIssuers(),
  });
};
