import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import { IUSERDATA } from '../../utils/types';

type UserResult = {
  status: string;
  users: IUSERDATA[];
};

export const getUserQuery = ({ query }: { query: string }) => {
  if (query[0] === '@') {
    query = query.slice(1);
  }

  return axios
    .get(`/user/search?query=${query}`)
    .then((res) => res.data as UserResult);
};

type UseSearchUserOptions = {
  query: string;
  config?: QueryConfig<typeof getUserQuery>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useUserQuery = ({ config, query }: UseSearchUserOptions) => {
  return useQuery({
    // ...config,
    queryKey: ['userSearch', query],
    queryFn: () => getUserQuery({ query }),
  });
};
