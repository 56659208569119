import { Combobox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { FaCheck, FaChevronDown } from 'react-icons/fa';
import { getUserQuery } from '../../features/user/getUserSearch';
import { IUSERDATA } from '../../utils/types';

// Comboboxes are the foundation of accessible autocompletes and command palettes
// for the app, complete with robust support for keyboard navigation.
// These are built with first-class support for integration with React Hook Form.

type AutoCompleteOptionType<T> = {
  values: T[]; // the list of values to display
  displayValue: (prop: T) => string; // maps a value to a display string
  keyValue: (prop: T) => string; // maps a value to a unique key string
};

// This is the list of options that will be displayed in the combobox.
function AutoCompleteOptions<T>({
  values,
  keyValue,
  displayValue,
}: AutoCompleteOptionType<T>) {
  // If there are no values, display a message saying so.
  if (values.length === 0)
    return (
      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
        Nothing found.
      </div>
    );

  return (
    <>
      {values.map((value) => (
        <Combobox.Option
          className="relative cursor-default select-none rounded-md py-2 pl-8 pr-4 hover:bg-primary hover:text-primary-content"
          key={keyValue(value)}
          value={value}
        >
          {/* We're given a render prop that tells us whether this option is selected.
           * We can use that to conditionally render a checkmark next to the option.
           */}
          {({ selected }) => (
            <>
              <span
                className={clsx(
                  'block truncate',
                  selected ? 'font-medium' : 'font-normal'
                )}
              >
                {displayValue(value)}
              </span>
              {selected && (
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <FaCheck className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </>
          )}
        </Combobox.Option>
      ))}
    </>
  );
}

type AutoCompleteProps<T, K extends FieldValues> = Pick<
  UseControllerProps<K>, // type parameter K is the type of the form values and will enforce that the name prop is a key of the form values
  'control' | 'name' // these are the props that the Controller wrapper component needs
> & {
  placeholder: string; // the placeholder text for the input
  displayValue: (prop: T) => string; // maps a value to a display string
  keyValue: (prop: T) => string; // maps a value to a unique key string
  getValues: (query: string) => Promise<T[]>; // the function to get the values
};
export default function AutoComplete<T, K extends FieldValues>({
  getValues,
  displayValue,
  keyValue,
  control,
  placeholder,
  name,
}: AutoCompleteProps<T, K>) {
  const [query, setQuery] = useState(''); // the query string
  const [values, setValues] = useState<T[]>([]); // the list of queried values

  // When the query changes, get the values from the callback and set them
  useEffect(() => {
    getValues(query).then((res) => setValues(res));
  }, [query]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Combobox as="div" className="relative" {...field}>
          <div>
            <Combobox.Input
              className="input-bordered input-primary input w-full"
              onChange={(e) => setQuery(e.target.value)}
              displayValue={displayValue}
              placeholder={placeholder}
            />
            <Combobox.Button className="btn-ghost btn-square btn absolute inset-y-0 right-0">
              <FaChevronDown
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Combobox.Options className="absolute max-h-40 w-full overflow-auto rounded-md bg-white p-2 shadow-2xl ring-1 ring-base-300">
              <AutoCompleteOptions<T>
                keyValue={keyValue}
                displayValue={displayValue}
                values={values}
              />
            </Combobox.Options>
          </Transition>
        </Combobox>
      )}
    />
  );
}

// This is a autocomplete implementation to select a user.
type UserAutoCompleteProps<T extends FieldValues> = Pick<
  UseControllerProps<T>,
  'control' | 'name'
>;
export function UserAutoComplete<T extends FieldValues>({
  control,
  name,
}: UserAutoCompleteProps<T>) {
  return (
    <AutoComplete
      getValues={(query: string) =>
        getUserQuery({ query }).then((res) => res.users)
      }
      displayValue={(user: IUSERDATA) => user.username}
      keyValue={(user: IUSERDATA) => user.id}
      control={control}
      placeholder="Start typing a username..."
      name={name}
    />
  );
}
