import { CircularProgress } from '@/components/Elements/circularprogress';
import { Fragment, useEffect } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import { useInfiniteTransactions } from '../../features/transaction/getInfiniteTransactions';
import { Transaction } from './Transaction';

function Profile() {
  const { ref, inView } = useInView();

  const { data, status, fetchNextPage, isFetchingNextPage } =
    useInfiniteTransactions();

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [inView]);

  if (status === 'error') return <div>Error</div>;
  if (status === 'loading') return <CircularProgress />;

  return (
    <div>
      <div className="mx-auto flex w-full max-w-2xl flex-col gap-4">
        <div>
          <span className="font-display text-3xl">My Transactions</span>
        </div>
        {/* <div>
          <div className="input-group-sm input-group rounded">
            <button className="btn-ghost btn-sm btn bg-secondary bg-opacity-20">
              <FaSearch />
            </button>
            <input
              type="text"
              placeholder="Search"
              className="input-primary input input-sm flex-1 bg-secondary bg-opacity-20"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div> */}
        <div className="flex flex-col gap-2">
          {data.pages.map((page, i) => (
            <Fragment key={i}>
              {page.transactions.map((transaction) => (
                <Transaction key={transaction._id} transaction={transaction} />
              ))}
            </Fragment>
          ))}
        </div>
        <div className="p-2" ref={ref}>
          {isFetchingNextPage && <FaCircleNotch className="animate-spin" />}
        </div>
      </div>
    </div>
  );
}

export default Profile;
