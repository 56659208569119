/* Overview of the authState data structure as used in Redux and local storage
 * We have temporarily added an additional key, profileData, to the definition of a LinkedAccount.
 * It is marked as optional in this file because it is appended when copying the data from
 * Redux into session and local storage. So, it is only present in Redux after loading the state
 * from local or session storage. This occurs on a page reload, or when returning to the page
 * after closing the tab when choosing "remember me".
 *
 * redux:
 * users: { 1: [USER OBJECT], ..., n: [USER OBJECT]}
 * authState: { loginID, actingID, linkedAccounts: { 1: { id, token, role, delegatedPermissions: {1 : {accept, mint, sell} , ... } } } }
 *
 * session storage:
 * authState: { loginID, actingID, linkedAccounts: { 1: {id, token, role, PROFILEDATA: [USER OBJECT], delegatedPermissions: {1 : {accept, mint, sell, PROFILEDATA: [USER OBJECT]} , ... } } } }
 *
 * local storage: same session storage, but is missing some linked accounts
 *
 * TODO: Update structure of Redux store so that we can make exact copies of it in storage
 *
 */
import { WS } from '../../context/WebSocketContext';
import { IUSERDATA, SPECUUID, USERUUID } from '../../utils/types';
import { AuthActionsTypes } from '../actions/actionTypes';
import { BoltState } from './boltReducer';
import { TxnDryState } from './txnReducer';

// Both the loginUser and actingUser are "in effect" with respect to the APIs
// This type ensures that either both are defined (logged in) or both
// undefined (logged out)
export type EffectiveUser = {
  loginID: USERUUID;
  actingID: USERUUID;
};

// The permissions/account types might belong in an axios response types file
export interface MintPermission {
  specID: SPECUUID;
  maxLimit: number;
}
export interface MintPermissions {
  [key: string]: MintPermission;
}
export interface SellPermissions {
  specIDs: Array<SPECUUID>;
  maxLimit: number;
}
export interface AccessPermission {
  accept: boolean;
  // "all" permissions when acting as self; used in selectors but not stored
  mint: MintPermissions | 'all'; // maps bolt spec ID to limit
  sell: SellPermissions | 'all';
  profileData?: IUSERDATA;
}
export type AccessPermissions = { [key: string]: AccessPermission };

export interface LinkedAccount {
  accountID: USERUUID;
  token?: string;
  userRole: string; //specifies if user is a customer or merchant
  // keys are delegation grantor USERUUIDs
  delegatedPermissions: AccessPermissions;
  profileData?: IUSERDATA; //optional because it only exists after loading authState data from storage
  //(on reload, or when returning to the page after closing the tab)
}

// the type of the redux authState slice
export interface IAuthStateData {
  effectiveUser?: EffectiveUser;
  // keys are USERUUIDs corresponding to accounts logged into on this device/browser
  accounts: { [key: string]: LinkedAccount };
}

// auth action types
export interface ILoadAuthAction {
  type: AuthActionsTypes.LOAD;
  payload?: IAuthStateData;
}

export interface ISwitchAccountAction {
  type: AuthActionsTypes.SWITCH;
  payload: {
    loginID: USERUUID;
    actingID: USERUUID;
    ws?: WS;
    transactions?: TxnDryState;
    userBolts?: BoltState;
  };
}

export interface ILoginAction {
  type: AuthActionsTypes.LOGIN;
  payload: {
    loginID: USERUUID;
    token: string;
    userRole: string;
    permissions: AccessPermissions;
  };
}
interface ILogoutAllAction {
  type: AuthActionsTypes.LOGOUT_ALL;
}

export interface ILogoutAction {
  type: AuthActionsTypes.LOGOUT;
  payload: {
    accountID: string;
  };
}
type IAuthAction =
  | ILoadAuthAction
  | ISwitchAccountAction
  | ILoginAction
  | ILogoutAction
  | ILogoutAllAction;

const initialState: IAuthStateData = {
  accounts: {},
};

const authReducer = (
  state: IAuthStateData = initialState,
  action: IAuthAction
): IAuthStateData => {
  switch (action.type) {
    // Reducer to load entire authState slice
    case AuthActionsTypes.LOAD:
      if (action.payload) {
        return action.payload;
      }
      // nothing to load; no effect
      return state;

    // Reducer for switching to an account
    case AuthActionsTypes.SWITCH:
      return {
        ...state,
        effectiveUser: {
          loginID: action.payload.loginID,
          actingID: action.payload.actingID,
        },
      };

    // Reducer for logging in. Does NOT switch to the new account
    case AuthActionsTypes.LOGIN:
      const loginID = action.payload.loginID;
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [loginID]: {
            accountID: loginID,
            token: action.payload.token,
            userRole: action.payload.userRole,
            // handle roles here
            delegatedPermissions: action.payload.permissions,
          },
        },
      };

    // Logs out of specified account
    case AuthActionsTypes.LOGOUT:
      const {
        accounts: {
          [action.payload.accountID]: logoutAccount, // eslint-disable-line @typescript-eslint/no-unused-vars
          ...restAccounts
        },
        ...rest
      } = state;
      const newAuthState: IAuthStateData = { accounts: restAccounts };
      if (
        action.payload.accountID.toString() !==
        state.effectiveUser?.loginID.toString()
      ) {
        Object.assign(newAuthState, rest);
      }
      return newAuthState;

    // Logs out of all accounts
    case AuthActionsTypes.LOGOUT_ALL:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
