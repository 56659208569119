import { useUser } from '@/lib/auth';
import { useZodForm } from '@/utils/form';
import { useEffect } from 'react';
import {
  Link,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { z } from 'zod';
import Title from '../common/Title';
import MyListbox from '../Form/ListBox';
import EditProfile from '../profile/EditProfile';
import CreateDelegate from './CreateDelegate';
import Delegations from './Delegations';
import EditDelegate from './EditDelegate';
import ManageAccounts from './ManageAccounts';
import PointOfSale from './PointOfSale';
import Preferences from './Preferences';
import Tips from './Tips';

// This is the list of settings that are available to the user
// maps the url path to the label that is displayed in the sidebar
const settings = [
  {
    path: '/settings/editprofile',
    label: 'Edit Profile',
  },
  {
    path: '/settings/delegations',
    label: 'Delegations',
  },
  {
    path: '/settings/manage-accounts',
    label: 'Manage Accounts',
  },
  {
    path: '/settings/point-of-sale',
    label: 'Point of Sale',
  },
  {
    path: '/settings/tips',
    label: 'Tips',
  },
  {
    path: '/settings/preferences',
    label: 'Preferences',
  },
] as const;

const settingFilter =
  (isDelegate: boolean, isMerchant: boolean) =>
  (setting: (typeof settings)[number]) => {
    if (isDelegate) {
      return setting.path !== '/settings/point-of-sale';
    }

    if (!isMerchant) {
      return (
        setting.path !== '/settings/point-of-sale' &&
        setting.path !== '/settings/tips'
      );
    }

    return settings;
  };

function Settings() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useUser();

  const isMerchant = !!currentUser.data?.user.businessName;

  const methods = useZodForm({
    schema: z.object({
      setting: z.object({
        path: z.string(),
        label: z.string(),
      }),
    }),
    defaultValues: {
      setting: {
        path: location.pathname,
        label: settings.find(
          (setting) =>
            setting.path ===
            (location.pathname as (typeof settings)[number]['path'])
        )?.label,
      },
    },
  });

  useEffect(() => {
    navigate(methods.getValues('setting').path);
  }, [methods.watch('setting')]);

  if (location.pathname === '/settings') {
    return <Navigate to="/settings/editprofile" />;
  }

  return (
    <div className="container w-full max-w-4xl">
      <Title>Settings</Title>
      <div>
        <MyListbox
          displayValue={(prop) => prop.label}
          name="setting"
          control={methods.control}
          keyValue={(prop) => prop.path}
          values={settings.filter(settingFilter(false, isMerchant))}
          placeholder="Select a setting"
        />
      </div>
      <div className="mt-4 gap-8 md:flex">
        <div className="mt-20 hidden w-40 flex-col gap-2 md:flex">
          {settings.filter(settingFilter(false, isMerchant)).map((setting) => (
            <Link
              key={setting.path}
              to={setting.path}
              className={`${
                setting.path === location.pathname
                  ? 'link-primary'
                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
              } link flex items-center rounded-md px-3 py-1 no-underline`}
            >
              {setting.label}
            </Link>
          ))}
        </div>
        <div className="flex-1">
          {/* Hide settings title on mobile */}
          <div className="hidden md:block">
            <h3 className="text-2xl">
              {
                settings.find((setting) => setting.path === location.pathname)
                  ?.label
              }
            </h3>
            <hr />
          </div>
          <div className="mt-2">
            <Routes>
              <Route path="/editprofile" element={<EditProfile />} />
              <Route path="/delegations" element={<Delegations />} />
              <Route path="/delegations/create" element={<CreateDelegate />} />
              <Route
                path="/delegations/edit/:userId"
                element={<EditDelegate />}
              />
              <Route path="/manage-accounts" element={<ManageAccounts />} />
              <Route path="/point-of-sale" element={<PointOfSale />} />
              <Route path="/tips" element={<Tips />} />
              <Route path="/preferences" element={<Preferences />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
