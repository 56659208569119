import { CircularProgress } from '@/components/Elements/circularprogress';
import { Link as MuiLink } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { FaSearch, FaStore, FaStoreAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { useIssuers } from '../../features/issuers/getIssuers';
import { useMyBolts } from '../../features/user/getMyBolts';
import { getImageURL } from '../../services/api';
import { useZodForm } from '../../utils/form';
import { asCurrencyString } from '../../utils/money';
import { Categories, IssuerData } from '../../utils/types';
import { CustomDialog } from '../common/Dialog';

function Vendor({ issuer, zuz }: { issuer: IssuerData; zuz?: number }) {
  return (
    <MuiLink
      component={Link}
      to={`/vendor/${issuer.id}`}
      underline="none"
      color="inherit"
      className="inline-flex w-full items-center gap-4 p-4 shadow-lg hover:shadow-2xl"
    >
      <div className="flex h-14 w-14 items-center justify-center">
        {issuer.logo ? (
          <img src={getImageURL(issuer.logo)} className="w-full" />
        ) : (
          <FaStore />
        )}
      </div>
      <div>
        <div className="font-semibold tracking-wide">{issuer.businessName}</div>
        {zuz && (
          <div className="text-sm font-semibold tracking-wider text-neutral-500">
            {asCurrencyString(zuz)} ZUZ
          </div>
        )}
      </div>
    </MuiLink>
  );
}

export function MarketPlace() {
  const [view, setView] = useState<'merchants' | 'active_campaigns'>(
    'merchants'
  );
  const [open, setOpen] = useState(false);

  const { data: issuers, status: issuersStatus } = useIssuers();
  const { data: myBolts, status: myBoltsStatus } = useMyBolts();

  const methods = useZodForm({
    schema: z.object({
      query: z.string(),
      categories: z.array(z.nativeEnum(Categories)),
    }),
    defaultValues: {
      query: '',
      categories: Object.values(Categories),
    },
  });

  const [filteredIssuers, setFilteredIssuers] = useState<IssuerData[]>([]);

  useEffect(() => {
    if (issuersStatus === 'success') {
      const filtered = issuers.result.filter(
        (issuer) =>
          issuer.businessName
            .toLowerCase()
            .includes(methods.watch('query').toLowerCase()) &&
          (!(view === 'active_campaigns') ||
            issuer.campaigns.some((campaign) => campaign.active))
      );
      setFilteredIssuers(filtered);
    }
  }, [issuersStatus, methods.watch('query'), view]);

  if (issuersStatus === 'loading' || myBoltsStatus === 'loading')
    return <CircularProgress />;
  if (issuersStatus === 'error' || myBoltsStatus === 'error')
    return <div>Something went wrong</div>;

  return (
    <div>
      <div>
        <span className="text-3xl">Market Place</span>
      </div>
      <div className="mt-2 w-full">
        <form
          onSubmit={methods.handleSubmit(console.log, console.error)}
          className="input-group w-full rounded-md bg-primary bg-opacity-10 outline outline-1 outline-primary"
        >
          <button type="submit" className="btn-ghost btn">
            <FaSearch />
          </button>
          <input
            type="text"
            className="input-ghost input w-full"
            placeholder="Search Merchants"
            {...methods.register('query')}
          />
          {/* <button
            onClick={() => setOpen(true)}
            type="button"
            className="btn-ghost btn"
          >
            <FaSort />
          </button> */}
          <CustomDialog isOpen={open} onClose={() => setOpen(false)}>
            <div className="flex flex-col gap-4">
              <div className="text-lg font-semibold">Categories</div>
              <div className="flex flex-col gap-1">
                {Object.values(Categories).map((category) => (
                  <label key={category} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="checkbox-secondary checkbox checkbox-sm border-solid"
                      value={category}
                      {...methods.register('categories')}
                    />
                    <span>{category}</span>
                  </label>
                ))}
              </div>
              <button
                type="button"
                className="btn-secondary btn-sm btn"
                onClick={() => setOpen(false)}
              >
                Apply
              </button>
            </div>
          </CustomDialog>
        </form>
      </div>
      <div className="input-group mt-2 w-full">
        <button
          className={clsx(
            'btn-outline btn flex-1 gap-2',
            view === 'merchants' && 'btn-primary'
          )}
          onClick={() => setView('merchants')}
        >
          <FaStoreAlt />
          Merchants
        </button>
        <button
          className={clsx(
            'btn-outline btn flex-1 gap-2',
            view === 'active_campaigns' && 'btn-primary'
          )}
          onClick={() => setView('active_campaigns')}
        >
          <FaStoreAlt />
          Campaigns
        </button>
      </div>
      {myBolts.bolts.length > 0 && (
        <>
          <div className="mt-4">
            <span className="font-body text-lg font-semibold">
              Where you can spend ZUZ
            </span>
          </div>
          <div className="flex flex-col gap-2">
            {issuers.result
              .filter((issuer) =>
                myBolts.bolts.some(
                  (bolt) =>
                    issuer.bolts.some((b) => b === bolt.bolt.specID) ||
                    issuer.acceptedZuz?.some((z) => z === bolt.bolt.specID)
                )
              )
              .sort((a, b) => a.businessName.localeCompare(b.businessName))
              .map((issuer) => {
                const zuz = myBolts.bolts
                  .filter(
                    ({ bolt }) =>
                      issuer.bolts.includes(bolt.specID) ||
                      issuer.acceptedZuz?.includes(bolt.specID)
                  )
                  .reduce((acc, bolt) => acc + bolt.amount, 0);
                return <Vendor key={issuer.id} issuer={issuer} zuz={zuz} />;
              })}
          </div>
        </>
      )}
      <div className="mt-4">
        <span className="font-body text-lg font-semibold">
          All ZUZ Merchants
        </span>
      </div>
      <div className="flex flex-col gap-2">
        {filteredIssuers
          .filter(
            (issuer) =>
              !myBolts.bolts.some((bolt) =>
                issuer.bolts.some((b) => b === bolt.bolt.specID)
              )
          )
          .map((issuer) => (
            <Vendor key={issuer.id} issuer={issuer} />
          ))}
      </div>
    </div>
  );
}
