import { toast } from 'react-hot-toast';
import { Dispatch } from 'redux';
import { delegateService } from '../../services/userService';
import { USERUUID } from '../../utils/types';
import { MintPermissions, SellPermissions } from '../reducers/authReducer';

export const delegatePermissions = (
  delegate: USERUUID,
  mint: MintPermissions,
  sell: SellPermissions,
  accept: boolean
) => {
  console.log('mint: ' + mint);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (dispatch: Dispatch) => {
    // eslint-disable-line @typescript-eslint/no-unused-vars
    delegateService(delegate, mint, sell, accept)
      .then((res: any) => {
        console.log('res: ' + res);
        if (res.status === 'error') {
          toast.error(res.msg);
        } else {
          toast.success('Successfully delegated permissions');
        }
      })
      .catch((err: any) => {
        console.log(err);
        toast.error('AJAX error while delegating permissions');
      });
  };
};
