import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import { IBOLTDATA } from '../../utils/types';
import { getSession } from './auth/getSession';

type BoltResult = {
  status: string;
  bolts: {
    amount: number;
    createdAt: Date;
    bolt: IBOLTDATA;
    lastCalcTS: number;
    updatedAt: Date;
    _id: string;
  }[];
};

export const getMyBolts = async () => {
  const session = await getSession();
  if (!session.user) {
    return {
      status: 'error',
      bolts: [],
    };
  }

  return axios.get(`/user/my-bolts`).then((res) => {
    const raw = res.data as BoltResult;
    const filtered = raw.bolts.filter((bolt) => bolt.amount >= 0.01);
    raw.bolts = filtered;
    console.log(raw.bolts);
    return raw;
  });
};

type UseMyBoltsOptions = {
  config?: QueryConfig<typeof getMyBolts>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useMyBolts = ({ config }: UseMyBoltsOptions = {}) => {
  return useQuery({
    // ...config,
    queryKey: ['my-bolts'],
    queryFn: () => getMyBolts(),
  });
};
