// @ts-check

import { schema } from './schema.mjs';

export const formatErrors = (
  /** @type {import('zod').ZodFormattedError<Map<string,string>,string>} */
  errors
) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && '_errors' in value)
        return `${name}: ${value._errors.join(', ')}\n`;
      return `${name}: ${value}\n`;
    })
    .filter(Boolean);

const _env = schema.safeParse(import.meta.env);

if (!_env.success) {
  console.error(
    '❌ Invalid environment variables:\n',
    ...formatErrors(_env.error.format())
  );
  throw new Error('Invalid environment variables');
}

export const env = _env.data;
