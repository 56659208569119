import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { MutationConfig } from '../../lib/react-query';

export const forgotPassword = ({ username }: { username: string }) =>
  axios.post('/user/forgot-password', { username }).then(({ data }) => data);

type UseChangePasswordOptions = {
  config?: MutationConfig<typeof forgotPassword>;
};

export const useForgotPassword = ({ config }: UseChangePasswordOptions) => {
  return useMutation({
    ...config,
    mutationFn: forgotPassword,
  });
};
