import { LoginResponse } from '@/features/auth/login';
import { PermissionStore } from '@/stores/PermissionStore';

type ValueOf<T> = T[keyof T];
type Entries<T> = [keyof T, ValueOf<T>][];

// Same as `Object.entries()` but with type inference
function objectEntries<T extends object>(obj: T): Entries<T> {
  return Object.entries(obj) as Entries<T>;
}

export default function seedPermissions(
  permissions: LoginResponse['permissions'],
  permissionStore: PermissionStore
) {
  objectEntries(permissions).forEach(([issuer, perm]) => {
    perm.forEach((p) => {
      switch (p.type) {
        case 'accept':
          permissionStore.addAcceptPermission(issuer.toString());
          break;
        case 'sell':
          permissionStore.addSellPermission(
            issuer.toString(),
            p.sell.specIDs,
            p.sell.maxLimit
          );
          break;
        case 'mint':
          permissionStore.addMintPermission(
            issuer.toString(),
            p.mint.specIDs,
            p.mint.maxLimit
          );
          break;
      }
    });
  });
}
