import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { MutationConfig } from '../../lib/react-query';

export const initTransaction = async ({
  boltID,
  userID,
  amount,
  qrCreator = '',
  tip,
  merchantID,
  usingPOS,
  posTid,
}: {
  boltID: string;
  userID: string;
  amount: { base: number; tip: number } | number;
  qrCreator?: string;
  tip?: number;
  merchantID?: string;
  usingPOS?: string;
  posTid?: string;
}) => {
  const data = {
    'Content-Type': 'application/json;charset=UTF-8',
    boltID,
    userID,
    amount,
    qrCreator,
    tip,
    ...(merchantID ? { merchantID } : {}),
    ...(usingPOS ? { usingPOS } : {}),
    ...(posTid ? { posTid } : {}),
  };
  return axios.post('/v2/transaction', data);
};

type useInitTransactionOptions = {
  config?: MutationConfig<typeof initTransaction>;
};

export const useInitTransaction = ({
  config,
}: useInitTransactionOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: initTransaction,
  });
};
