import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import { IBOLTDATA } from '../../utils/types';

type BoltResult = {
  status: string;
  bolt: IBOLTDATA;
};

export const getBolt = ({ specId }: { specId: string }) => {
  return axios.get(`/bolt/${specId}`).then((res) => res.data as BoltResult);
};

type UseBoltOptions = {
  specId: string;
  config?: QueryConfig<typeof getBolt>;
};

export const useBolt = ({ config, specId }: UseBoltOptions) => {
  return useQuery({
    ...config,
    queryKey: ['bolt', specId],
    queryFn: () => getBolt({ specId }),
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};
