import { useUser } from '@/lib/auth';
import { useZodForm } from '@/utils/form';
import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { useEditUser } from '../../features/user/editUser';
import ChangePasswordDialog from './ChangePasswordDialog';
import { ReturnAvatar } from './ReturnAvatar';

/**
 * Top-level component to edit user's profile
 */
const EditProfile = () => {
  const user = useUser();

  const queryClient = useQueryClient();

  const editUser = useEditUser({
    config: {
      onSuccess: () => {
        queryClient.refetchQueries(['user']);
        toast.success('Successfully edited profile');
      },
      onError: () => {
        toast.error('Failed to edit profile');
      },
    },
  });

  const methods = useZodForm({
    schema: z.object({
      firstName: z.string(),
      lastName: z.string(),
    }),
    defaultValues: {
      firstName: user.data?.user.firstName || '',
      lastName: user.data?.user.lastName || '',
    },
  });

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  if (!user.isSuccess) return <span className="loading loading-spinner" />;
  if (!user.data) return null;

  return (
    <>
      <ChangePasswordDialog
        isOpen={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
      />
      <Box maxWidth="lg">
        <form
          className="flex flex-col gap-4"
          onSubmit={methods.handleSubmit(({ firstName, lastName }) => {
            editUser.mutate({
              userId: user.data!.user.id, // eslint-disable-line @typescript-eslint/no-non-null-assertion
              firstName,
              lastName,
            });
          })}
        >
          <div className="m-2 flex max-h-[7vh] min-w-[30vh] items-end justify-center">
            <ReturnAvatar />
          </div>
          <div>
            <label htmlFor="firstname" className="label label-text">
              First Name
            </label>
            <input
              id="firstname"
              className="input-bordered input w-full"
              {...methods.register('firstName')}
            />
          </div>
          <div>
            <label htmlFor="lastname" className="label label-text">
              Last Name
            </label>
            <input
              id="lastname"
              className="input-bordered input w-full"
              {...methods.register('lastName')}
            />
          </div>
          <div>
            <label htmlFor="username" className="label label-text">
              Username
            </label>
            <input
              id="username"
              disabled
              className="input-bordered input w-full"
              value={user.data!.user.username} // eslint-disable-line @typescript-eslint/no-non-null-assertion
            />
          </div>
          <div>
            <label htmlFor="email" className="label label-text">
              Email
            </label>
            <input
              id="email"
              disabled
              className="input-bordered input w-full"
              value={user.data!.user.email} // eslint-disable-line @typescript-eslint/no-non-null-assertion
            />
          </div>
          <div className="flex gap-4">
            <button
              type="button"
              className="sm btn-ghost btn-sm btn"
              onClick={() => methods.reset()}
            >
              Reset
            </button>
            <button
              className="btn-primary btn-sm btn"
              disabled={!methods.formState.isDirty}
            >
              Save
            </button>
          </div>
        </form>
        <button
          type="button"
          onClick={() => setChangePasswordOpen(true)}
          className="btn-secondary btn-sm btn mt-4"
        >
          Change Password
        </button>
      </Box>
    </>
  );
};

export default EditProfile;
