import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { MutationConfig } from '../../lib/react-query';

//The 'editUser' function is responsible for making a PUT request to the server to edit user profile information
export const changePassword = ({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) =>
  axios
    .post('/user/change-password', {
      oldPwd: oldPassword,
      newPwd: newPassword,
    })
    .then(({ data }) => data);

type UseChangePasswordOptions = {
  config?: MutationConfig<typeof changePassword>;
};

export const useChangePassword = ({ config }: UseChangePasswordOptions) => {
  return useMutation({
    ...config,
    mutationFn: changePassword,
  });
};
