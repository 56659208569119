import { useInitSqrTransaction } from '@/features/transaction/initSqrTransaction.js';
import { useDialogStore } from '@/stores/DialogStore.js';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { env } from '../env/client.mjs';
import { getIssuer } from '../features/issuers/getIssuer.js';
import { CustomDialog } from './common/Dialog.js';
import SpendZuz from './SpendZuz';

const offsetAmount = 0;
const offsetIssuerId = 1;
const offsetHash = 2;

//Dialog containing qr scanner
function QRScannerDialog() {
  const { dialog, clearDialog } = useDialogStore();

  const [spendDialogOpen, setSpendDialogOpen] = useState(false);
  const [storeName, setStoreName] = useState('');
  const [storeId, setStoreId] = useState('');
  const [qrCreator, setQrCreator] = useState('');
  const [additionalZuz, setAdditionalZuz] = useState<Array<string>>([]); // any ZUZ which reciever will definitely accept (in addition to their own)
  const [usePOS, setusePOS] = useState('none'); // POS being used by seller
  const [posTid, setPosTid] = useState(''); // extra data for POS transaction
  const [allowTips, setAllowTips] = useState(false); // we only include tips if explicitly told that it is ok

  const [amt, setAmt] = useState<number | undefined>();
  const [data, setData] = useState<string | null>(null);

  const initSqrTransaction = useInitSqrTransaction({
    config: {
      onSuccess: (res) => {
        setStoreName(res.sqrdata.issuerName);
        setStoreId(res.sqrdata.issuerID);
        setAdditionalZuz(res.sqrdata.addtionalZuz);
        setAmt(res.sqrdata.amount);
        setAllowTips(true); // we allow tips from a sqr POS is merchant asked for it
        setPosTid(res.sqrdata.rid);
        setusePOS('square');
        setSpendDialogOpen(true);
      },
      onError: (err) => {
        console.log(err);
        toast.error('Error initiating transaction');
      },
    },
  });

  //Handles scanning the qr code
  const handleScan = async (scan: string) => {
    setData(null);

    // check to see if this is from square
    if (scan.indexOf('https://square.link') === 0) {
      // process the link from square
      initSqrTransaction.mutate({ sqrurl: scan });
      return;
    }

    if (!scan.includes(env.VITE_QR_PREFIX)) {
      toast.error('Invalid QR code');
      clearDialog();
      return;
    }

    // remove the basename from the pathname
    const pathname = scan.substring(env.VITE_QR_PREFIX.length);
    const pieces = pathname.split('/');

    // determine whether tips are allowed
    const tipChar = pieces[offsetAmount][0] === 'T';
    if (tipChar) pieces[offsetAmount] = pieces[offsetAmount].substring(1);

    const issuer = await getIssuer({ issuerId: pieces[offsetIssuerId] });

    // check if the issuer is valid
    if (
      issuer.result?.businessName === undefined ||
      pieces[offsetAmount] === undefined
    ) {
      toast.error('Invalid QR code');
      clearDialog();
      return;
    }

    setStoreName(issuer.result.businessName);
    setStoreId(String(pieces[offsetIssuerId]));
    setAmt(parseFloat(pieces[offsetAmount]));
    setQrCreator(pieces[offsetHash]);
    setAllowTips(tipChar);

    if (!!issuer.result.acceptedZuz)
      setAdditionalZuz([...additionalZuz, ...issuer.result.acceptedZuz]);

    clearDialog();
    setSpendDialogOpen(true);
  };

  // the data is loaded asynchronously, so we need to wait for it to be ready
  useEffect(() => {
    if (data === null) return;
    handleScan(data);
  }, [data]);

  return (
    <>
      <SpendZuz
        isOpen={spendDialogOpen}
        setIsOpen={setSpendDialogOpen}
        storeName={storeName}
        storeId={storeId}
        amt={amt}
        pos={usePOS}
        extraZuz={additionalZuz}
        qrCreator={qrCreator}
        allowTips={allowTips}
        posTid={posTid}
      />

      <CustomDialog isOpen={dialog === 'QrScanner'} onClose={clearDialog}>
        <div className="flex items-center justify-between">
          <div className="text-2xl font-bold">Scan QR Code</div>
        </div>
        <div>
          <QrScanner
            onDecode={(result) => {
              if (!result) return;
              setData(result);
            }}
            tracker={false}
            scanDelay={1000}
            onError={console.error}
            constraints={{ facingMode: 'environment' }}
          />
        </div>
      </CustomDialog>
    </>
  );
}

export default QRScannerDialog;
