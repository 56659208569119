import { useChangePassword } from '@/features/user/changePassword';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { useZodForm } from '../../utils/form';
import { CustomDialog } from '../common/Dialog';

type ChangePasswordDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ChangePasswordDialog({
  isOpen,
  onClose,
}: ChangePasswordDialogProps) {
  const methods = useZodForm({
    schema: z
      .object({
        oldPassword: z
          .string()
          .min(4, 'Password must be at least 4 characters'),
        newPassword: z
          .string()
          .min(4, 'Password must be at least 4 characters'),
        confirmPassword: z.string(),
      })
      .superRefine((args, ctx) => {
        if (args.newPassword !== args.confirmPassword) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Passwords do not match',
            path: ['confirmPassword'],
          });
        }
      }),
  });

  const changePassword = useChangePassword({
    config: {
      onSuccess: () => {
        toast.success('Successfully changed password');
        onClose();
      },
    },
  });

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => {
        onClose();
        methods.reset();
      }}
    >
      <div className="flex flex-col p-4">
        <form
          className="flex flex-col gap-3"
          onSubmit={methods.handleSubmit(async (data) =>
            changePassword.mutate(data)
          )}
        >
          <div className="flex flex-col">
            <label className="label label-text">Old Password</label>
            <input
              type="password"
              className="input-bordered input"
              placeholder="Old Password"
              {...methods.register('oldPassword')}
            />
            <span className="text-sm text-error">
              {methods.formState.errors.oldPassword?.message}
            </span>
          </div>
          <div className="flex flex-col">
            <label className="label label-text">New Password</label>
            <input
              type="password"
              className="input-bordered input"
              placeholder="New Password"
              {...methods.register('newPassword')}
            />
            <span className="text-sm text-error">
              {methods.formState.errors.newPassword?.message}
            </span>
          </div>
          <div className="flex flex-col">
            <label className="label label-text">Confirm New Password</label>
            <input
              type="password"
              className="input-bordered input"
              placeholder="Confirm Password"
              {...methods.register('confirmPassword')}
            />
            <span className="text-sm text-error">
              {methods.formState.errors.confirmPassword?.message}
            </span>
          </div>
          <div className="flex flex-col">
            <button
              id="d.submit"
              type="submit"
              className="btn-secondary btn-sm btn"
            >
              Update Password
            </button>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
}
