import { axios } from '@/lib/axios';

export async function logerrBackend(
  msg: string,
  forceReload: boolean
): Promise<void> {
  axios.post('/general/logFEerror', { msg });
  console.log(`report to @seth on wallet channel: ${msg}.`);
  if (forceReload) window.location.reload();
}
