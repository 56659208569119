import { DRYTXNDATA } from '../../utils/types';
import { SentTxnActionsTypes } from '../actions/actionTypes';

const initialState: DRYTXNDATA | null = null;

export interface SentTransactionAction {
  type: SentTxnActionsTypes;
  payload: {
    transaction: DRYTXNDATA | null;
  };
}

function usersReducer(
  state: typeof initialState = initialState,
  action: SentTransactionAction
) {
  switch (action.type) {
    case SentTxnActionsTypes.GET_SENT_TXN: {
      return state;
    }
    case SentTxnActionsTypes.UPDATE_SENT_TXN: {
      state = action.payload.transaction;
      return state;
    }
    default:
      return state;
  }
}

export default usersReducer;
