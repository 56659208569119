import { useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { useInitTransaction } from '../../features/transaction/initTransaction';
import { useMyBolts } from '../../features/user/getMyBolts';
import useTransactionStore from '../../stores/TransactionStore';
import { useZodForm } from '../../utils/form';
import { CustomDialog } from '../common/Dialog';
import { UserAutoComplete } from '../Form/Combobox';

type SpendBoltProps = {
  isOpen: boolean;
  onClose: () => void;
  specId: string;
};
export function SpendBolt({ isOpen, onClose, specId }: SpendBoltProps) {
  const { data } = useMyBolts();
  const methods = useZodForm({
    schema: z
      .object({
        payee: z.object({
          id: z.number().int(),
        }),
        amount: z.coerce.number().positive(),
      })
      .superRefine(({ amount }, ctx) => {
        const walletAmount =
          data?.bolts.find((bolt) => bolt.bolt.specID == specId)?.amount ?? 0;
        if (amount > walletAmount) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Insufficient Funds',
            path: ['amount'],
          });
        }
      }),
    // add default values so we make sure component starts off controlled
    defaultValues: {
      payee: {
        id: -1,
      },
    },
  });

  const queryClient = useQueryClient();

  const { setSentTransaction } = useTransactionStore();
  const initTransaction = useInitTransaction({
    config: {
      onSuccess: (res) => {
        methods.reset();
        queryClient.refetchQueries(['my-bolts']);
        setSentTransaction(res.data.transaction);
      },
    },
  });

  return (
    <CustomDialog isOpen={isOpen} onClose={onClose}>
      <div className="text-center font-display text-xl font-bold text-primary">
        Spend
      </div>
      <form
        className="flex flex-col justify-between gap-8"
        onSubmit={methods.handleSubmit(async (values) => {
          onClose();
          initTransaction.mutate({
            boltID: specId,
            userID: values.payee.id.toString(),
            amount: values.amount,
            merchantID: undefined,
          });
        })}
      >
        <div className="w-full">
          <label className="label">
            <span className="label-text">Payee</span>
          </label>
          <UserAutoComplete control={methods.control} name="payee" />
          <span className="text-xs text-error">
            {methods.formState.errors.payee?.message}
          </span>
        </div>
        <div className="w-full">
          <label className="label">
            <span className="label-text">Amount</span>
          </label>
          <input
            className="input-bordered input-primary input w-full"
            type="number"
            step={0.01}
            {...methods.register('amount')}
          />
          <span className="text-xs text-error">
            {methods.formState.errors.amount?.message}
          </span>
        </div>
        <div className="flex w-full justify-center gap-4">
          <button
            onClick={onClose}
            type="button"
            className="btn-primary btn-outline btn-sm btn"
          >
            Back
          </button>
          <button type="submit" className="btn-secondary btn-sm btn">
            Submit
          </button>
        </div>
      </form>
    </CustomDialog>
  );
}
