import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { MutationConfig } from '../../lib/react-query';

//The 'editUser' function is responsible for making a PUT request to the server to edit user profile information
export const resetPassword = ({
  username,
  token,
  newPassword,
}: {
  username: string;
  token: string;
  newPassword: string;
}) =>
  axios
    .post(`/user/reset-password/${username.toLowerCase()}`, {
      token,
      newPassword,
    })
    .then(({ data }) => data);

type UseResetPasswordOptions = {
  config?: MutationConfig<typeof resetPassword>;
};

export const useResetPassword = ({ config }: UseResetPasswordOptions) => {
  return useMutation({
    ...config,
    mutationFn: resetPassword,
  });
};
