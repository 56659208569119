import { useQuery } from '@tanstack/react-query';

import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';

export const getTOS = async () => {
  return fetch('/terms-of-service.html').then((data) => data.text());
};

type QueryFnType = typeof getTOS;

type UseTOSOptions = {
  config?: QueryConfig<QueryFnType>;
};

// We prefetch and cache the TOS
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useTOS = ({ config }: UseTOSOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    // ...config,
    queryKey: ['tos'],
    queryFn: getTOS,
  });
};
