import { useQuery } from '@tanstack/react-query';
import { QueryConfig } from '../../lib/react-query';

type StoryResult = {
  vendor: number;
  stories: [
    {
      title: string;
      caption: string | null;
      image?: string;
    }
  ];
};

export const getStory = ({ issuerId }: { issuerId: string }) => {
  return fetch(`/stories/${issuerId}/story.json`)
    .then((res) => res.json())
    .then((res) => res as StoryResult)
    .catch(() => ({ vendor: issuerId, stories: [] }));
};

type UseStoryOptions = {
  issuerId: string;
  config?: QueryConfig<typeof getStory>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useStory = ({ config, issuerId }: UseStoryOptions) => {
  return useQuery({
    // ...config,
    queryKey: ['story', issuerId],
    queryFn: () => getStory({ issuerId }),
  });
};
