/* Component to find account/initate forgot password process. Users submit their username here and if their account is found, they will
 * receive a link by email, which they can use to reset their password.
 */
import { useForgotPassword } from '@/features/user/forgotPassword';
import { useZodForm } from '@/utils/form';
import { useRef, useState } from 'react';
import { z } from 'zod';

import {
  ZodFormTextField,
  ZodSubmitButton,
} from '@/components/Elements/ZodFormFields';

import { toast } from 'react-hot-toast';

function ForgotPassword() {
  const [isSuccess, setIsSuccess] = useState(false);
  const lastSubmit = useRef(null);

  // form to fill out when requesting password reset
  const methods = useZodForm({
    schema: z.object({
      username: z
        .string()
        .min(4, 'usernames and emails are at least 4 characters long'),
    }),
    // add default values so we make sure component starts off controlled
    defaultValues: {
      username: '',
    },
  });

  // used to ask server to send forgot password email.
  const forgotPassword = useForgotPassword({
    config: {
      onSuccess: () => {
        setIsSuccess(true);
      },
      onError: (data) => {
        console.log(data);
        lastSubmit.current = null;
        toast.error('there was an error');
        setIsSuccess(false);
      },
    },
  });

  // user hit submit.
  // If it is oktosend, then tell server through forgotPassword (hook defined above).
  // Otherwise, tell them to waot a bit more
  const onSubmit = methods.handleSubmit(async ({ username }) => {
    const now = Math.floor(new Date().getTime() / 1000);
    if (lastSubmit.current !== null && now - lastSubmit.current < 60) {
      const delta = now - lastSubmit.current;
      toast.error(
        `Please check your email.  You can re-submit your request in ${delta} seconds.`
      );
      return;
    }
    lastSubmit.current = now;
    forgotPassword.mutate({ username });
  });

  const onResend = () => {
    const now = Math.floor(new Date().getTime() / 1000);
    if (lastSubmit.current !== null && now - lastSubmit.current < 60) {
      const delta = 60 - (now - lastSubmit.current);
      toast.error(
        `Please check your email.  You can re-submit your request in ${delta} seconds.`
      );
      return;
    }
    setIsSuccess(false);
    return false;
  };

  if (isSuccess) {
    return (
      <div className="mt-8 flex flex-col items-center text-center">
        <h3 className="text-center text-lg font-bold">
          A reset password email has been sent to your inbox!
        </h3>
        <div className="mt-4 max-w-sm">
          <div className="text-center">
            Please follow the instructions in the email to reset your password.
          </div>
          <br />
          <div className="italic">
            <div>Didn&apos;t see the email?</div>
            <div>Click to resend.</div>
          </div>
          <br />
          <button
            className="btn-primary btn mt-2"
            type="submit"
            onClick={onResend}
          >
            Resend
          </button>
        </div>
      </div>
    );
  }

  // Initial form for user to enter their username or email to get a
  // reset password email sent to them.

  return (
    <div className="mt-8 flex flex-col items-center text-center">
      <h3 className="text-center text-xl font-bold">Find Your Account</h3>
      <br />
      <div className="max-w-sm">
        Enter your username or email and if we find it, we&apos;ll send you a
        link to get back into your account.
        <form className="form-control mt-4 w-full gap-2" onSubmit={onSubmit}>
          {/* How do I get space above this? */}
          <ZodFormTextField
            placeholder="Username or Email"
            id="username"
            schema={methods}
            name="username"
          />
          {/* How can I make this narrower? */}
          {/* Why isn't the submit button disabled even if username is empty? */}
          <ZodSubmitButton disabled={!methods.formState.isValid} />
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
