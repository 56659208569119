import { useUser } from '@/lib/auth';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { z } from 'zod';
import { QrUniqId } from '../../context/WebSocketContext';
import { env } from '../../env/client.mjs';
import { showQR } from '../../features/transaction/showQR';
import { useZodForm } from '../../utils/form';
import { CustomDialog } from '../common/Dialog';

type QRvalueObject = {
  code: string;
  id: string;
  amt: number;
};

function makeQRvalue({ code, id, amt }: QRvalueObject) {
  return `${env.VITE_QR_PREFIX}T${amt}/${id}/${code.toUpperCase()}`;
}

function QRCodeCreator() {
  const user = useUser();

  const [openQrDialog, setOpenQrDialog] = useState(false);

  const methods = useZodForm({
    schema: z
      .object({
        amount: z.coerce.number().gt(0),
        tax: z.coerce.number().min(0),
        note: z.string(),
      })
      .superRefine(({ amount, tax }, ctx) => {
        if (tax && amount < tax) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Tax cannot be greater than amount',
            path: ['tax'],
          });
        }
      }),
    defaultValues: {
      tax: 0,
    },
  });

  return (
    <>
      <div className="flex w-full max-w-sm flex-col items-center justify-center rounded-lg p-8 shadow-xl">
        <form
          className="flex w-full flex-col gap-2"
          onSubmit={methods.handleSubmit(({ amount, tax, note }) => {
            setOpenQrDialog(true);
            showQR({
              id: QrUniqId,
              amount,
              tax,
              note,
            });
          }, console.error)}
        >
          <div>
            <span className="text-xl font-bold">Request ZUZ</span>
          </div>
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">Amount</span>
            </label>
            <div className="relative w-full">
              <label className="absolute left-1.5 top-1/2 -translate-y-1/2">
                $
              </label>
              <input
                type="number"
                step={0.01}
                className="input-bordered input w-full"
                placeholder="0.00"
                {...methods.register('amount')}
              />
            </div>
            <label className="label">
              <span className="label-text-alt text-error">
                {methods.formState.errors.amount?.message}
              </span>
            </label>
          </div>
          {!!Object.hasOwn(user.data?.user ?? {}, 'showTax') && (
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Tax</span>
                <span className="label-text-alt">Included In Amount</span>
              </label>
              <div className="relative w-full">
                <label className="absolute left-1.5 top-1/2 -translate-y-1/2">
                  $
                </label>
                <input
                  type="number"
                  step={0.01}
                  className="input-bordered input w-full"
                  placeholder="0.00"
                  {...methods.register('tax')}
                />
              </div>
              <label className="label">
                <span className="label-text-alt text-error">
                  {methods.formState.errors.tax?.message}
                </span>
              </label>
            </div>
          )}
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">Notes</span>
            </label>
            <textarea
              className="textarea-bordered textarea w-full resize-none"
              {...methods.register('note')}
            />
            <label className="label">
              <span className="label-text-alt text-error">
                {methods.formState.errors.note?.message}
              </span>
            </label>
          </div>
          <div className="form-control w-full max-w-xs">
            <button className="btn-secondary btn-sm btn" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>

      <CustomDialog
        isOpen={openQrDialog}
        onClose={() => setOpenQrDialog(false)}
      >
        <div id="qrimg" className="flex w-full items-center justify-center">
          <QRCode
            value={makeQRvalue({
              id: !user.data ? '' : user.data?.user.id.toString(),
              amt: methods.getValues().amount,
              code: QrUniqId,
            })}
            level={'Q'}
          />
        </div>
      </CustomDialog>
    </>
  );
}

export default QRCodeCreator;
