import { useMutation } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';

//The 'editUser' function is responsible for making a PUT request to the server to edit user profile information
export const editUser = ({
  userId,
  username,
  firstName,
  lastName,
  email,
  profilePic,
}: {
  userId: string;
  username?: string;
  firstName?: string;
  lastName?: string | null;
  email?: string;
  profilePic?: string | File | null;
}) => {
  const form = new FormData();
  form.append('id', userId);
  if (firstName) form.append('firstName', firstName);
  if (username) form.append('username', username);
  if (email) form.append('email', email);
  if (lastName) form.append('lastName', lastName);
  if (profilePic) form.append('profilePic', profilePic);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return axios.put('/user/profile', form, config);
};

type UseEditUserOptions = {
  config?: QueryConfig<typeof editUser>;
};

export const useEditUser = ({ config }: UseEditUserOptions) => {
  return useMutation({
    ...config,
    mutationFn: editUser,
  });
};
