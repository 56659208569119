import { CircularProgress } from '@/components/Elements/circularprogress';
import { useSession } from '@/features/user/auth/getSession';
import { useUser } from '@/lib/auth';
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Hidden,
  MobileStepper,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type helpData = {
  merchant: [
    {
      intro: string;
      tocentry: string;
      tocurl: string;
      key: number;
      title: string;
      desktop: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
      mobile: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
    }
  ];
  customer: [
    {
      intro: string;
      tocentry: string;
      tocurl: string;
      key: number;
      title: string;
      desktop: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
      mobile: [
        {
          title: string;
          text: string;
          image: string;
        }
      ];
    }
  ];
};

function Help() {
  const [tab, setTab] = useState(0);
  const [helpData, setHelpData] = useState<helpData>();

  const session = useSession();
  const { data, status } = useUser(); // eslint-disable-line @typescript-eslint/no-unused-vars

  const navigate = useNavigate();

  const { device, user, article } = useParams();

  const isMerchant = Object.hasOwn(data?.user ?? {}, 'businessName');

  React.useEffect(() => {
    fetch('/help.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    }).then((res) => res.json().then((data) => setHelpData(data)));

    navigate(
      `/help/${device ?? window.innerWidth <= 640 ? 'mobile' : 'desktop'}/${
        user ?? isMerchant ? 'merchant' : 'customer'
      }/${article ?? 'explore-the-market'}`
    );
  }, []);

  React.useEffect(() => {
    if (!helpData || !user) return;
    const urlTab = helpData[user as keyof typeof helpData].find(
      (section) => section.tocurl === article
    );
    if (urlTab) setTab(urlTab.key);
  }, [helpData]);

  React.useEffect(() => {
    setTab(0);
  }, [user, device]);

  React.useEffect(() => {
    if (!session.isSuccess) return;
    if (!device || !user || !article) return;
    navigate(`/help/${device}/${user}/${article}`);
  }, [session.isSuccess, isMerchant, device, user, article]);

  if (!user || !device || !article || !helpData) return <CircularProgress />;
  if (device !== 'desktop' && device !== 'mobile') return <CircularProgress />;
  if (user !== 'customer' && user !== 'merchant') return <CircularProgress />;

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    if (!helpData) return;
    const section = helpData[user].find((section) => section.key === newValue);
    setTab(newValue);
    if (section) navigate(`/help/${device}/${user}/${section.tocurl}`);
  };

  return helpData ? (
    <Container maxWidth="lg">
      <Card style={{ margin: 10, borderRadius: 20, padding: 20 }}>
        <Grid
          container
          spacing={4}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Grid container spacing={10} alignItems="center">
              <Grid item>
                <Typography variant="h3">Help</Typography>
              </Grid>
              <Hidden smDown>
                <Grid item>
                  <ToggleButtonGroup
                    onChange={(e, newDevice) => {
                      if (!newDevice) return;
                      navigate(`/help/${newDevice}/${user}/${article}`);
                    }}
                    exclusive
                    value={device}
                  >
                    <ToggleButton value={'desktop'}>Desktop</ToggleButton>
                    <ToggleButton value={'mobile'}>Mobile</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Hidden>
              {(!session.data?.status || isMerchant) && (
                <Grid item>
                  <ToggleButtonGroup
                    onChange={(e, newUserType) => {
                      if (!newUserType) return;
                      navigate(`/help/${device}/${newUserType}/${article}`);
                    }}
                    exclusive
                    value={user}
                  >
                    <ToggleButton value={'customer'}>Customer</ToggleButton>
                    <ToggleButton value={'merchant'}>Merchant</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Desktop vertical help menu */}
          <Hidden mdDown>
            <Grid item md={3}>
              <Tabs value={tab} onChange={handleChange} orientation="vertical">
                {helpData[user]
                  ?.filter((section) => !!section[device])
                  .map((section) => (
                    <Tab key={section.tocentry} label={section.tocentry} />
                  ))}
              </Tabs>
            </Grid>
          </Hidden>

          {/* Mobile horizontal help menu */}
          <Hidden mdUp>
            <Grid item xs={12}>
              <Tabs
                value={tab}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
              >
                {helpData[user]
                  ?.filter((section) => !!section.mobile)
                  .map((section) => (
                    <Tab key={section.tocentry} label={section.tocentry} />
                  ))}
              </Tabs>
              <Divider />
            </Grid>
            <MobileStepper
              variant="dots"
              steps={helpData[user]?.length}
              position="static"
              activeStep={tab}
              nextButton={<></>}
              backButton={<></>}
            />
          </Hidden>

          {/* Desktop help content */}
          <Grid item md={9} hidden={device === 'mobile'}>
            <Grid container spacing={5}>
              <Grid item>
                <Typography variant="h5">
                  {
                    helpData[user]?.filter((section) => !!section[device])?.[
                      tab
                    ]?.title
                  }
                </Typography>
                {helpData[user]
                  ?.filter((section) => !!section[device])
                  .map(
                    (section) =>
                      section.intro && (
                        <div
                          key={section.key}
                          dangerouslySetInnerHTML={{ __html: section.intro }}
                          className="mt-8 ml-2 w-[9/10]"
                        />
                      )
                  )}
              </Grid>
              {helpData[user]
                ?.filter((section) => !!section[device])
                ?.[tab]?.[device]?.map((section, index) => (
                  <Grid item key={section.title}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div className="inline-flex items-center gap-1">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full">
                            <Typography color="textSecondary">
                              {index + 1}
                            </Typography>
                          </span>
                          <Typography>
                            <b>{section.title}</b>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          dangerouslySetInnerHTML={{ __html: section.text }}
                        />
                      </Grid>
                      {section.image && (
                        <Grid item xs={12}>
                          <Box
                            border={2}
                            borderRadius="borderRadius"
                            borderColor="grey.500"
                            style={{
                              overflow: 'hidden',
                              width: '80%',
                              marginInline: '10% 10%',
                              writingMode: 'horizontal-tb',
                            }}
                          >
                            <img
                              src={section.image}
                              alt="img"
                              style={{
                                width: '100%',
                              }}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>

          {/* Mobile help content */}
          <Grid item xs={12} md={9} hidden={device === 'desktop'}>
            <Grid container spacing={5}>
              <Grid item>
                <Typography variant="h5">
                  {
                    helpData[user]?.filter((section) => !!section.mobile)[tab]
                      ?.title
                  }
                </Typography>
                {helpData[user]
                  ?.filter((section) => !!section[device])
                  .map(
                    (section) =>
                      section.intro && (
                        <div
                          key={section.key}
                          dangerouslySetInnerHTML={{ __html: section.intro }}
                          className="mt-8 ml-2 w-[9/10]"
                        />
                      )
                  )}
              </Grid>
              {helpData[user]
                ?.filter((section) => !!section.mobile)
                [tab]?.mobile.map((section, index) => (
                  <Grid item key={section.title}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div className="inline-flex items-center gap-1">
                          <span className="flex h-10 w-10 items-center justify-center rounded-full">
                            <Typography color="textSecondary">
                              {index + 1}
                            </Typography>
                          </span>
                          <Typography>
                            <b>{section.title}</b>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          dangerouslySetInnerHTML={{ __html: section.text }}
                        />
                      </Grid>
                      {section.image && (
                        <Grid item xs={12}>
                          <Box
                            border={2}
                            borderRadius="borderRadius"
                            borderColor="grey.500"
                            style={{
                              overflow: 'hidden',
                              width: '40%',
                              marginInline: '30% 30%',
                              writingMode: 'horizontal-tb',
                            }}
                          >
                            <img
                              src={section.image}
                              alt="img"
                              style={{
                                width: '100%',
                              }}
                            />
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Container>
  ) : (
    <CircularProgress />
  );
}

export default Help;
