import { useLogin } from '@/lib/auth';
import { z } from 'zod';
import { useZodForm } from '../../utils/form';
import { CustomDialog } from '../common/Dialog';

type AddAccountDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function AddAccountDialog({
  isOpen,
  onClose,
}: AddAccountDialogProps) {
  const methods = useZodForm({
    schema: z.object({
      username: z.string(),
      password: z.string(),
      rememberMe: z.boolean(),
    }),
  });

  const login = useLogin();

  return (
    <CustomDialog isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col p-4">
        <form
          className="flex flex-col gap-3"
          onSubmit={methods.handleSubmit(async (data) => {
            await login.mutateAsync(data);
            methods.reset();
            onClose();
          })}
        >
          <div className="flex flex-col">
            <input
              id="d.username"
              type="text"
              className="input-bordered input"
              placeholder="User Name"
              {...methods.register('username')}
            />
          </div>
          <div className="flex flex-col">
            <input
              id="d.password"
              type="password"
              className="input-bordered input"
              placeholder="Password"
              {...methods.register('password')}
            />
          </div>
          <label className="flex justify-between">
            <span>Remember me</span>
            <input
              id="d.checkbox"
              type="checkbox"
              className="checkbox-primary checkbox outline outline-1"
              {...methods.register('rememberMe')}
            />
          </label>
          <div className="flex flex-col">
            <button
              id="d.submit"
              type="submit"
              className="btn-secondary btn-sm btn"
            >
              Add Profile
            </button>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
}
