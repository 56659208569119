import { CircularProgress } from '@/components/Elements/circularprogress';
import { useUpdateMerchant } from '@/features/user/merchant/updateMerchant';
import { useUser } from '@/lib/auth';
import { useZodForm } from '@/utils/form';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { z } from 'zod';

function Tips() {
  const user = useUser();
  const methods = useZodForm({
    schema: z
      .object({
        offer: z.boolean(),
        tip1: z.coerce.number().int().min(0).max(100).nullable(),
        tip2: z.coerce.number().int().min(0).max(100).nullable(),
        tip3: z.coerce.number().int().min(0).max(100).nullable(),
      })
      .superRefine(({ offer, tip1, tip2, tip3 }, ctx) => {
        if (!offer) return;

        if (!tip1 && !tip2 && !tip3) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'You must offer at least one tip.',
            path: ['offer'],
          });
        }
      }),
  });
  const updateMerchantMutation = useUpdateMerchant({
    config: {
      onSuccess: () => user.refetch(),
    },
  });

  useEffect(() => {
    if (!user.isSuccess) return;
    reset();
  }, [user.data]);

  if (user.isLoading || !user.data) return <CircularProgress />;

  const reset = () => {
    if (!user.data) return;

    methods.reset({
      offer: user.data.user.tips!.ask ?? false, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      tip1: user.data.user.tips!.percents?.[0] ?? null, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      tip2: user.data.user.tips!.percents?.[1] ?? null, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      tip3: user.data.user.tips!.percents?.[2] ?? null, // eslint-disable-line @typescript-eslint/no-non-null-assertion
    });
  };

  return (
    <div>
      <span>
        You can set up your POS so that when a customer scans a bar code it will
        offer them the opportunity to add a tip. If you want to offer this to
        your customers, check "offer tips". Leave a tip blank to remove it.
      </span>
      <form
        onSubmit={methods.handleSubmit(({ offer, tip1, tip2, tip3 }) => {
          toast.promise(
            updateMerchantMutation.mutateAsync({
              tips: offer
                ? ([tip1, tip2, tip3].filter((x) => !!x) as number[])
                : [],
            }),
            {
              loading: 'Saving...',
              success: 'Tips updated.',
              error: 'Failed to update tips.',
            }
          );
        }, console.error)}
        className="mt-4 flex flex-col gap-4"
      >
        <div className="flex items-center justify-between gap-2">
          <div>
            <label className="font-bold">Offer Tips</label>
            <br />
            <span className="label-text">
              If you want to offer tips to your customers, check this box.
            </span>
          </div>
          <div className="flex flex-col items-end justify-end gap-1">
            <input
              type="checkbox"
              className="checkbox-primary checkbox outline outline-1"
              {...methods.register('offer')}
            />
          </div>
        </div>
        <div className="text-xs font-bold text-red-700">
          {methods.formState.errors.offer?.message}
        </div>

        <div>
          <div>
            <label className="font-bold">Tips</label>
            <br />
            <span className="label-text">
              You can offer up to three tip amounts. Leave a tip blank to remove
              it.
            </span>
          </div>
          <div className="mt-2 flex flex-col gap-2">
            <div className="w-full">
              <input
                type="number"
                className="input-bordered input input-sm w-full"
                disabled={!methods.getValues('offer')}
                {...methods.register('tip1')}
              />
              <div className="text-xs font-bold text-red-700">
                {methods.formState.errors.tip1?.message}
              </div>
            </div>
            <div className="w-full">
              <input
                type="number"
                className="input-bordered input input-sm w-full"
                disabled={!methods.getValues('offer')}
                {...methods.register('tip2')}
              />
              <div className="text-xs font-bold text-red-700">
                {methods.formState.errors.tip2?.message}
              </div>
            </div>
            <div className="w-full">
              <input
                type="number"
                className="input-bordered input input-sm w-full"
                disabled={!methods.getValues('offer')}
                {...methods.register('tip3')}
              />
              <div className="text-xs font-bold text-red-700">
                {methods.formState.errors.tip3?.message}
              </div>
            </div>
          </div>
        </div>

        <div className="inline-flex gap-4">
          <button
            className="btn-outline btn-sm btn"
            disabled={!methods.formState.isDirty}
            onClick={() => reset()}
          >
            Reset
          </button>
          <button
            className="btn-success btn-sm btn"
            disabled={!methods.formState.isDirty}
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default Tips;
