import { useTOS } from '@/features/legal/getTOS';
import { getUsernameCheck } from '@/features/user/getUsernameCheck';
import { useRegisterUser } from '@/features/user/registerUser';
import { useZodForm } from '@/utils/form';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FaCheckCircle, FaCircleNotch, FaTimesCircle } from 'react-icons/fa';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { z } from 'zod';
import { CustomDialog } from '../common/Dialog';

////////////////////////////////////////////////////////////////
// tell them what to do next.  (How can I inline this into EmailVerificationDialog?)
function getMsg(sendMsg, email) {
  if (sendMsg) {
    return (
      <div className="text-sm">
        We&apos;ve sent a verification link to{' '}
        <span className="font-bold">{email}</span>.
      </div>
    );
  } else {
    return <div className="text-sm">You are registered and ready to login</div>;
  }
}

////////////////////////////////////////////////////////////////
// Dialog after confirmation of account creation.
// Will either tell them they should verify, or they are ready to go depending on backend response
type EmailVerificationProps = {
  isOpen: boolean;
  onClose: () => void;
  sendMsg: boolean; // true if they will need to verify their email
  email: string; // email they will get verification on
};
function EmailVerificationDialog({
  isOpen,
  onClose,
  sendMsg,
  email,
}: EmailVerificationProps) {
  return (
    <CustomDialog isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-col gap-4">
        <div className="text-xl font-bold">
          {sendMsg ? 'Verify your email' : 'Registered'}
        </div>
        {getMsg(sendMsg, email)}
        <div className="flex w-full justify-end">
          <Link to="/login" className="btn-primary btn-sm btn">
            Back to Login
          </Link>
        </div>
      </div>
    </CustomDialog>
  );
}

////////////////////////////////////////////////////////////////
// Dialog to show terms and conditions.  Will submit registration once they agree
type TermsProps = {
  isOpen: boolean;
  onClose: () => void;
  setVerify: (val: boolean) => void;
  methods: ReturnType<typeof useZodForm>;
};
function TermsDialog({
  isOpen,
  onClose,
  setVerify,
  setWillsend,
  methods,
}: TermsProps) {
  const tos = useTOS();
  const [scrolled, setScrolled] = useState(false);

  const registerUserMutation = useRegisterUser({
    config: {
      onSuccess: (data) => {
        //console.log(`on success ${data}`);
        //console.log(data);
        // indicate whether verification of email is still needed
        setWillsend(data.data.needVerify);
        toast.success('Account created successfully');
        // open up dialog telling them what happened on registration
        setVerify(true);
      },
      onError: (err) => {
        toast.error(err.message);
      },
    },
  });

  if (!tos.isSuccess) return null;

  return (
    <CustomDialog isOpen={isOpen} onClose={onClose}>
      <div
        id="r.terms"
        className="h-full max-h-[36rem] overflow-y-scroll p-2"
        onScroll={(e) => {
          const target = e.target as HTMLDivElement;
          if (target.scrollHeight - target.scrollTop === target.clientHeight)
            setScrolled(true);
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: tos.data }} />
      </div>
      <div className="mt-8 flex items-center justify-between">
        <Link
          className="link-primary link text-xs"
          target="_blank"
          to="/privacy-policy.html"
        >
          Privacy Policy
        </Link>
        <div className="flex gap-4">
          <button className="btn-outline btn-sm btn" onClick={onClose}>
            Deny
          </button>
          <button
            type="submit"
            className="btn-primary btn-sm btn"
            disabled={!scrolled}
            onClick={() => {
              onClose();
              registerUserMutation.mutate({
                ...methods.getValues(),
                phone: methods.getValues().phone.length
                  ? methods.getValues('phone')
                  : undefined,
              });
            }}
          >
            Agree
          </button>
        </div>
      </div>
    </CustomDialog>
  );
}

const signupSchema = z.object({
  email: z.string().email('Invalid email').nonempty('Email is required'),
  username: z
    .string()
    .min(4, 'Username must be at least 4 characters long')
    .regex(/^[a-zA-Z0-9-]+$/, 'Invalid username format')
    .nonempty('Username is required'),
  firstName: z.string().nonempty('First name is required'),
  lastName: z.string(),
  phone: z.string(),
  password: z
    .string()
    .min(4, 'Password must be at least 4 characters long')
    .nonempty('Password is required')
    .regex(/[A-Z]/, 'Password must contain at least one capital letter'),
  confirmPassword: z.string().nonempty('Confirm password is required'),
});

type RegisterFormProps = {
  isMerchant?: boolean;
};
function RegisterForm({ isMerchant }: RegisterFormProps) {
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line @typescript-eslint/no-unused-vars

  const vmail = searchParams.get('vmail');

  const methods = useZodForm({
    schema: signupSchema
      .extend({
        businessName: isMerchant
          ? z.string().nonempty('Business name is required')
          : z.string().optional(),
      })
      .superRefine(async (data, ctx) => {
        if (data.password !== data.confirmPassword) {
          ctx.addIssue({
            code: 'custom',
            message: 'Passwords do not match',
            path: ['confirmPassword'],
          });
        }

        if (!data.username) return;

        const methodsUser = await getUsernameCheck({ username: data.username });

        if (!methodsUser.username.available) {
          ctx.addIssue({
            code: 'custom',
            message: 'Username must be unique',
            path: ['username'],
          });
        }
      }),
    defaultValues: {
      email: vmail ? window.atob(vmail) : '',
    },
  });

  const [verify, setVerify] = useState(false);
  const [agree, setAgree] = useState(false);
  const [willsend, setWillsend] = useState(true);

  return (
    <>
      <EmailVerificationDialog
        isOpen={verify}
        onClose={() => setVerify(false)}
        sendMsg={willsend}
        email={methods.watch('email')}
      />
      <TermsDialog
        isOpen={agree}
        onClose={() => setAgree(false)}
        setVerify={setVerify}
        setWillsend={setWillsend}
        methods={methods}
      />
      <form
        className="flex w-full flex-col gap-4"
        onSubmit={methods.handleSubmit(() => {
          setAgree(true);
        }, console.error)}
      >
        <div>
          <input
            className="input-bordered input w-full"
            placeholder="Email*"
            {...methods.register('email')}
          />
          <span className="text-xs italic text-error">
            {methods.formState.errors.email?.message}
          </span>
        </div>
        <div>
          <div className="relative">
            <input
              className="input-bordered input w-full"
              placeholder="Username*"
              {...methods.register('username')}
            />
            <div className="absolute right-3 top-1/2 h-4 w-4 -translate-y-1/2">
              {methods.formState.isValidating ? (
                <FaCircleNotch className="animate-spin" />
              ) : methods.formState.errors.username ? (
                <FaTimesCircle />
              ) : methods.formState.dirtyFields.username ? (
                <FaCheckCircle />
              ) : null}
            </div>
          </div>
          <span className="text-xs italic text-error">
            {methods.formState.errors.username?.message}
          </span>
        </div>
        <div>
          <input
            className="input-bordered input w-full"
            placeholder="First Name*"
            {...methods.register('firstName')}
          />
          <span className="text-xs italic text-error">
            {methods.formState.errors.firstName?.message}
          </span>
        </div>
        <div>
          <input
            className="input-bordered input w-full"
            placeholder="Last Name"
            {...methods.register('lastName')}
          />
          <span className="text-xs italic text-error">
            {methods.formState.errors.lastName?.message}
          </span>
        </div>
        {isMerchant && (
          <div>
            <input
              className="input-bordered input w-full"
              placeholder="Business Name*"
              {...methods.register('businessName')}
            />
            <span className="text-xs italic text-error">
              {methods.formState.errors.businessName?.message}
            </span>
          </div>
        )}
        <div>
          <input
            className="input-bordered input w-full"
            placeholder="Phone"
            {...methods.register('phone')}
          />
          <span className="text-xs italic text-error">
            {methods.formState.errors.phone?.message}
          </span>
        </div>
        <div>
          <input
            className="input-bordered input w-full"
            placeholder="Password*"
            type="password"
            {...methods.register('password')}
          />
          <span className="text-xs italic text-error">
            {methods.formState.errors.password?.message}
          </span>
        </div>
        <div>
          <input
            className="input-bordered input w-full"
            placeholder="Confirm Password*"
            type="password"
            {...methods.register('confirmPassword')}
          />
          <span className="text-xs italic text-error">
            {methods.formState.errors.confirmPassword?.message}
          </span>
        </div>
        <button className="btn-primary btn">Sign up</button>
      </form>
    </>
  );
}

function Register() {
  const location = useLocation();

  const navigate = useNavigate();

  const validPaths = ['/register/customer', '/register/merchant'];

  useEffect(() => {
    if (!validPaths.includes(location.pathname)) {
      navigate('/register/customer' + location.search);
    }
  }, []);

  return (
    <div className="mx-auto flex w-full max-w-md flex-col items-center gap-8">
      <div className="text-6xl font-extrabold leading-relaxed tracking-widest">
        ZUZ
      </div>
      <div className="btn-group w-full px-20">
        <Link
          className={clsx(
            'btn-ghost no-animation btn-sm btn flex-1',
            location.pathname === '/register/customer' && 'btn-active'
          )}
          to="/register/customer"
        >
          CUSTOMER
        </Link>
        <Link
          className={clsx(
            'btn-ghost no-animation btn-sm btn flex-1',
            location.pathname === '/register/merchant' && 'btn-active'
          )}
          to="/register/merchant"
        >
          MERCHANT
        </Link>
      </div>
      <div className="w-full">
        <RegisterForm isMerchant={location.pathname === '/register/merchant'} />
      </div>
      <div className="divider my-0" />
      <span className="text-sm">
        Already have an account?{' '}
        <Link className="link-hover link-primary link" to="/login">
          Log in
        </Link>
      </span>
    </div>
  );
}

export default Register;
