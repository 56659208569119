import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import React, { Fragment } from 'react';

// Popovers are perfect for floating panels with arbitrary
// content like navigation menus, mobile menus and flyout menus.

type Props = {
  button: React.ReactNode; // button that will open the popover
  content: (close: () => void) => React.ReactNode; // content that will be displayed in the popover
  origin: 'right' | 'left'; // where the popover's animation will originate from
};
export default function MyPopover({ button, content, origin }: Props) {
  return (
    <Popover className="relative">
      {({ close }) => (
        <>
          <Popover.Button as="div">{button}</Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={clsx(
                'absolute z-10 mt-2 w-max rounded-md bg-white py-2 px-4 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none',
                origin === 'right'
                  ? 'right-0 origin-top-left'
                  : 'left-0 origin-top-right'
              )}
            >
              {content(close)}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
