import { CircularProgress } from '@/components/Elements/circularprogress';
import { axios } from '@/lib/axios';
import { Box, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

function PointOfSale() {
  const [token, setToken] = useState<{ status: string; authlink: string }>();
  const [status, setStatus] = useState<{ posinfo: any[]; status: string }>();

  useEffect(() => {
    axios
      .get<{ status: string; authlink: string }>('/pos/sqr-get-token')
      .then((res) => {
        console.log(res);
        setToken(res.data);
      });

    axios
      .get<{ posinfo: any[]; status: string }>('/pos/pos-status')
      .then((res) => {
        console.log(res);
        setStatus(res.data);
      });
  }, []);

  return (
    <Box maxWidth="lg" style={{ marginTop: '40px' }}>
      <div>
        {status ? (
          status.posinfo.length > 0 ? (
            <Typography>Point of Sale approved with Square.</Typography>
          ) : (
            <Typography>
              Authorize <Link href={token?.authlink}>Square</Link>.
            </Typography>
          )
        ) : (
          <CircularProgress />
        )}
      </div>
    </Box>
  );
}

export default PointOfSale;
