import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from '@tanstack/react-query';

export type registerUserProps = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  role: string;
  username: string;
  phone?: string;
  businessName?: string;
};

export const registerUser = async (
  data: registerUserProps
): Promise<{
  status: string;
  needVerify: boolean;
}> => axios.post(`/user`, data);

type useRegisterUserOptions = {
  config?: MutationConfig<typeof registerUser>;
};

export const useRegisterUser = ({ config }: useRegisterUserOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: registerUser,
  });
};
