import { clsx } from 'clsx';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
  className?: string;
};

export function LinkButton({ to, className, children }: Props) {
  return (
    <button className={clsx('btn-secondary btn-sm btn', className)}>
      <Link to={to}>{children}</Link>
    </button>
  );
}
