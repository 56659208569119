import { create } from 'zustand';

type Dialog = 'QrScanner';

type DialogStore = {
  dialog: Dialog | null;
  setDialog: (dialog: Dialog) => void;
  clearDialog: () => void;
};

export const useDialogStore = create<DialogStore>((set) => ({
  dialog: null,
  setDialog: (dialog) => set(() => ({ dialog })),
  clearDialog: () => set(() => ({ dialog: null })),
}));
