// Round an amount to the nearest penny and format (currently for US)
export function asCurrencyString(amt: number): string {
  //const val = Math.round( ( amt + Number.EPSILON ) * 100 ) / 100;
  return amt.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

// there is probably a better way to do this, but I want to make sure
// the number part of the string returned by asCurrencyString are the
// same.  Not even sure this will do that given the inability to deal
// exactly with conversion between binary and hundereds.  We should
// probably be doing all this with a fixed point package.
export function rounded2pennies(amt: number): number {
  const asstr = amt.toLocaleString('en-US', {
    style: 'decimal',
    useGrouping: false,
    notation: 'standard',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return parseFloat(asstr);
}
