import { useGiveFeedback } from '@/features/general/giveFeedback';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { useZodForm } from '../../utils/form';

export const Feedback = () => {
  // Using the useZodForm hook and defining the schema of the user inputs
  const methods = useZodForm({
    schema: z.object({
      type: z.string().nonempty('Feedback type is required.'),
      msg: z
        .string()
        .nonempty('Feedback text is required.')
        .min(20, 'Feedback text must be at least 20 characters long.'),
      canContact: z.boolean(),
    }),
  });

  const giveFeedback = useGiveFeedback();

  return (
    <>
      <form
        onSubmit={methods.handleSubmit(async (values) => {
          await giveFeedback.mutateAsync(values);
          toast.success('Feedback submitted successfully!');
          methods.reset();
        })}
      >
        <div className="flex items-center justify-center">
          <div className="w-full max-w-lg">
            <div className="rounded-lg bg-white p-6 shadow-md">
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className="text-3xl font-bold">Feedback</label>
                  <p className="text-base">
                    We welcome your feedback and suggestions!
                  </p>
                </div>
                <div>
                  <label className="block font-medium">Type:</label>
                  <select
                    className="select-bordered select select-md w-full"
                    {...methods.register('type')}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="Report a Bug">Report a Bug</option>
                    <option value="Report a Problem">Report a Problem</option>
                    <option value="Enter a Suggestion">
                      Enter a Suggestion
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div>
                  <label className="block font-medium">
                    Describe your feedback here!
                  </label>
                  <textarea
                    className="textarea-bordered textarea textarea-md w-full"
                    rows={3}
                    {...methods.register('msg')}
                  ></textarea>
                  {methods.formState.errors.msg && (
                    <p className="text-red-500">
                      {methods.formState.errors.msg?.message}
                    </p>
                  )}
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="checkbox-primary checkbox outline outline-1"
                    {...methods.register('canContact')}
                  />
                  <label className="ml-2">Can we contact you about this?</label>
                </div>
                <div>
                  <button type="submit" className="btn-primary btn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Feedback;
