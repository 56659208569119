import { useMutation } from '@tanstack/react-query';
import { axios } from '../../../lib/axios';
import { MutationConfig } from '../../../lib/react-query';

export const updateMerchant = async ({ tips }: { tips: number[] }) =>
  axios.post(`/user/merchant`, { tips });

type useUpdateMerchantOptions = {
  config?: MutationConfig<typeof updateMerchant>;
};

export const useUpdateMerchant = ({
  config,
}: useUpdateMerchantOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: updateMerchant,
  });
};
