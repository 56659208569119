import { createTheme, ThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';

declare module '@mui/material' {
  interface Theme {
    palette: {
      primary: {
        main: string;
        dark: string;
        contrastText: string;
      };
      secondary: {
        main: string;
        light: string;
        contrastText: string;
      };
      error: {
        main: string;
      };
    };
    typography: {
      fontFamily: string;
      h1: {
        fontFamily: string;
        fontWeight: number;
      };
      h2: {
        fontFamily: string;
        fontWeight: number;
      };
      h3: {
        fontFamily: string;
        fontWeight: number;
      };
      h4: {
        fontFamily: string;
        fontWeight: number;
      };
      h5: {
        fontFamily: string;

        fontWeight: number;
      };
      h6: {
        fontFamily: string;
        fontWeight: number;
      };
    };
  }
}

const headerFont = "'Montserrat', sans-serif";
const font = "'Roboto', sans-serif";

const theme = createTheme({
  palette: {
    primary: {
      main: '#0071BC',
      dark: '#2E3192',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00a99D',
      light: '#00FFFF',
      contrastText: '#000000',
    },
    error: {
      main: '#EF5350',
    },
  },
  typography: {
    fontFamily: font,
    h1: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h2: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h3: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h4: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h5: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
    h6: {
      fontFamily: headerFont,
      fontWeight: 600,
    },
  },
});

const CustomThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
