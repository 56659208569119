import { logerrBackend } from '../../utils/logger';
import { DRYTXNDATA, IUSERBOLTDATA, TXNSTATUS } from '../../utils/types';
import {
  AccountDataActionsTypes,
  AuthActionsTypes,
  BoltActionsTypes,
  TxnActionsTypes,
} from '../actions/actionTypes';

export interface BoltState {
  [key: string]: IUSERBOLTDATA;
}

const initialState: BoltState = {};

function boltReducer(state: BoltState = initialState, action: any) {
  //console.log(`boltreducer: ${action.type}`); //JSON.stringify(action)}`);
  switch (action.type) {
    //Reducer for the get user bolts action
    case BoltActionsTypes.GET_USER_BOLTS:
      return action.payload.bolts
        .filter((bolt: IUSERBOLTDATA) => {
          return bolt.amount >= 0.01;
        })
        .reduce((bolts: BoltState, bolt: IUSERBOLTDATA) => {
          bolts[bolt.bolt.specID] = bolt;
          return bolts;
        }, state);

    //Reducer for the create bolt action
    case BoltActionsTypes.CREATE_BOLT: {
      let newBolt;
      if (action.payload.bolt?.version == 1) {
        // we have a new kind of bolt being created
        newBolt = {
          amount: 0,
          bolt: {
            ...action.payload.bolt,
            issueDate: new Date().toISOString(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        };
      } else {
        // old style hyperledger bolt being created
        //Formatting the maturity date
        const maturity = new Date(0);
        maturity.setUTCMilliseconds(action.payload.bolt.maturity);
        newBolt = {
          amount: 0,
          bolt: {
            ...action.payload.bolt,
            issueDate: new Date().toISOString(),
            interest: {
              ...action.payload.bolt.interest,
              start: action.payload.bolt.interest.start,
            },
            maturity: maturity.toISOString(),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          },
        };
      }
      console.log('inserting new bolt into state');
      console.log(newBolt.bolt.specID);
      console.log(newBolt);
      return {
        ...state,
        [newBolt.bolt.specID]: newBolt,
      };
    }
    //Reducer for mint bolt action
    case BoltActionsTypes.MINT_BOLT:
      const mintBolt = action.payload.bolt;
      return {
        ...state,
        [mintBolt.bolt.specID]: {
          ...mintBolt,
          amount: mintBolt.amount,
        },
      };
    //Reducer for donate bolt action
    case BoltActionsTypes.DONATE_BOLT:
      const donatedBoltSpecID = action.payload.specID;
      const newAmt = action.payload.newAmt;
      return {
        ...state,
        [donatedBoltSpecID]: {
          ...state[donatedBoltSpecID],
          amount: newAmt,
        },
      };
    // Reducer to load saved account state
    case AuthActionsTypes.SWITCH:
      if (action.payload.userBolts) {
        return action.payload.userBolts;
      }
      return state;
    // Reducer to clear active bolt state
    case AccountDataActionsTypes.CLEAR_ACTIVE:
      return {};

    // Reducers for handling amount changes based on transactions
    // in case the user is notified that an outgoing transaction has been
    // accepted
    case TxnActionsTypes.UPDATE_TXN: {
      const updateTxn: DRYTXNDATA = action.payload.transaction;
      const bid = updateTxn.bolt;
      const aold = state[bid].amount;
      const anew = updateTxn.amount;
      console.log(`updating txn: ${aold} + ${anew}`);
      if (updateTxn.status === TXNSTATUS.CONFIRMED) {
        return {
          ...state,
          [updateTxn.bolt]: {
            ...state[updateTxn.bolt],
            amount: state[updateTxn.bolt].amount - updateTxn.amount,
          },
        };
      }
      return state;
    }
    // in case the current user accepts an incoming transaction
    case TxnActionsTypes.ACCEPT_REJECT_TXN: {
      const finalizedTxn: DRYTXNDATA = action.payload.transaction;
      const bid = finalizedTxn.bolt;
      const aold = state[bid].amount;
      const anew = action.payload.firstTime ? 0 : finalizedTxn.amount;
      console.log(
        `accepting txn: ${bid}: ${aold} + ${
          action.payload.firstTime ? -1 : anew
        }`
      );
      if (action.payload.firstTime) {
        // this is a finalized transaction that was already
        // in the store because of the timing of hydration,
        // so we just return state.  To confirm this, we
        // check some things and log the error if I am
        // wrong.
        if (finalizedTxn.status !== TXNSTATUS.CONFIRMED)
          logerrBackend(
            `boltreduction, ART, 1st, but not finalized: ${JSON.stringify(
              action.payload
            )}`,
            false
          );
        if (!(bid in state))
          logerrBackend(
            `boltreduction, ART, 1st, but bid not found: ${JSON.stringify(
              action.payload
            )}`,
            false
          );
        if (finalizedTxn.amount != aold)
          logerrBackend(
            `boltreduction, ART, 1st, but not same amount: ${JSON.stringify(
              action.payload
            )}`,
            false
          );
        return state;
      }
      if (finalizedTxn.status === TXNSTATUS.CONFIRMED) {
        return {
          ...state,
          [finalizedTxn.bolt]: {
            ...state[finalizedTxn.bolt],
            amount: state[finalizedTxn.bolt].amount + finalizedTxn.amount,
          },
        };
      }
      return state;
    }
    default:
      return state;
  }
}

export default boltReducer;
