import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';
import { useMutation } from '@tanstack/react-query';

export const mintBolt = async ({
  specId,
  amount,
  email,
}: {
  specId: string;
  amount: number;
  email?: string;
}) =>
  axios
    .post('/bolt/mint', {
      specId,
      amount,
      email,
    })
    .then(({ data }) => data);

type UseMintBoltOptions = {
  config?: MutationConfig<typeof mintBolt>;
};

export const useMintBolt = ({ config }: UseMintBoltOptions) => {
  return useMutation({
    ...config,
    mutationFn: mintBolt,
  });
};
