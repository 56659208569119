import {
  QueryClient,
  type DefaultOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { PromiseValue } from 'type-fest';

// This is the default configuration for all queries. It is used to configure the query cache and the query client.
const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 1000 * 60 * 10, // 10 minutes
  },
};

// Used to interact with a query cache. This is the main entry point for all query related functionality. The QueryClient provides methods to create and configure queries, mutate the cache, and prefetch data.
export const queryClient = new QueryClient({ defaultOptions: queryConfig });

// This is a helper type to extract the return type of a function. It is used to extract the type of the data that is returned from a fetcher function.
export type ExtractFnReturnType<FnType extends (...args: any) => any> =
  PromiseValue<ReturnType<FnType>>;

// This is a helper type that provides the configuration for a query. It takes a generic fetcher function type and extracts the return type of the function.
export type QueryConfig<FetcherFnType extends (...args: any) => any> =
  UseQueryOptions<PromiseValue<ReturnType<FetcherFnType>>>;

// This is a helper type that provides the configuration for a mutation. It takes a generic fetcher function type and extracts the return type of the function.
export type MutationConfig<FetcherFnType extends (...args: any) => any> =
  UseMutationOptions<
    PromiseValue<ReturnType<FetcherFnType>>,
    AxiosError,
    Parameters<FetcherFnType>[0]
  >;
