import { useUser } from '@/features/user/getUser';
import {
  switchAccounts,
  useLogout,
  useUser as useActiveUser,
} from '@/lib/auth';
import { queryClient } from '@/lib/react-query';
import { useDialogStore } from '@/stores/DialogStore';
import storage from '@/utils/storage';
import { Menu } from '@headlessui/react';
import {
  FaCashRegister,
  FaCommentAlt,
  FaMoneyCheckAlt,
  FaQrcode,
  FaSignOutAlt,
  FaStore,
  FaUserAlt,
  FaWallet,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReturnAvatar from './common/ReturnAvatar';
import MyMenu from './Elements/MyMenu';
import MyPopover from './Elements/MyPopover';

function Account({ id }: { id: string }) {
  const user = useUser({ userId: id });
  const logout = useLogout(id);

  return (
    <div
      className="group flex cursor-pointer items-center gap-2 p-4 hover:bg-base-200"
      onClick={() => {
        switchAccounts(id);
        queryClient.invalidateQueries();
      }}
    >
      <div className="h-10 w-10">
        <ReturnAvatar userId={id} />
      </div>
      <div className="flex-1">
        <div className="text-xs">{user.data?.user.username}</div>
        <div className="text-xs opacity-70">{user.data?.user.email}</div>
      </div>
      <div>
        <span
          className="btn-ghost btn-xs btn group-hover:btn-outline"
          onClick={async (e) => {
            e.stopPropagation();
            logout.mutateAsync({});
          }}
        >
          <FaSignOutAlt />
        </span>
      </div>
    </div>
  );
}

// The layout component wraps around all the pages and contains the header and footer
// Conditional rendering based on user authentication and role is also handled here
function Layout({ children }: { children: React.ReactNode }) {
  const user = useActiveUser();
  const token = storage.getToken();
  const { setDialog } = useDialogStore();
  const logout = useLogout();

  const merchant = !!user.data?.user.businessName;

  return (
    <>
      <div className="fixed top-0 left-0 z-50 flex h-16 w-full items-center justify-between bg-gradient-to-l from-primary to-secondary px-8 shadow-lg">
        <MyMenu
          origin="left"
          button={
            <img
              src="/images/zuz_logotype_white.svg"
              className="h-8 cursor-pointer align-middle"
            />
          }
          items={
            <>
              <Menu.Item
                as={Link}
                to="/about"
                className="block px-4 py-2 text-base-content no-underline hover:bg-base-200"
              >
                About
              </Menu.Item>
              <Menu.Item
                as={Link}
                to="/help"
                className="block px-4 py-2 text-base-content no-underline hover:bg-base-200"
              >
                Help
              </Menu.Item>
            </>
          }
        />

        {/* Navigation links appear on desktop */}
        <div className="hidden items-center justify-center gap-8 font-display font-bold capitalize text-white md:flex">
          <Link to="/wallet" className="link-hover text-inherit">
            Wallet
          </Link>
          <Link to="/marketplace" className="link-hover text-inherit">
            Marketplace
          </Link>
          {merchant && (
            <Link to="/pos" className="link-hover text-inherit">
              Point-Of-Sale
            </Link>
          )}
          {user.data && (
            <button
              className="btn-ghost btn-circle btn text-xl"
              onClick={() => setDialog('QrScanner')}
            >
              <FaQrcode />
            </button>
          )}
        </div>

        {/* If there is no user, show login button */}
        {!user.data && (
          <Link to="/login" className="btn-ghost btn text-white">
            Login
          </Link>
        )}

        {/* If there is a user, show user avatar with a navigation popover (menu) */}
        {!!user.data && !!user.data.user && (
          <MyPopover
            button={
              <button id="layout.rdd" className="btn-ghost btn-circle btn">
                <ReturnAvatar userId={user.data.user.id.toString()} />
              </button>
            }
            origin="right"
            content={(close) => (
              <>
                <div className="flex items-center gap-4 p-4">
                  <div className="h-16 w-16">
                    <ReturnAvatar
                      userId={user.data?.user.id.toString() ?? ''}
                    />
                  </div>
                  <div>
                    <div className="text-sm">{user.data?.user.username}</div>
                    <div className="text-xs opacity-70">
                      {user.data?.user.email}
                    </div>
                  </div>
                </div>
                <Link
                  to="/settings"
                  onClick={close}
                  className="text-inherit no-underline"
                >
                  <span className="btn-ghost btn-xs btn w-full">Settings</span>
                </Link>
                <div className="divider my-2" />
                <div id="layout.outlist">
                  {token?.accounts.map((account) => (
                    <div key={account.id} onClick={() => close()}>
                      <Account id={account.id} />
                    </div>
                  ))}
                </div>
                <Link
                  to="/profile"
                  onClick={close}
                  className="flex cursor-pointer items-center gap-4 px-4 py-2 text-base-content no-underline hover:bg-base-200"
                >
                  <FaMoneyCheckAlt />
                  Transactions
                </Link>
                <Link
                  to="/settings/manage-accounts"
                  onClick={close}
                  className="flex cursor-pointer items-center gap-4 px-4 py-2 text-base-content no-underline hover:bg-base-200"
                >
                  <FaUserAlt />
                  Add an account
                </Link>
                <Link
                  to="/feedback"
                  onClick={close}
                  className="flex cursor-pointer items-center gap-4 px-4 py-2 text-base-content no-underline hover:bg-base-200"
                >
                  <FaCommentAlt />
                  Feedback
                </Link>
                <div
                  id="layout.outall"
                  onClick={async () => {
                    logout.mutateAsync({});
                    close();
                  }}
                  className="flex cursor-pointer items-center gap-4 px-4 py-2 text-base-content no-underline hover:bg-base-200"
                >
                  <FaSignOutAlt />
                  Logout of all accounts
                </div>
              </>
            )}
          />
        )}
      </div>

      <div className="container mx-auto my-16 max-w-4xl p-4">{children}</div>

      {/* Bottom navigation renders on mobile only */}
      <div className="fixed bottom-0 z-50 flex h-16 w-full items-center justify-around bg-gradient-to-l from-primary to-secondary text-white md:hidden">
        <Link to="/wallet" className="btn-ghost btn-circle btn text-inherit">
          <FaWallet className="text-2xl" />
        </Link>
        <Link
          to="/marketplace"
          className="btn-ghost btn-circle btn text-inherit"
        >
          <FaStore className="text-2xl" />
        </Link>
        {merchant && (
          <Link className="btn-ghost btn-circle btn" to="/pos">
            <FaCashRegister className="text-2xl" />
          </Link>
        )}
        {user.data && (
          <button
            className="btn-ghost btn-circle btn"
            onClick={() => setDialog('QrScanner')}
          >
            <FaQrcode className="text-2xl" />
          </button>
        )}
      </div>
    </>
  );
}

export default Layout;
