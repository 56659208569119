import { useUser } from '@/lib/auth';
import { FaCircleNotch } from 'react-icons/fa';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import About from '../components/About';
import Help from '../components/help';
import Layout from '../components/Layout';
import Issuer from '../components/marketplace/Issuer';
import { MarketPlace } from '../components/marketplace/MarketPlace';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const commonRoutes: RouteObject[] = [
    { path: '/about', element: <About /> },
    { path: '/help/:device/:user/:article', element: <Help /> },
    { path: '/help/:article', element: <Help /> },
    { path: '/help/*', element: <Help /> },
    { path: '/marketplace', element: <MarketPlace /> },
    { path: '/vendor/:issuerId', element: <Issuer /> },
    { path: '/', element: <Navigate to="/wallet" /> },
    { path: '*', element: <>404</> },
  ];

  const user = useUser();

  // Only use public routes if user is not logged in
  const element = useRoutes([
    ...commonRoutes,
    ...(!!user.data ? [] : publicRoutes),
    ...protectedRoutes,
  ]);

  if (user.isLoading)
    return (
      <Layout>
        <div className="grid h-screen w-full place-items-center">
          <FaCircleNotch className="animate-spin text-4xl" />
        </div>
      </Layout>
    );

  return <Layout>{element}</Layout>;
};
