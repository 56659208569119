import { FaCircleNotch } from 'react-icons/fa';
import { useUser } from '../../features/user/getUser';
import { getImageURL } from '../../services/api';

// This component returns a user's avatar.
// If the user has a profile picture, it will
// return the image. If not, it will return the user's initials.
type Props = {
  userId: string;
};
export default function ReturnAvatar({ userId }: Props) {
  const { data, status } = useUser({ userId });
  if (status === 'loading') return <FaCircleNotch className="animate-spin" />;
  if (status === 'error') return <div>Error fetching user</div>;
  const { profilePic } = data.user;
  if (profilePic) {
    let profileObj: any = null;
    if (typeof profilePic === 'string') {
      profileObj = getImageURL(profilePic);
    } else {
      profileObj = URL.createObjectURL(profilePic);
    }
    return <img src={profileObj} className="h-full rounded-full" />;
  }

  return (
    <div className="flex h-full w-full items-center justify-center rounded-full bg-primary p-2 font-bold text-primary-content">
      {data.user?.firstName[0]}.{data.user?.lastName?.[0]}.
    </div>
  );
}
