import { RouteObject } from 'react-router-dom';
import Confirmation from '../components/auth/Confirmation';
import ForgotPassword from '../components/auth/ForgotPassword';
import Login from '../components/auth/Login';
import Register from '../components/auth/Register';
import ResetPassword from '../components/auth/ResetPassword';

export const publicRoutes: RouteObject[] = [
  { path: '/login/:userRole', element: <Login /> },
  { path: '/login', element: <Login /> },
  { path: '/register/*', element: <Register /> },
  { path: '/email-verification', element: <Confirmation /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/:username', element: <ResetPassword /> },
];
