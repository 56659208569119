import { z } from 'zod';

// Schema that enforces the shape of the authentication data stored in local storage
// This is used to ensure that the data is always in the correct shape
export const ZStorageSchema = z.object({
  activeAccount: z.string(),
  accounts: z.array(
    z.object({
      id: z.string(),
      token: z.string(),
    })
  ),
});

// Type of the inferred data from the schema
export type TStorageSchema = z.infer<typeof ZStorageSchema>;

// Prefix for all keys in local storage
const storagePrefix = 'zuz_';

// Supports storing and retrieving data from local storage
const storage = {
  getToken: () => {
    try {
      // Try session storage
      const session = ZStorageSchema.safeParse(
        JSON.parse(
          window.sessionStorage.getItem(`${storagePrefix}token`) as string
        )
      );

      if (session.success) {
        return session.data;
      }

      // Try local storage
      const local = ZStorageSchema.safeParse(
        JSON.parse(
          window.localStorage.getItem(`${storagePrefix}token`) as string
        )
      );

      if (local.success) {
        return local.data;
      }
    } catch {
      return null;
    }

    return null;
  },
  setToken: (token: TStorageSchema, persist = false) => {
    window.sessionStorage.setItem(
      `${storagePrefix}token`,
      JSON.stringify(token)
    );
    if (persist)
      window.localStorage.setItem(
        `${storagePrefix}token`,
        JSON.stringify(token)
      );
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.sessionStorage.removeItem(`${storagePrefix}token`);
  },

  // No usecase for session storage yet

  set: (key: string, value: string) => {
    window.localStorage.setItem(`${storagePrefix}${key}`, value);
  },
  get: (key: string) => {
    return window.localStorage.getItem(`${storagePrefix}${key}`);
  },
  clear: (key: string) => {
    window.localStorage.removeItem(`${storagePrefix}${key}`);
  },
};

export default storage;
