import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { User } from '@/utils/types';
import { useQuery } from '@tanstack/react-query';

type SessionResult = {
  status: string;
  user: User | null;
};

export const getSession = async (): Promise<SessionResult> => {
  return axios.get(`/user/auth/session`).then((res) => res.data);
};

type UseSessionOptions = {
  config?: QueryConfig<typeof getSession>;
};

export const useSession = ({ config }: UseSessionOptions = {}) => {
  return useQuery({
    ...config,
    queryKey: ['session'],
    queryFn: () => getSession(),
  });
};
