export type USERUUID = string;
export type SPECUUID = string;

export type InterestSpec = {
  start: Date; // start this interest rate applies
  stop: Date | null; // if null, the interest rate continues indefinitely
  rate: number; // interest rate specified on a yearly basis. "1" means 1%
};

export type RedeemSpec = {
  start: Date;
  fixedfee: number;
  discount: number;
  kind: string;
};

export type IBOLTDATA = {
  version: number;
  issuer: number;
  issuerName: string;
  issueDate: string;
  interest: Array<InterestSpec>;
  buyback?: { [specID: string]: number };
  redeem: Array<RedeemSpec>;
  createdAt: string;
  specID: SPECUUID;
  specName: string;
  donation: boolean;
};

export type IUSERBOLTDATA = {
  amount: number;
  bolt: IBOLTDATA;
};

export type GROUPEDBOLTDATA = {
  issuer: number;
  issuerName: string;
  bolts: IUSERBOLTDATA[];
  totalAmount: number;
};

export type IUSERDATA = {
  id: USERUUID;
  firstName: string;
  lastName: string | null;
  email: string;
  profilePic: File | string | null;
  username: string;
  phone?: string;
  businessName?: string;
  emailfreq: number;
};

export type User = {
  id: number;
  phone: string;
  username: string;
  exp: number;
  iat: number;
  name: string;
};

export type CAMPAIGNDATA = {
  code: string;
  active: boolean;
  description: string;
  name: string;
  slogan: string;
  buyzuz?: string;
  logo: string;
  images?: Array<string>;
  videos?: Array<string>;
  bolt: string;
  goal: {
    amount: number;
    completed?: number;
    createdAt?: string;
    updatedAt?: string;
    id?: string;
  };
};

export type IssuerData = {
  id: number;
  userId: number;
  businessName: string;
  street1: string | null;
  street2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  image: string | null;
  logo: string | null;
  slogan: string | null;
  categoryId: number | null;
  status: number;
  buyurl: string | null;
  description: string | null;
  tips?: { percents?: number[]; ask: boolean; other?: number };
  showtax: 0 | 1;
  acceptedZuz?: string[];
  campaigns: CAMPAIGNDATA[];
  bolts: string[];
  username: string;
  // these are added to issuer/merchant after they are fetched
  ZUZOwned: number;
  goal: number;
  raised: number;
};

export enum TXNSTATUS {
  NEW = 'new',
  INITIATED = 'initiated',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  FINALIZED = 'finalized',
  FAILEDONLEDGER = 'failed-on-ledger',
  DONATED = 'donated',
}

export enum TXNKINDS {
  MINT = 'mint',
  TRANSFER = 'transfer',
  DESTROY = 'destroy',
  BUYBACK = 'buyback',
}

// dehydrated transaction type in store
export type DRYTXNDATA = {
  status: TXNSTATUS;
  receiver: USERUUID; // id into UsersState
  sender: USERUUID; // id into UsersState
  bolt: SPECUUID; // id into BoltState
  amount: number;
  createdAt: string;
  updatedAt: string;
  _id: string;
  kind: TXNKINDS;
};

// hydrated transaction type
export type ITXNDATA = {
  status: string;
  receiver: IUSERDATA;
  sender: IUSERDATA;
  bolt: IBOLTDATA;
  amount: number;
  createdAt: string;
  updatedAt: string;
  _id: string;
  kind: TXNKINDS;
};

export type IHISTORYDATA = {
  issuer: USERUUID;
  issuerName: string;
  issueDate: string;
  interest: {
    number: number;
    rate: number;
    start: string;
  };
  buyback: boolean;
  maturity: string;
  createdAt: string;
  updatedAt: string;
  specID: SPECUUID;
};

/*
export type BARCHARTDATA = {
  id: string;
  value: number;
};
*/

export type BOLTRECORDDATA = {
  title: string;
  id: string;
  lastAction: any[];
  lastModificationDate: number;
  owner: USERUUID;
  specID: SPECUUID;
  value: number;
  name: string;
  maturity: number;
  interest: number;
};

// API error response type
export type APIError = {
  status: string;
  code: string;
  msg: string;
};

export type GitInfo = {
  git: {
    branch: string;
    commit: string;
    path: string;
    repo: string;
    shared?: Record<string, string>;
  };
};

export enum Categories {
  CAFE = 'Cafe/Bakery',
  GROCERY = 'Grocery',
  BOOKSTORE = 'Bookstore',
  RESTAURANT = 'Restaurant',
  HARDWARE = 'Hardware Store',
}

export type TileData = {
  userId: number;
  businessName: string;
  logo?: string | null;
  ZUZOwned?: number;
  goal?: number;
  raised?: number;
  category?: Categories;
};
