import { env } from '../env/client.mjs';
import { GitInfo, IssuerData, IUSERDATA, USERUUID } from '../utils/types';

//Dashboard url for symlink from dashboard repo
//export const dashboardURL = `${dashboardServer}/api/bolt/transactions`;

// Authentication
export const loginURL = `/login`;
export const logoutURL = `/logout`;

// User APIs
export const registerURL = `/user`;
export const userBoltURL = (merchantID?: USERUUID) => {
  const url = `/user/my-bolts`;
  if (merchantID) {
    return url + '?merchantId=' + merchantID;
  }
  return url;
};
export const issuersURL = `/user/list/issuers`;
export const userURL = `/user/profile`;
export const delegateURL = `/user/delegate`;
export const forgotPasswordURL = `/user/forgot-password`;
export const resetPasswordURL = (username: string) =>
  `/user/reset-password/${username.toLowerCase()}`;
export const changePasswordURL = `/user/change-password`;
export const searchUserByIdURL = (userId: string) => {
  const result = `/user/${userId}`;
  return result;
};

// Transaction APIs
export const txnBoltURL = `/v2/transaction`;
export const txnSqlURL = `/v2/transaction/fromsqr`;
export const acceptAndRejectTxn = `/v2/transaction/`;
// export const userTxnsURL = (merchantID?: USERUUID) => {
//   const url = `/transaction/user-txns`;
//   if (merchantID) {
//     return url + "?merchantId=" + merchantID;
//   }
//   return url;
// };

// Feedback APIs
export const feedbackURL = `/general/feedback`;

export const logURL = '/general/log';

export const userTxnsURLV2 = (merchantID?: USERUUID) => {
  const url = `/v2/transaction/user-txns`;
  if (merchantID) {
    return url + '?merchantId=' + merchantID;
  }
  return url;
};
export const buybackURL = (user: USERUUID) => `/transaction/buyback/${user}`;

// Bolt APIs
export const createBoltURL = `/bolt`;
export const mintBoltURL = `/bolt/mint`;
export const sellBoltURL = `/stripe/sell`;
export const getBoltURL = `/v2/bolt/`;
export const donateBoltURL = `/user/donate/`;
export const getBoltsByIDsURL = (specIDs: string[]) => {
  const url = `/v2/bolt`;
  if (specIDs) {
    return url + '?specIDs=' + specIDs;
  }
  return url;
};
export const requestZuzCreateURL = '/bolt/reqauth';
// Other
export const getImageURL = (imagePath: string) =>
  `${env.VITE_AWSS3_URL}/${imagePath}`;
export const searchIssuersURL = `/v2/user/list/issuers/`;
export const campaignURL = `/bolt/campaign/`;
export const checkTokenURL = `/user/check-token`;

export interface ApiErrorResponse {
  status: 'error';
  msg: string;
}

export interface ApiResponse {
  status: 'success';
}

export const backendGitURL = `/general`;

export interface GitResponse extends ApiResponse {
  data: GitInfo;
}

export const issuerURL = `/user/issuer/`;

export interface IssuerResponse extends ApiResponse {
  issuer: IssuerData;
}

export const searchUsersURL = (query: string): string => {
  const result = `/user/search?query=${query}`;
  return result;
};

export interface SearchResponse extends ApiResponse {
  data: {
    users: IUSERDATA[];
  };
}

export const checkUsernameURL = `/user/check-username/`;

export interface CheckUserResponse extends ApiResponse {
  username: {
    available: boolean;
  };
}

export class APIError extends Error {
  constructor(response: ApiErrorResponse) {
    super(response.msg);
    this.name = 'APIError';
  }
}

export const updateMerchantURL = `/user/merchant/`;
