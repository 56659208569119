import { QrActionTypes } from '../actions/actionTypes';

// This reducer currently controls only the ExpiredSessionModal but
// may be expanded to include other modals (e.g. ConfirmLogout, Confirmation)
export interface IQrState {
  code: string;
  open: boolean;
}

export interface IQRCodeAction {
  type: QrActionTypes.SET_CODE;
  payload: { code: string };
}

export interface IQROpenAction {
  type:
    | QrActionTypes.SET_OPEN
    | QrActionTypes.SET_CLOSED
    | QrActionTypes.FORCE_CLOSED;
}

export type IQrAction = IQROpenAction | IQRCodeAction;

const initialState: IQrState = {
  code: '',
  open: false,
};

const qrReducer = (state: IQrState = initialState, action: IQrAction) => {
  switch (action.type) {
    case QrActionTypes.SET_CODE:
      // set unique code for this app instance
      return { ...state, code: action.payload.code };

    case QrActionTypes.FORCE_CLOSED:
    case QrActionTypes.SET_CLOSED:
      // set QR closed
      return { ...state, open: false };

    case QrActionTypes.SET_OPEN:
      // set QR open
      return { ...state, open: true };

    default:
      return state;
  }
};

export default qrReducer;
