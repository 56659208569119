import { useQuery } from '@tanstack/react-query';
import { axios } from '../../lib/axios';
import { QueryConfig } from '../../lib/react-query';
import { IUSERDATA } from '../../utils/types';

type UserResult = {
  status: string;
  user: IUSERDATA;
};

export const getUser = ({ userId }: { userId: string }) => {
  // if we need testing again:
  // console.log(`getUser(${userId})`);
  return axios.get(`/user/${userId}`).then((res) => res.data as UserResult);
};

type UseUserOptions = {
  userId: string;
  config?: QueryConfig<typeof getUser>;
};

export const useUser = ({ config, userId }: UseUserOptions) => {
  // if we need testing again:
  // console.log(`useUser(${JSON.stringify(config)}, '${userId}')`);
  // if (userId === '') {
  //   console.trace("userid is empty string");
  // }
  return useQuery({
    ...config,
    queryKey: ['user', userId],
    queryFn: () => getUser({ userId }),
    staleTime: 1000 * 60 * 60 * 24, // 1 day
  });
};
