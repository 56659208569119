import { CircularProgress } from '@/components/Elements/circularprogress';
import { useMyBolts } from '@/features/user/getMyBolts';
import { useUser } from '@/lib/auth';
import { usePermissionStore } from '@/stores/PermissionStore';
import { calculateInterestRate } from '@/utils/bolt';
import { asCurrencyString } from '@/utils/money';
import { IUSERBOLTDATA } from '@/utils/types';
import { useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaCircleNotch } from 'react-icons/fa';
import BoltLogo, { IssuerLogo } from '../common/BoltLogo';
import { CustomDialog } from '../common/Dialog';
import { MintBolt } from './MintBolt';
import { SellBolt } from './SellBolt';
import { SpendBolt } from './SpendBolt';

type BoltCardProps = {
  bolt: IUSERBOLTDATA;
};

function BoltCard({ bolt }: BoltCardProps) {
  const [isMinting, setIsMinting] = useState(false);
  const [isSpending, setIsSpending] = useState(false);
  const [isSelling, setIsSelling] = useState(false);

  const { data, isLoading } = useUser();

  const { getCanMint, getCanSell } = usePermissionStore();

  if (isLoading) return <FaCircleNotch className="animate-spin" />;

  console.log(bolt);
  const interestRate = calculateInterestRate(bolt.bolt, bolt.bolt.interest);
  const maturity = new Date(bolt.bolt.issueDate);
  const matured = new Date() > maturity;

  return (
    <>
      <div className="flex w-full flex-col gap-2 rounded-lg p-4 shadow-2xl">
        <div className="inline-flex justify-between">
          <span className="text-lg font-bold">{bolt.bolt.specName}</span>
          <span className="font-bold text-primary">
            {asCurrencyString(bolt.amount)}
          </span>
        </div>
        <div className="p-4">
          {interestRate != 0 ? (
            <>
              Interest Rate: <span className="font-bold">{interestRate}</span>%
              <br />
            </>
          ) : null}
          {matured ? null : (
            <>
              Maturity Date:{' '}
              <span className="font-bold">{maturity.toLocaleDateString()}</span>
            </>
          )}
        </div>
        {data && String(bolt.bolt.issuer) === data.user.id.toString() && (
          <div>
            <button
              onClick={() => setIsMinting(true)}
              className="btn-secondary btn w-full rounded-full"
              disabled={
                +bolt.bolt.issuer !== +data.user.id &&
                !getCanMint(bolt.bolt.specID)
              }
            >
              Mint
            </button>
          </div>
        )}
        <div className="inline-flex w-full gap-2">
          <button
            onClick={() => setIsSpending(true)}
            className="btn-secondary btn flex-1 rounded-full"
          >
            Spend
          </button>
          {data && String(bolt.bolt.issuer) === data.user.id.toString() && (
            <button
              onClick={() => setIsSelling(true)}
              className="btn-secondary btn flex-1 rounded-full"
              disabled={
                +bolt.bolt.issuer !== +data.user.id &&
                !getCanSell(bolt.bolt.specID)
              }
            >
              Sell
            </button>
          )}
        </div>
      </div>

      <MintBolt
        isOpen={isMinting}
        onClose={() => setIsMinting(false)}
        specId={bolt.bolt.specID}
      />

      <SpendBolt
        isOpen={isSpending}
        onClose={() => setIsSpending(false)}
        specId={bolt.bolt.specID}
      />

      <SellBolt
        isOpen={isSelling}
        onClose={() => setIsSelling(false)}
        specId={bolt.bolt.specID}
      />
    </>
  );
}

type BoltCardDialogProps = {
  group: [string, { bolts: IUSERBOLTDATA[]; amount: number }];
  isOpen: boolean;
  index: number;
  onClose: () => void;
};
function BoltCardDialog({
  group,
  isOpen,
  index,
  onClose,
}: BoltCardDialogProps) {
  const [indice, setIndice] = useState(index);

  useEffect(() => {
    setIndice(index);
  }, [index]);

  return (
    <CustomDialog
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setIndice(0);
      }}
    >
      <div className="flex items-center justify-between text-lg">
        <span className="text-3xl">{group[0]}</span>
        <span className="text-2xl text-primary">
          {asCurrencyString(group[1].amount)}
        </span>
      </div>

      <div className="mt-2 flex items-center justify-center gap-4">
        <div>
          <button
            className="btn-primary btn-outline btn-xs btn-circle btn"
            onClick={() => setIndice((prev) => prev - 1)}
            disabled={indice === 0}
          >
            <FaArrowLeft />
          </button>
        </div>

        <BoltCard bolt={group[1].bolts[indice]} />

        <div>
          <button
            className="btn-primary btn-outline btn-xs btn-circle btn"
            onClick={() => setIndice((prev) => prev + 1)}
            disabled={indice === group[1].bolts.length - 1}
          >
            <FaArrowRight />
          </button>
        </div>
      </div>
    </CustomDialog>
  );
}

type GroupedBoltCardProps = {
  group: [string, { bolts: IUSERBOLTDATA[]; amount: number }];
};
function GroupedBoltCard({ group }: GroupedBoltCardProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);

  return (
    <>
      <div
        className="z-10 -mb-40 flex h-56 flex-col overflow-y-hidden rounded-3xl border-2 border-solid border-secondary bg-white p-3 font-display shadow-2xl transition-all hover:-translate-y-2 md:hidden"
        onClick={() => setIsOpen(true)}
      >
        <div className="flex justify-between">
          <span className="text-xl">{group[0]}</span>
          <span className="text-xl text-primary">
            {asCurrencyString(group[1].amount)}
          </span>
        </div>
        <div className="flex w-full flex-1 items-center justify-center">
          <div className="w-1/2">
            <IssuerLogo issuerId={String(group[1].bolts[0].bolt.issuer)} />
          </div>
        </div>
      </div>

      <div className="hidden flex-col gap-2 p-4 md:flex">
        <h1 className="font-display text-xl font-bold text-secondary">
          {group[0]}
        </h1>
        <div className="flex gap-2 overflow-x-scroll p-2">
          {group[1].bolts
            .filter((bolt) => bolt.amount >= 0.01) // only show bolts with more than 1 penny
            .map((bolt, i) => (
              <button
                key={bolt.bolt.specID}
                className="flex h-36 w-72 flex-shrink-0 items-center justify-center gap-4 rounded-md border-2 border-solid border-secondary bg-base-100 transition-all hover:border-primary hover:shadow-2xl"
                onClick={() => {
                  setIndex(i);
                  setIsOpen(true);
                }}
              >
                <div className="h-16 w-16">
                  <BoltLogo specID={bolt.bolt.specID} />
                </div>
                <div className="text-left font-bold">
                  <div>{bolt.bolt.specName}</div>
                  <div className="mt-1 text-primary">
                    {asCurrencyString(bolt.amount)}
                  </div>
                </div>
              </button>
            ))}
        </div>
      </div>

      <BoltCardDialog
        group={group}
        index={index}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}

function BoltCards() {
  const { data, status } = useMyBolts();

  if (status === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    return <div>Error</div>;
  }

  const groups = data.bolts.reduce<{
    [key: string]: { bolts: IUSERBOLTDATA[]; amount: number };
  }>((prev, curr) => {
    if (prev[curr.bolt.issuerName]) {
      prev[curr.bolt.issuerName].amount += curr.amount;
    }

    prev[curr.bolt.issuerName] = {
      bolts: [...(prev[curr.bolt.issuerName]?.bolts || []), curr],
      amount: prev[curr.bolt.issuerName]?.amount || curr.amount,
    };

    return prev;
  }, {});

  // only show an issuer (i.e., a group) if the user has at least one bolt from them with a value of more than 1 penny
  const filteredGroups = Object.entries(groups).filter((group) =>
    group[1].bolts.some((bolt) => bolt.amount >= 0.01)
  );

  // if user has no bolts, show link to marketplace
  if (filteredGroups.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <div className="text-gray-500">
          There is nothing in your wallet. Check out the{' '}
          <a href="/marketplace" className="link-primary link">
            marketplace
          </a>
          .
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      {filteredGroups.map((group) => (
        <GroupedBoltCard key={group[0]} group={group} />
      ))}
    </div>
  );
}

function Wallet() {
  return (
    <div>
      <div>
        <h1 className="mb-4 font-display text-xl tracking-wide text-secondary">
          My ZUZ Wallet
        </h1>
        <BoltCards />
      </div>
    </div>
  );
}

export default Wallet;
