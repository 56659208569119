import { CircularProgress } from '@/components/Elements/circularprogress';
import { useUser as useAuthUser } from '@/lib/auth';
import { Link } from '@mui/material';
import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useBolt } from '../../features/bolt/getBolt';
import { useUser } from '../../features/user/getUser';
import useTransactionStore from '../../stores/TransactionStore';
import { asCurrencyString } from '../../utils/money';
import { DRYTXNDATA, IBOLTDATA, IUSERDATA } from '../../utils/types';
import BoltLogo from '../common/BoltLogo';
import { CustomDialog } from '../common/Dialog';

function Content({
  bolt,
  transactions,
  sender,
}: {
  bolt: IBOLTDATA;
  transactions: DRYTXNDATA[];
  sender: IUSERDATA;
}) {
  const { setReceivedTransaction } = useTransactionStore();

  return (
    <div className="flex flex-col items-center gap-4 p-8">
      <div className="flex flex-col items-center text-success">
        <div>
          <FaCheckCircle className="h-10 w-10" />
        </div>
        <div className="text-2xl font-bold">Payment Received</div>
      </div>
      <div className="divider" />
      <div className="space-y-2">
        <BoltLogo specID={bolt.specID} />
        <div className="text-lg font-bold">{bolt.specName}</div>
      </div>
      <div className="divider" />
      <div className="w-full text-base-content/80">
        <div className="flex w-full justify-between">
          <span>From:</span>
          <span className="font-bold">@{sender.username}</span>
        </div>
        <div className="flex w-full justify-between">
          <span>Amount Received:</span>
          <span className="font-bold">
            {!!transactions[0] && asCurrencyString(transactions[0].amount)}
          </span>
        </div>
        <div className="flex w-full justify-between">
          <span>ZUZ:</span>
          <span className="font-bold">{bolt.specName}</span>
        </div>
      </div>
      {transactions.length > 1 && (
        <Link href="/profile">
          <span>and {transactions.length - 1} more...</span>
        </Link>
      )}
      <div className="mt-5 w-full">
        <button
          type="button"
          className="btn-secondary btn float-right"
          onClick={() => setReceivedTransaction(null)}
        >
          Dismiss
        </button>
      </div>
    </div>
  );
}

export default function ReceivePaymentDialog() {
  const [receivedTxns, setReceivedTxns] = useState<DRYTXNDATA[]>([]);

  const authUser = useAuthUser();

  const sender = useUser({
    userId: receivedTxns[0]?.sender,
    config: {
      enabled: !!receivedTxns[0],
    },
  });
  const bolt = useBolt({
    specId: receivedTxns[0]?.bolt,
    config: { enabled: !!receivedTxns[0] },
  });

  const { receivedTransaction, setReceivedTransaction } = useTransactionStore();

  useEffect(() => {
    if (!receivedTransaction) {
      setReceivedTxns([]);
      return;
    }
    if (!authUser.data) return;
    if (
      receivedTransaction.receiver.toString() !==
      authUser.data.user.id.toString()
    )
      return;
    if (receivedTxns.find((txn) => txn._id === receivedTransaction._id)) return;
    setReceivedTxns([...receivedTxns, receivedTransaction]);
  }, [receivedTransaction]);

  return (
    <CustomDialog
      isOpen={!!receivedTxns[0]}
      onClose={() => setReceivedTransaction(null)}
    >
      {!bolt.isSuccess || !sender.isSuccess ? (
        <CircularProgress />
      ) : (
        <Content
          bolt={bolt.data.bolt}
          transactions={receivedTxns}
          sender={sender.data?.user}
        />
      )}
    </CustomDialog>
  );
}
